<template>
  <div
    v-if="curoisity_data.is_active"
    class="flex flex-col justify-between items-center min-[410px]:w-44 border-1 border-borderColor bg-white rounded-2xl px-3"
  >
    <div class="flex text-white flex-col items-center">
      <img
        :src="curoisity_data.image"
        alt="Badge Image"
        class="p-2 w-20 vsx:w-28"
      />
      <p class="vsx:text-md text-sm font-bold mb-4 text-center text-blue">
        {{ curoisity_data.name }}
      </p>
    </div>
    <p class="text-xs text-center font-inter font-normal mb-5">
      {{ curoisity_data.description || '' }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'BadgeCuroisityComponent',
  props: {
    curoisity_data: {
      type: Object,
      required: true,
    },
  },
};
</script>








<!-- <template>
  <div
    class="flex flex-col justify-between items-center bg-white  rounded-2xl  px-3"
  >
    <div class="flex text-white flex-col items-center">
      <img
        :src="curoisity_data.image"
        alt="Badge Image"
        class="p-2 w-16 vsx:w-28"
        :class="{ 'filter grayscale': !curoisity_data.is_active }"
      />
      <p
        class="vsx:text-md text-xs font-bold mb-4 text-center"
        :class="{
          'text-blue': curoisity_data.is_active,
          'text-inactiveBadgeTitle': !curoisity_data.is_active,
        }"
      >
        {{ curoisity_data.name }}
      </p>
    </div>
    <p class="vsx:text-xs text-[10px] text-center font-inter font-normal mb-5">
      {{ curoisity_data.description ? curoisity_data.description : '' }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'BadgeCuroisityComponent',
  props: {
    curoisity_data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style> -->
