<template>
  <section class="w-full py-5">
    <div class="w-full">
      <EntityCardComponent
        v-for="(key, index) in filtered_entity_type_display"
        :key="index"
        :entity_key="key['entity_type_key']"
      />
      <RelatedActivityMediaComponent />
      <!-- <ActivitiesComponent />
      <PurchaseActivityComponent />
      <CareerInterstComponent /> -->
    </div>
  </section>
</template>
<!-- Enhance Section Start -->

<script>
// Import jQuery and Slick slider
import { all_entity_type_values } from '@/common_variables/entity_type';
import EntityCardComponent from '@/controller/media_data/components/common_component/entity_component/entity_card.vue';
// import ActivitiesComponent from '@/controller/media_data/components/enhance/activity/activity_component.vue';
// import PurchaseActivityComponent from '@/controller/media_data/components/enhance/purchase_opp/purchase_component.vue';
// import CareerInterstComponent from '@/controller/media_data/components/enhance/career_interest/career_interest_component.vue';
import RelatedActivityMediaComponent from '@/controller/media_data/components/enhance/related_activity_media/related_media_component.vue';

export default {
  name: 'EnhanceComponent',
  components: {
    EntityCardComponent,
    RelatedActivityMediaComponent,
  },

  computed: {
    filtered_entity_type_display() {
      let filter_entity = Object.values(all_entity_type_values).filter(
        (entity) => entity.component === 'enhance',
      );
      console.log(filter_entity);
      return filter_entity;
    },
  },
  data() {
    return {
      loading: false,
      isLogin: false,
      tab_class: {
        underline_tab:
          'tab-button text-2xl text-blue border-b-2 pb-1 border-blue max-[767px]:ml-0 ml-7',
        without_underline_tab:
          'tab-button text-2xl text-black/40 max-[767px]:ml-0 ml-7',
      },
      selected_tab: 'experience',
    };
  },

  mounted() {
    const token = localStorage.getItem('token');
    if (token === undefined || token === null || token === '') {
      this.isLogin = false;
    } else {
      this.isLogin = true;
    }
  },
};
</script>

<style scoped></style>
