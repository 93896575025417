<template>
  <div class="" v-if="selectedFilterNames.length > 0">
    <div class="selected-filters-container flex flex-wrap gap-1 mb-6 sm:gap-2">
      <div v-for="(filterName, index) in selectedFilterNames" :key="index"
        class="selected-filter-pill bg-gray-300 text-xs sm:text-sm md:text-lg rounded-lg sm:rounded-xl px-3 py-1 flex items-center gap-2">
        <span class="">{{ filterName }}</span>
        <button @click="removeFilter(index)" class=" focus:outline-none"
          :aria-label="'Remove ' + filterName + ' filter'">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectedFilters',
  props: {
    selectedFilterNames: {
      type: Array,
      required: true
    },
    selectedFilterIds: {
      type: Array,
      required: true
    }
  },
  emits: ['remove-filter'],
  methods: {
    removeFilter(index) {
      this.$emit('remove-filter', {
        id: this.selectedFilterIds[index],
        name: this.selectedFilterNames[index]
      });
    },
  }
}
</script>