<template>
  <div class="w-full mx-auto">
    <!-- Header Start -->
    <HomeHeaders />
    <!-- Header End -->
    <template v-if="loading">
      <div class="loading-indicator">
        <div class="loader"></div>
      </div>
    </template>

    <template v-else>
      <div v-if="related_media_result" class="mx-auto ">
        <div class=" ">
          <div class="flex flex-col bg-white py-8 sm:py-12  px-3 sm:px-5 lg:p-8 "
            :style="{ backgroundImage: `url(${require('../../images/card2.png')})` }">
            <div class="mb-10">
              <button
                class="text-blue xs:px-6 xs:py-3 bg-white px-4 py-2 sm:text-lg font-medium border-1 border-blue rounded-2xl hover:text-white hover:bg-blue duration-500"
                @click="goBack">
                Back
              </button>
            </div>
            <DetailComponent />

            <!-- <div class="border-2 border-borderColor my-12"></div> -->
          </div>
          <div class="">
            <div class="flex justify-center bg-gray-200 mb-9 p-1 ">
              <span
                class="xs:text:sm sm:text-md md:text-2xl lg:text-3xl rounded-2xl px-6 py-4 sm:px-12 sm:py-6 mx-auto text-center font-bold  max-[640px]:mb-5 text-blue">
                {{ mediaSentence() }}
              </span>
            </div>
            <div v-if="related_media_result.result.results.length === 0"
              class="text-2xl font-semibold mb-5 text-center">
              No Results Found
            </div>
            <template v-if="related_media_result_list">
              <div
                class="grid xxl:grid-cols-6 lg:grid-cols-5 px-8 md:grid-cols-4  min-[550px]:grid-cols-3 min-[380px]:grid-cols-2 grid-cols-1 sm:gap-4 max-[640px]:gap-y-4 mb-10">
                <div v-for="(item, index) in related_media_result_list" :key="index">
                  <CardComponent :card_data="item" />
                </div>
              </div>

              <div v-if="show_more_result" class="main-item">
                <div class="animated-background">
                  <div class="background-masker btn-divide-left"></div>
                </div>
              </div>
              <div v-if="
                related_media_result.result.next != null &&
                related_media_result
              " class="flex justify-center items-center cursor-pointer">
                <SeeMoreIcon @click="show_more_results(related_media_result.result.next)" />
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="recently-bottom-border border-b-1 border-borderColor mt-20 mb-4 sm:mx-20"></div>
    </template>
    <!-- Footer Start -->
    <HomeFooter />
    <!-- Footer End -->
  </div>
</template>

<script>
import $ from 'jquery';
import { mapState, mapActions, mapMutations } from 'vuex';
import HomeHeaders from '@/common_components/header/header.vue';
import HomeFooter from '@/common_components/footer/footer.vue';
import DetailComponent from './component/detail_component.vue';
import CardComponent from '@/common_components/card/card_component.vue';
import { initializeSliders } from './specific_data_slider';
import SeeMoreIcon from '@/common_components/show_more/show_more_component.vue';

export default {
  name: 'SpecificData',

  components: {
    HomeHeaders,
    HomeFooter,
    DetailComponent,
    CardComponent,
    SeeMoreIcon,
  },

  computed: {
    ...mapState({
      entity_detail_result: (state) =>
        state.specific_data_store.entity_detail_result,
      related_media_result: (state) =>
        state.specific_data_store.related_media_result,
      related_media_result_list: (state) =>
        state.specific_data_store.related_media_result_list,
    }),
  },

  data() {
    return {
      loading: false,
      show_more_result: false,
      page: 1,
    };
  },

  async created() {
    this.reset_character_related_media_list();
    await this.fetchDataFromApis();
  },

  mounted() {
    initializeSliders();
  },

  methods: {
    ...mapActions('specific_data_store', [
      'character_entity_detail_api',
      'character_related_media_api',
    ]),
    ...mapMutations('specific_data_store', [
      'reset_character_related_media_list',
    ]),
    async fetchDataFromApis() {
      this.loading = true;
      try {
        await Promise.all([
          this.character_entity_detail_api({
            id: this.$route.query.id,
            type: this.$route.query.type,
          }),
          this.character_related_media_api({
            id: this.$route.query.id,
            page: this.page,
            type: this.$route.query.type,
          }),
        ]);
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.loading = false;
      }
    },
    async show_more_results(page) {
      this.show_more_result = true;
      try {
        await Promise.all([
          this.character_related_media_api({
            id: this.$route.query.id,
            page: page,
            type: this.$route.query.type,
          }),
        ]);
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.show_more_result = false;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    mediaSentence() {
      if (!this.entity_detail_result) {
        return '';
      }
      if (['media_credit'].includes(this.entity_detail_result.result.entity)) {
        return `Related media for this ${this.entity_detail_result.result.entity_display} are as follows:`;
      } else {
        return `This ${this.entity_detail_result.result.entity_display} appears in the following media:`;
      }
    },
  },
  beforeUnmount() {
    $('.result-media-slider').slick('unslick');
    $('.other-authors-slider').slick('unslick');
  },
};
</script>

<style>
.result-media-slider,
.other-authors-slider {
  overflow: hidden;
}

.slick-track {
  display: flex !important;
  padding: 5px 0;
}

/* the slides */
.slick-slide {
  margin: 0 20px;
  height: inherit !important;
}

/* the parent */
.slick-list {
  margin: 0 -20px;
}
</style>
