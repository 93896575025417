export const APIs = {
  // base_url: 'https://journey-dev.projectvast.io',
  // base_url: 'http://localhost:8000',
  base_url: 'https://vast-prod.projectvast.io',
  login_api: '/user/api/v1/discovery/login/',
  register_api: '/user/api/v1/discovery/register/',
  most_viewed_api: '/discovery/api/v1/media/most_viewed/',
  trending_media_api: '/discovery/api/v1/media/trending_media/',
  feature_media_api: '/discovery/api/v1/media/featured_media/',
  experience_api: '/discovery/api/v1/media/{id}/experience/',
  update_experience_api: '/discovery/api/v1/media/{id}/update_experience/',
  exp_progress: '/discovery/api/v1/exp-progress/',
  exp_status: '/discovery/api/v1/exp-status/',
  identify_api: '/discovery/api/v1/media/{id}/identify/',
  describe_api: '/discovery/api/v1/media/{id}/describe/',
  enhance_api: '/discovery/api/v1/media/{id}/enhance/',
  recent_views_api: '/discovery/api/v1/media/recent_views/',
  favourite_api: '/discovery/api/v1/favourite/',
  profile_api: '/user/api/v1/discovery/profile/',
  update_profile_api: '/user/api/v1/discovery/profile/{id}/',
  change_password_api: '/user/api/v1/discovery/reset_password/',
  logout_api: '/user/api/v1/logout/',
  search_result_api: '/discovery/api/v1/media/',
  media_type_options: '/discovery/api/v1/media_type_options/',
  target_audience_options: '/discovery/api/v1/target_audience_options/',
  media_format_options: '/discovery/api/v1/media_format_options/',
  all_favorite_api: '/discovery/api/v1/favourite/',
  entity_detail_api: '/discovery/api/v1/entity_detail/',
  entity_related_media_api: '/discovery/api/v1/entity_related_media/',
  satisfy_my_curiosity_api: '/discovery/api/v1/satisfy_my_curiosity/',
  journal_media_api: '/discovery/api/v1/journel/',
  satisfy_curiosity_option_api:
    '/discovery/api/v1/satisfy_my_curiosity/related_suggestions/',
  home_search_api: '/discovery/api/v1/searchv2/',
  review_api: '/discovery/api/v1/review',
  quote_api: '/discovery/api/v1/quote',
  char_trait_api: '/discovery/api/v1/char_traits/',
  entity_list_api: '/discovery/api/v1/entity_list/',
  media_credits_options: '/discovery/api/v1/media_credits_options/',
  find_media_api: '/discovery/api/v1/find/',
  entity_form: '/discovery/api/v1/entity_form/',
  media_form_api: '/discovery/api/v1/media_form/',

  related_media_api: '/discovery/api/v1/media/{id}/related_media/',
  // enhance apis //
  activity_api: '/discovery/api/v1/media/{id}/activity/',
  purchase_oppurtunity_api:
    '/discovery/api/v1/media/{id}/purchase_oppurtunity/',
  career_interest_api: '/discovery/api/v1/media/{id}/career_interest/',
  related_media_activities_api:
    '/discovery/api/v1/media/{id}/related_media_activities/',
  group_api: '/discovery/api/v1/media/{id}/groups/',
  show_all_entities: '/discovery/api/v1/media/{id}/{entity}/',
  // engage apis //
  challange_api: '/discovery/api/v1/media/{id}/challenge/',
  entity_api: '/discovery/api/v1/media/{id}/',

  // badge apis
  badge_curoisity_api: '/discovery/api/v1/badges/curoisity/',
  badge_stats_api: '/discovery/api/v1/badges/stats/',

   // XP api
   xp_logs_api: '/user/api/v1/xp_logs/',
   
  //  Group API 
  groups_api: '/discovery/api/v1/groups/',
  get_summary: '/discovery/api/v1/get-summary/',
};
