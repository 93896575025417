<template>
    <div class="rightside flex justify-between bg-[#e4eaf8] h-full w-full ">
        <div class="mx-auto w-full">
            <div class="header xs:flex xs:justify-between  p-3 w-full">
                <div class="menu hidden xs:flex items-center cursor-pointer" @click.stop="$emit('toggle-sidebar')">
                </div>
                <div class="links flex justify-center items-center gap-6">
                    <ul class="flex gap-1 sm:gap-3 text-[#B5BAC1] text-[12px] sm:text-[14px] font-[500]">
                        <router-link v-for="(tab, index) in tabs" :key="index"
                            :to="{ path: '/chat/earn_points', query: { view: tab.value } }"
                            class="hover:text-blue px-2 sm:px-3" :class="{ 'text-blue': currentTab === tab.value }">
                            <span class="hidden sm:inline">{{ tab.label }}</span>
                            <span class="sm:hidden text-lg">{{ tab.shortLabel }}</span>
                        </router-link>
                    </ul>
                    <!-- <button @click="goToAddAlly" class="bg-blue text-white px-2 py-1 rounded-lg">Add ally</button> -->
                </div>
                <div class="right-icons flex justify-end gap-3 pt-3 text-gray-500  p-2">
                    <div class=" border-r border-gray-500 pr-2">
                        <span @click.stop="toggleMenu" class="cursor-pointer relative">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                                class="bi bi-people-fill" viewBox="0 0 16 16">
                                <path
                                    d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
                            </svg>
                        </span>

                        <div v-if="isMenuOpen" ref="menu"
                            class="absolute px-6 right-0 xs:right-24 top-32 lg:top-16 bg-white text-black border rounded-lg shadow-lg py-3 z-50">
                            <h3 class=" font-semibold p-2 border-b">Select allies for group</h3>
                            <p class="text-xs p-2">Add people</p>
                            <div class="py-2">
                                <div
                                    class="search-name flex pr-4 items-center rounded-xl overflow-hidden bg-white border-2">
                                    <input class="w-full p-4 focus:outline-none" type="text" placeholder="Username ...">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                            fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                            <path
                                                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                        </svg>
                                    </span>
                                </div>
                                <div class="flex items-center my-3 gap-2 p-2 bg-gray-100 rounded cursor-pointer">
                                    <img src="../../../images/ziaumer.png" alt="user.name" class="w-12 rounded-full" />
                                    <div class="flex-1">
                                        <p class="text-sm font-medium">Zia Umar</p>
                                        <p class="text-xs text-gray-500">Online</p>
                                    </div>
                                    <div class="flex items-center gap-3">
                                        <p class="text-[10px] flex text-blue bg-gray-300 p-2 rounded-xl ">
                                            <span>Data privacy</span>
                                        </p>
                                        <input class="w-5 h-5" type="checkbox">
                                    </div>
                                </div>
                                <div class="flex items-center my-3 gap-2 p-2 bg-gray-100 rounded cursor-pointer">
                                    <img src="../../../images/ziaumer.png" alt="user.name" class="w-12 rounded-full" />
                                    <div class="flex-1">
                                        <p class="text-sm font-medium">Zia Umar</p>
                                        <p class="text-xs text-gray-500">Online</p>
                                    </div>
                                    <div class="flex items-center gap-3">
                                        <p class="text-[10px] flex text-blue bg-gray-300 p-2 rounded-xl ">
                                            <span>Data privacy</span>
                                        </p>
                                        <input class="w-5 h-5" type="checkbox">
                                    </div>
                                </div>
                                <div class="flex items-center my-3 gap-2 p-2 bg-gray-100 rounded cursor-pointer">
                                    <img src="../../../images/ziaumer.png" alt="user.name" class="w-12 rounded-full" />
                                    <div class="flex-1">
                                        <p class="text-sm font-medium">Zia Umar</p>
                                        <p class="text-xs text-gray-500">Online</p>
                                    </div>
                                    <div class="flex items-center gap-3">
                                        <p class="text-[10px] flex text-blue bg-gray-300 p-2 rounded-xl ">
                                            <span>Data privacy</span>
                                        </p>
                                        <input class="w-5 h-5" type="checkbox">
                                    </div>
                                </div>
                            </div>
                            <div class="flex justify-center">
                                <button class="bg-blue text-white text-xl px-6 py-2 rounded-xl">Create Group</button>
                            </div>
                        </div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                        class="bi bi-envelope-fill" viewBox="0 0 16 16">
                        <path
                            d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                        class="bi bi-question-circle-fill" viewBox="0 0 16 16">
                        <path
                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247m2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
                    </svg>
                </div>

            </div>

            <component :is="currentViewComponent" />
        </div>
    </div>
</template>

<script>
import AllEarnPoint from './components/all_earn_points.vue';

export default {
    name: 'EarnPoints',
    components: {
        AllEarnPoint
    },
    data() {
        return {
            isMenuOpen: false,
            tabs: [
                { label: 'All Earn points', shortLabel: 'All Earn points', value: 'all' },
            ],
        };
    },
    computed: {
        currentTab() {
            return this.$route.query.view || 'all';
        },
        currentViewComponent() {
            const views = {
                all: 'AllEarnPoint',
                // received: 'ReceivedRequests',
                // sent: 'SentRequests',
                // add: 'AddAlly',
            };
            return views[this.currentTab];
        },
    },
    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        closeMenu(event) {
            if (this.$refs.menu && !this.$refs.menu.contains(event.target)) {
                this.isMenuOpen = false;
            }
        },
    },
    mounted() {
        document.addEventListener("click", this.closeMenu);
    },
    beforeUnmount() {
        document.removeEventListener("click", this.closeMenu);
    },
};
</script>
<style>
.leftside {
    height: 100vh;
    will-change: transform;
}

@media (max-width: 1024px) {
    .leftside {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    }
}
</style>