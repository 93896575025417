<template>
  <div @click="
    detail_navigation(character.character.id, character.character.entity)
    " class="border-1 border-borderColor p-3 xxl:p-5 rounded-2xl cursor-pointer"
    :style="{ backgroundImage: `url(${require('../../../../../images/card2.png')})` }">
    <div class="xs:flex xs:h-[400px] ">
      <div class="flex  flex-col  xs:min-w-40 lg:min-w-52 xs:h-full">
        <div class=" overflow-hidden lg:h-60  mx-auto w-60 xs:w-full h-48 rounded-2xl">
          <img :src="character.character.image" :alt="character.character.name" class="w-full h-full" />
        </div>
        <div class="flex flex-col items-center w-full ">
          <div class="flex justify-center  mt-3">
            <div class="flex items-center">
              <!-- Replace with grey icon if not isLike-->
              <MediaLikeComponent :identify_media_data="character.character" />
              <span class="text-xl mx-3">Like</span>
            </div>
          </div>
          <template v-if="character.character.additional_resources.length > 0">
            <button
              class="text-xs lg:block hidden sm:text-sm text-white bg-blue font-medium py-2 px-2 my-3 text-center rounded-full border-1 hover:text-blue hover:bg-white duration-500 hover:border-blue hover:border-1"
              @click.stop="additionalSources($event, character.id)">
              {{ showAdditionalSources ? 'Hide' : 'View' }} Additional Sources
            </button>
            <button
              class="text-xs block lg:hidden sm:text-sm text-white bg-blue font-medium py-2 px-2 my-3 text-center rounded-full border-1 hover:text-blue hover:bg-white duration-500 hover:border-blue hover:border-1"
              @click.stop="additionalSources($event, character.id)">
              {{ showAdditionalSources ? 'Hide' : 'View' }} more Sources
            </button>
            <ul class="flex flex-col mt-2 gap-2 sm:text-center max-[640px]:text-center hidden"
              :id="'additionalSources-' + character.id">
              <template v-for="(add_src, index) in character.character
                .additional_resources" :key="index">
                <li>
                  <span class="text-black underline text-xs">
                    <a v-if="add_src.link" :href="add_src.link" target="_blank" @click.stop="">{{ add_src.description
                    }}</a></span>
                </li>
              </template>
            </ul>
          </template>
        </div>
      </div>
      <div class="flex  overflow-y-hidden hover:overflow-y-scroll h-[300px] xs:h-full flex-col xs:ml-2 xxl:ml-5 w-full ">
        <h2 class="text-[28px] font-bold mb-2 mr-2 max-[640px]:text-[24px]">
          {{ character.character.name }}
        </h2>
        <template v-if="character.character.groups.length > 0">
          <p class="text-black">
            <span>ROLES:&nbsp;</span>
            <span class="opacity-40">{{
              character.character.groups.join(', ')
            }}</span>
          </p>
        </template>
        <template v-if="character.character.description">
          <p class="py-1">
            <span><b>Description:</b></span>
          </p>
          <p class="text-sm mt-1 pr-3">
            {{ character.character.description }}
          </p>
        </template>
        <template v-if="character.description">
          <p class="py-1 mt-1">
            <span><b>Title Specific Description:</b></span>
          </p>
          <p class="text-sm pr-3">
            {{ character.description }}
          </p>
        </template>
        <div class="flex flex-wrap my-1 xs:my-3">
          <div
            class="flex justify-center items-center border-1 border-blue rounded-full px-3 py-1 mr-3 mb-2 ">
            <span class="text-xs" :class="isReal ? 'text-blue ml-1' : 'text-black opacity-20'">Real</span>
            <span class="text-xs text-black opacity-20">&nbsp;|&nbsp;</span>
            <span class="text-xs" :class="isFiction ? 'text-blue ml-1' : 'text-black opacity-20'">Fiction</span>
          </div>
          <div class="flex justify-center items-center border-1 border-blue rounded-full mb-2  px-3 py-1">
            <span class="text-xs" :class="isNative ? 'text-blue mr-1' : 'text-black opacity-20'">Native
            </span>
            <span class="text-xs text-black opacity-20">&nbsp;|&nbsp;</span>
            <span class="text-xs" :class="isMentioned ? 'text-blue mr-1' : 'text-black opacity-20'">Mentioned</span>
          </div>
        </div>
        <template v-if="character.character.traits.length > 0">
          <h4 class="text-sm font-bold">Character Traits</h4>
          <div class="flex flex-wrap my-3">
            <template v-for="(trait, index) in character.character.traits" :key="index">
              <div class="flex justify-center items-center border-1 border-blue rounded-full px-2 py-1 mr-2 mb-2">
                <span class="text-xs text-blue ml-1">{{ trait }}</span>
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import MediaLikeComponent from '@/common_components/media_fav_icon/media_like_component.vue';
import { specific_page_routing } from '@/common_components/common_routing/specific_data_routing';
export default {
  components: {
    MediaLikeComponent,
  },
  data() {
    return {
      isReal: this.character.character.o_type === 'real',
      isFiction: this.character.character.o_type === 'fiction',
      isNative: this.character.source_type === 'native',
      isMentioned: this.character.source_type === 'mentioned',
      showAdditionalSources: false,
    };
  },
  name: 'CharacterCardComponent',
  props: {
    character: {
      type: Object,
    },
  },
  methods: {
    detail_navigation(id, component) {
      specific_page_routing(this.$router, id, component);
    },
    additionalSources(e, id) {
      const div_id = 'additionalSources-' + id;
      this.showAdditionalSources = !this.showAdditionalSources;
      document.querySelector('#' + div_id).classList.toggle('hidden');
    },
  },
};
</script>

<style scoped>
/* Add your component-specific styles here */
</style>
