<template>
  <div v-if="value_prop" class="relative ">
    <h2 class=" lg:text-3xl md:text-xl text-blue sm:text-2xl max-[640px]:text-lg font-bold mt-6 mb-10">
      {{ mediaSentence(key_prop) }}
    </h2>
    <!-- Grid View -->
    <div v-if="viewMode === 'grid'"
      class="grid xl:grid-cols-5 min-[1130px]:grid-cols-4 lg:grid-cols-3 md:grid-cols-4 min-[560px]:grid-cols-3 grid-cols-2 gap-2 xs:gap-4 max-[640px]:gap-y-4 mb-10">
      <div v-for="(item, index) in local_prop_result" :key="index">
        <CardComponent :card_data="item" card_page="search_page" />
      </div>
    </div>

    <!-- Flat View -->
    <div class="sm:block hidden" v-else>
      <div v-for="(item, index) in local_prop_result" :key="index">
        <FlatCardComponent :card_data="item" card_page="search_page" />
      </div>
    </div>

    <!-- See All button - Hide when entity parameter is present -->
    <div class="text-center mt-6 mb-8" v-if="value_prop.count > 20 && !showPagination">
      <button @click="$emit('load-more', key_prop)"
        class="bg-blue hover:bg-blue-700 text-white font-semibold py-2 px-6 rounded-2xl transition-colors duration-300">
        See All {{ getEntityName(key_prop) }}
      </button>
    </div>

    <!-- Pagination loading indicator - Updated to be larger -->
    <template v-if="loading && paginationLoading">
      <div class="loading-indicator-container">
        <div class="loading-indicator-wrapper">
          <div class="loader"></div>
        </div>
      </div>
    </template>

    <!-- Original loading animation (for non-pagination loading) -->
    <div v-if="(loading && !paginationLoading) || show_more_result" class="main-item w-full ">
      <div class="animated-background w-full ">
        <div class="background-masker btn-divide-left"></div>
      </div>
    </div>

    <!-- Pagination - only show when entity parameter is present -->
    <div v-if="value_prop.count > 0 && showPagination" class="flex justify-center items-center my-8">
      <div class="flex space-x-2">
        <!-- Previous Page Button -->
        <button @click="changePage(currentPage - 1)" :disabled="currentPage === 1"
          class="px-3 py-1 rounded-md border border-gray-300 hover:bg-gray-100 transition-colors duration-300"
          :class="{ 'opacity-50 cursor-not-allowed': currentPage === 1 }">
          &laquo;
        </button>

        <!-- First Page -->
        <button v-if="totalPages > 0" @click="changePage(1)"
          class="px-3 py-1 rounded-md border hover:bg-gray-100 transition-colors duration-300"
          :class="{ 'bg-blue text-white hover:bg-blue-700': currentPage === 1 }">
          1
        </button>

        <!-- Ellipsis for skipped pages (left) -->
        <span v-if="startPage > 2" class="px-3 py-1">...</span>

        <!-- Middle Pages -->
        <button v-for="page in displayedPageRange" :key="page" @click="changePage(page)"
          class="px-3 py-1 rounded-md border hover:bg-gray-100 transition-colors duration-300"
          :class="{ 'bg-blue text-white hover:bg-blue-700': currentPage === page }">
          {{ page }}
        </button>

        <!-- Ellipsis for skipped pages (right) -->
        <span v-if="endPage < totalPages - 1" class="px-3 py-1">...</span>

        <!-- Last Page -->
        <button v-if="totalPages > 1" @click="changePage(totalPages)"
          class="px-3 py-1 rounded-md border hover:bg-gray-100 transition-colors duration-300"
          :class="{ 'bg-blue text-white hover:bg-blue-700': currentPage === totalPages }">
          {{ totalPages }}
        </button>

        <!-- Next Page Button -->
        <button @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages || totalPages === 0"
          class="px-3 py-1 rounded-md border border-gray-300 hover:bg-gray-100 transition-colors duration-300"
          :class="{ 'opacity-50 cursor-not-allowed': currentPage === totalPages || totalPages === 0 }">
          &raquo;
        </button>
      </div>
    </div>


  </div>
</template>

<script>
import CardComponent from '@/common_components/card/card_component.vue';
import FlatCardComponent from '@/common_components/card/flatcard_component.vue';
import { mapActions, mapMutations, mapState } from 'vuex';
import { all_entity_type_values } from '@/common_variables/entity_type';

export default {
  name: 'SearchedResults',
  components: {
    CardComponent,
    FlatCardComponent,
  },
  computed: {
    ...mapState({
      show_result: (state) => state.home_search_store.show_result,
      show_more_result: (state) => state.home_search_store.show_more_result,
    }),
    // Compute total pages based on count and items per page
    totalPages() {
      const itemsPerPage = this.value_prop?.page_size || 20;// Assuming 20 items per page as standard
      return Math.ceil(this.value_prop.count / itemsPerPage);
    },
    // Fixed: Create a computed property for page range to avoid v-if with v-for
    displayedPageRange() {
      // Only return pages that are between startPage and endPage and not 1 or totalPages
      const range = [];
      for (let i = this.startPage; i <= this.endPage; i++) {
        if (i !== 1 && i !== this.totalPages) {
          range.push(i);
        }
      }
      return range;
    },
    // Check if we should show pagination based on URL query parameter
    showPagination() {
      // Get the current route query parameters
      const query = this.$route.query;
      // Check if entity parameter exists in URL using safer Object.prototype method
      return Object.prototype.hasOwnProperty.call(query, 'entity');
    }
  },
  props: {
    key_prop: {
      type: String,
      required: true,
    },
    value_prop: {
      type: Object,
      required: true,
    },
    search_topic_display: {
      type: String,
      required: true,
    },
    viewMode: {
      type: String,
      default: 'grid'
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      paginationLoading: false, // New flag to track pagination loading specifically
      local_prop_result: this.value_prop.results,
      currentPage: 1,
      startPage: 1,
      endPage: 5,
      maxVisiblePages: 5,
    };
  },
  methods: {
    ...mapActions('home_search_store', ['show_more_results_api']),
    ...mapMutations('home_search_store', ['reset_home_search_result_list']),

    getEntityName(key) {
      const entityNames = {
        media: 'Media',
        media_credit: 'Creators',
        character: 'Characters',
        place: 'Places',
        item: 'Items',
        time_period: 'Time Periods',
        genre: 'Genres',
        topic: 'Topics',
        theme: 'Themes',
        activity: 'Activities',
        purchase_oppurtunity: 'Purchase Opportunities',
        career_interest: 'Career Interests',
      };
      return entityNames[key] || key;
    },

    mediaSentence(key) {
      let temp_all_entity_type_values = JSON.parse(
        JSON.stringify(all_entity_type_values),
      );

      if (key === 'media') {
        temp_all_entity_type_values['media'] = {
          entity_type_key: 'media',
          singular: 'Media',
          plural: 'Media',
        };
      }
      return `${temp_all_entity_type_values[key]['singular']}: `;
    },

    // Method to fetch data for a specific page
    async fetchPage(page) {
      this.loading = true; // Set loading to true before API call
      this.paginationLoading = true; // Set pagination loading to true for pagination changes
      try {
        const query = this.$route.params.search_topic || ''; // Use empty string if search_topic is not provided
        await Promise.all([
          this.show_more_results_api({
            entity_type: this.key_prop,
            query: query,
            page: page,
            filters: this.filters,
          }),
        ]);
        // Update the local results with the new page data
        this.local_prop_result = this.show_result[this.key_prop].results;
      } catch (error) {
        console.error('Error fetching data for page:', error);
      } finally {
        // Set loading and paginationLoading to false after data is loaded
        this.loading = false;
        this.paginationLoading = false;
      }
    },

    // Method to handle page changes
    async changePage(page) {
      if (page < 1 || page > this.totalPages || page === this.currentPage) {
        return;
      }

      // Update current page first
      this.currentPage = page;

      // Scroll to top 
      window.scrollTo({
        top: this.$el.offsetTop - -500, 
        behavior: 'smooth'
      });

     
      await new Promise(resolve => setTimeout(resolve, 300));

      // Now show loading indicators
      this.loading = true;
      this.paginationLoading = true;

      // Update pagination range
      this.updatePaginationRange();

      // Fetch the data for the new page
      try {
        const query = this.$route.params.search_topic || '';
        await Promise.all([
          this.show_more_results_api({
            entity_type: this.key_prop,
            query: query,
            page: page,
            filters: this.filters,
          }),
        ]);
        // Update the local results with the new page data
        this.local_prop_result = this.show_result[this.key_prop].results;
      } catch (error) {
        console.error('Error fetching data for page:', error);
      } finally {
        // Set loading and paginationLoading to false after data is loaded
        this.loading = false;
        this.paginationLoading = false;
      }
    },

    // Previous Method to handle page changes
    // async changePage(page) {
    //   if (page < 1 || page > this.totalPages || page === this.currentPage) {
    //     return;
    //   }

    //   this.currentPage = page;
    //   await this.fetchPage(page);

    //   // Update pagination range
    //   this.updatePaginationRange();

    //   // Scroll to top of results after page change
    //   this.$nextTick(() => {
    //     window.scrollTo({
    //       // top: this.$el.offsetTop - 100,
    //       top: this.$el.offsetTop - -500, // Scrolls up by 500 pixels
    //       behavior: 'smooth'
    //     });
    //   });
    // },

    // Update which page numbers to show
    updatePaginationRange() {
      if (this.totalPages <= this.maxVisiblePages) {
        // If total pages is less than max visible, show all
        this.startPage = 1;
        this.endPage = this.totalPages;
      } else {
        // Calculate the start and end pages
        const halfVisible = Math.floor(this.maxVisiblePages / 2);

        if (this.currentPage <= halfVisible + 1) {
          // Near the start
          this.startPage = 2;
          this.endPage = this.maxVisiblePages;
        } else if (this.currentPage >= this.totalPages - halfVisible) {
          // Near the end
          this.startPage = this.totalPages - this.maxVisiblePages + 1;
          this.endPage = this.totalPages - 1;
        } else {
          // In the middle
          this.startPage = this.currentPage - halfVisible + 1;
          this.endPage = this.currentPage + halfVisible - 1;
        }

        // Adjust if needed
        if (this.startPage <= 1) this.startPage = 2;
        if (this.endPage >= this.totalPages) this.endPage = this.totalPages - 1;
      }
    }
  },
  // Update pagination range on component mounted
  mounted() {
    this.updatePaginationRange();

    // For testing: Uncomment this line to see loading state on mount
    // this.loading = true;
  },
  // Watch for changes in value_prop to update local data
  watch: {
    'value_prop.results': {
      handler(newResults) {
        this.local_prop_result = newResults;
        this.currentPage = 1;
        this.updatePaginationRange();
      },
      immediate: true
    },
    // Watch for changes in the route to conditionally show pagination
    '$route.query': {
      handler() {
        // No action needed here as the showPagination computed property will update automatically
      },
      deep: true
    }
  }
};
</script>

<style>
/* Original loading animation styles */
.main-item {
  position: relative;
  height: 100px;
  display: flex;
  gap: 20px;
  margin: 20px 0;
  overflow: hidden;
}

.animated-background {
  width: 80%;
  height: 200px;
  border-radius: 4px;
  background: linear-gradient(90deg, #EEEEEE 0%, #F5F5F5 50%, #EEEEEE 100%);
  background-size: 200% 100%;
  animation: placeHolderShimmer 1.5s infinite ease-in-out;
}

.background-masker {
  width: 75%;
  height: 100%;
  border-radius: 4px;
  margin-left: 5px;
  background: linear-gradient(90deg, #EEEEEE 0%, #F5F5F5 50%, #EEEEEE 100%);
  background-size: 200% 100%;
  animation: placeHolderShimmer 1.5s infinite ease-in-out;
  animation-delay: 0.2s;
  /* Slight delay for staggered effect */
}

/* loading indicator styles for pagination */
.loading-indicator-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1000;
}


@keyframes placeHolderShimmer {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}

/* Optional: Add custom pagination styles */
.pagination-active {
  background-color: #1E40AF;
  color: white;
}
</style>