<template>
  <div class="w-full  mx-auto">
    <HomeHeaders />
    <LoginPopup v-if="!isLogin" page="satisfy_curiosity" />
    <LoginPopup v-if="show_login_popup" page="satisfy_curiosity" />
    <div class="relative px-5  flex justify-start items-center h-[90vh] xs:h-[83vh] bg-contain mx-auto font-inter"
      :style="{ backgroundImage: `url(${require('../../images/poster2.jpg')})` }">

      <!-- Overlay with semi-transparent black background -->
      <div class="absolute inset-0 bg-black opacity-60"></div>
      <!-- <div
        class="fixed z-50 w-[982px] max-[1024px]:w-full text-center mt-10 bg-white py-5"
      >
        <h3 class="text-2xl font-semibold">Coming Soon!</h3>
        <p>Stay Tuned For Updates</p>
      </div> -->
      <!-- <div class="blur-div"> -->


      <div class=" relative mx-auto z-50 lg:gap-5">
        <div class="bg-white/10 rounded-2xl  p-5 py-8  backdrop-blur-lg shadow-lg">
          <div
            class="xs:flex relative  z-10 gap-2 xs:gap-4 px-2 py-4  sm:px-6 items-center justify-center text-white rounded-2xl  text-center">
            <img src="../../images/idea.png" alt="Bulb" class="w-24 mx-auto max-[640px]:mb-3" />
            <p class="sm:text-xl vsx:text-md text-sm font-bold ">
              Satisfy My Curiosity helps you find media suggestions based on your interests!
            </p>
          </div>
          <form class="">
            <div class="w-full text-white lg:px-5 mb-9">
              <p class="lg:text-4xl text-xl sm:text-2xl font-bold text-center text-white mb-8">Start Searching</p>
              <label for="interest" class="lg:text-lg text-center xs:text-md text-sm sm::text-lg">
                To start, please enter a topic that you are interested in.
              </label>
              <input v-model="search_topic_query" type="text"
                class="border-1 border-borderColor rounded-2xl text-black  text-lg sm:text-2xl px-4 p-3 font-light w-full mt-7"
                placeholder="Search" />
            </div>
            <div class="flex justify-center">
              <button @click.prevent="search_navigation"
                class="border-1 rounded-2xl border-blue bg-blue sm:px-12 sm:py-3 py-2 px-5 xs:px-8 text-md xs:text-xl sm:text-2xl text-white font-medium hover:border-1 hover:bg-white hover:border-blue hover:text-blue duration-500">
                Next
              </button>
            </div>
          </form>
        </div>
      </div>

      <div class="recently-bottom-border  mt-10 mb-7"></div>
    </div>
    <HomeFooter />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import HomeHeaders from '@/common_components/header/header.vue';
import HomeFooter from '@/common_components/footer/footer.vue';
import LoginPopup from '@/common_components/login_popup/login_popup.vue';
import { logout_messages } from '@/common_variables/logout_messages';

export default {
  name: 'SatisfyCuriosity',
  components: {
    HomeHeaders,
    HomeFooter,
    LoginPopup,
  },

  computed: {
    ...mapState({
      show_login_popup: (state) => state.home_logout_store.show_login_popup,
    }),
  },

  data() {
    return {
      search_topic_query: '',
      search_interest_query: '',
      isLogin: false,
    };
  },
  mounted() {
    const token = localStorage.getItem('token');
    if (token === undefined || token === null || token === '') {
      this.isLogin = false;
      this.set_msg(logout_messages.satisfy_curiosity);
    } else {
      this.isLogin = true;
    }
  },
  methods: {
    ...mapMutations('home_logout_store', ['set_show_login_popup', 'set_msg']),

    search_navigation() {
      if (
        localStorage.getItem('token') === undefined ||
        localStorage.getItem('token') === null ||
        localStorage.getItem('token') === ''
      ) {
        this.set_msg(logout_messages.satisfy_curiosity);
        this.set_show_login_popup(true);
      } else if (this.search_topic_query.length == 0) {
        this.$toast.open({
          message: 'Enter a Search Topic',
          type: 'error',
          duration: 1000 * 5,
          dismissible: true,
        });
      } else {
        if (this.search_interest_query == '') {
          this.$router.push({
            name: 'search-satisfy',
            params: {
              search_topic: this.search_topic_query,
              search_interest: '',
            },
          });
        } else {
          this.$router.push({
            name: 'search-satisfy',
            params: {
              search_topic: this.search_topic_query,
              search_interest: this.search_interest_query,
            },
          });
        }
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style></style>
