<template>
  <LoginPopup v-if="show_login_popup" />

  <div class="w-full mx-auto font-inter">
    <!-- Header Start -->
    <HomeHeader />
    <!-- Header End -->

    <!-- Quick Media Search Start -->
    <div v-if="loading" class="loading-indicator">
      <div class="loader"></div>
    </div>
    <div class="flex flex-col justify-center items-center py-[160px]" :style="{
      backgroundImage: `url(${require('../../images/media-search-bg.jpg')})`,
      backgroundSize: 'cover',
    }">
      <h2  class="text-3xl relative z-10 md:text-5xl text-white text-center font-bold pb-16 sm:pb-6">
        Quick Media Search
      </h2>
      <SearchComponent />
    </div>
    <!-- Quick Media Search End -->

    <!-- Most Viewed Start -->
    <section class="py-10 px-8 sm:px-10 font-inter ">
      <div class="flex w-full justify-between items-center mb-8 ">
        <h2 class="sm:text-2xl text-xl md:text-3xl font-bold  ">
          Most Viewed
        </h2>
        <template v-if="most_viewed_data && most_viewed_data.result.results.length > 4">
          <a @click="search_result_navigate('most_viewed')"
            class="flex items-center px-3 py-2 rounded-full bg-blue text-white">
            <span class="text-[10px] vsx:text-xs sm:text-md text-white cursor-pointer">See more</span>
            <span class="ml-3 sm:block hidden font-semibold text-white">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                class="bi bi-arrow-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
              </svg>
            </span>
          </a>
        </template>
      </div>

      <!-- Most viewed slider start -->
      <template v-if="most_viewed_data && most_viewed_data.result.results.length > 0">
        <div class="relative ">
          <template v-if="most_viewed_data.result.results.length > 4">
            <div class="previous-viewed">
              <button type="button" class="absolute p-4 py-2 rounded-full bg-blue text-white z-20 top-[40%] -left-8">
                <span class="icon-slider-leftarrow text-xl"></span>
              </button>
            </div>
            <div class="next-viewed">
              <button type="button" class="absolute p-4 py-2 rounded-full bg-blue text-white z-20 top-[40%] -right-8">
                <span class="icon-slider-rightarrow text-xl"></span>
              </button>
            </div>
          </template>
          <div class="most-viewed-slider justify-center w-full">
            <template v-for="(item, index) in most_viewed_data.result.results" :key="index">
              <CardComponent :card_data="item" />
            </template>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="w-full">
          <p>No Media Available</p>
        </div>
      </template>
      <!-- Most viewed slider end -->
    </section>
    <!-- Most Viewed End -->

    <!-- <div class="border-b-1 border-borderColor mt-16 mb-10 mx-10 max-[767px]:mx-5"></div> -->

    <!-- Trending Media Start -->
    <section class="py-10 px-8 sm:px-10  font-inter ">
      <div class="flex w-full justify-between items-center mb-8 ">
        <h2 class="sm:text-2xl text-xl md:text-3xl font-bold  ">
          Trending Media
        </h2>
        <template v-if="
          trending_media_data && trending_media_data.result.results.length > 3
        ">
          <a @click="search_result_navigate('trending_media')"
            class="flex items-center px-3 py-2 rounded-full bg-blue text-white">
            <span class="text-[10px] vsx:text-xs sm:text-md text-white cursor-pointer">See more</span>
            <span class="ml-3 sm:block hidden font-semibold text-white">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                class="bi bi-arrow-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
              </svg>
            </span>
          </a>
        </template>
      </div>
      <!-- slider start items trending_media_data.result.results -->
      <template v-if="
        trending_media_data && trending_media_data.result.results.length > 0
      ">
        <div class="relative ">
          <template v-if="trending_media_data.result.results.length > 3">
            <div class="previous-trending-media">
              <button type="button" class="absolute p-4 py-2 rounded-full bg-blue text-white z-20 top-[40%] -left-8">
                <span class="icon-slider-leftarrow text-xl"></span>
              </button>
            </div>
            <div class="next-trending-media">
              <button type="button" class="absolute p-4 py-2 rounded-full bg-blue text-white z-20 top-[40%] -right-8">
                <span class="icon-slider-rightarrow text-xl"></span>
              </button>
            </div>
          </template>
          <div class="trending-media-slider justify-center w-full">
            <template v-for="(item, index) in trending_media_data.result.results" :key="index">
              <CardComponent :card_data="item" />
            </template>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="w-full px-10">
          <p>No Media Available</p>
        </div>
      </template>
      <!-- slider end -->
    </section>
    <!-- Trending Media End -->

    <!-- Feature Media Start -->
    <section class="py-10 px-8 sm:px-10 font-inter ">
      <div class="flex  w-full justify-between items-center mb-8 ">
        <h2 class="sm:text-2xl text-xl md:text-3xl font-bold  ">
          Featured Media
        </h2>
        <template v-if="
          feature_media_data && feature_media_data.result.results.length > 4
        ">
          <a @click="search_result_navigate('featured_media')"
            class="flex items-center px-3 py-2 rounded-full bg-blue text-white">
            <span class="text-[10px] vsx:text-xs sm:text-md text-white cursor-pointer">See more</span>
            <span class="ml-3 sm:block hidden font-semibold text-white">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                class="bi bi-arrow-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
              </svg>
            </span>
          </a>
        </template>
      </div>
      <!-- slider start -->
      <template v-if="
        feature_media_data && feature_media_data.result.results.length > 0
      ">
        <div class="relative ">
          <template v-if="feature_media_data.result.results.length > 4">
            <div class="previous-feature-media">
              <button type="button" class="absolute p-4 py-2 rounded-full bg-blue text-white z-20 top-[40%] -left-8">
                <span class="icon-slider-leftarrow text-xl"></span>
              </button>
            </div>
            <div class="next-feature-media">
              <button type="button" class="absolute p-4 py-2 rounded-full bg-blue text-white z-20 top-[40%] -right-8">
                <span class="icon-slider-rightarrow text-xl"></span>
              </button>
            </div>
          </template>
          <div class="featured-media-slider justify-center w-full">
            <template v-for="(item, index) in feature_media_data.result.results" :key="index">
              <CardComponent :card_data="item" />
            </template>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="w-full px-10 pt-10">
          <p>No Media Available</p>
        </div>
      </template>
      <!-- slider start -->
    </section>
    <!-- Feature Media End -->

    <div class="recently-bottom-border border-b-1 border-borderColor mt-20 mb-4 mx-20"></div>
    <!-- Footer Start -->
    <HomeFooter />
    <!-- Footer End -->
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import $ from 'jquery';
import HomeHeader from '@/common_components/header/header.vue';
import HomeFooter from '@/common_components/footer/footer.vue';
import initializeSliders from './home_logout_slider.js';
import CardComponent from '@/common_components/card/card_component.vue';
import SearchComponent from '@/controller/home/components/search_component.vue';
import LoginPopup from '@/common_components/login_popup/login_popup.vue';

export default {
  name: 'HomeLogout',
  components: {
    HomeHeader,
    HomeFooter,
    CardComponent,
    SearchComponent,
    LoginPopup,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState({
      most_viewed_data: (state) => state.home_logout_store.most_viewed_data,
      trending_media_data: (state) =>
        state.home_logout_store.trending_media_data,
      feature_media_data: (state) => state.home_logout_store.feature_media_data,
      show_login_popup: (state) => state.home_logout_store.show_login_popup,
    }),
  },

  async created() {
    await this.fetchDataFromApis();
    initializeSliders();
  },

  methods: {
    ...mapActions('home_logout_store', [
      'most_viewed_api',
      'trending_media_api',
      'feature_media_api',
    ]),

    openPopup() {
      document.querySelector('#popupContent').classList.remove('hidden');
    },

    search_result_navigate(search_type) {
      this.$router.push({
        name: 'search-result',
        query: {
          media: 'all',
          search_type: search_type,
          selected_media: 'All',
        },
      });
    },

    async fetchDataFromApis() {
      this.loading = true;
      try {
        await Promise.all([
          this.most_viewed_api(),
          this.trending_media_api(),
          this.feature_media_api(),
        ]);
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.loading = false;
      }
    },
  },

  mounted() { },
  beforeUnmount() {
    $('.most-viewed-slider').slick('unslick');
    $('.trending-media-slider').slick('unslick');
    $('.featured-media-slider').slick('unslick');
  },
};
</script>

<style>
@import '../../css/slick.css';
@import '../../css/slick-theme.css';
@import '../../css/icomoon.css';
@import '../../css/style.css';
@import '../../css/discovery.css';

.most-viewed-slider,
.trending-media-slider,
.featured-media-slider {
  overflow: hidden;
}

.slick-track {
  display: flex !important;
  padding: 5px 0;
}

/* the slides */
.slick-slide {
  margin: 0px;
  height: inherit !important;
}

/* the parent */
.slick-list {
  margin: 0px;
}

.trending-media-slider .slick-slide {
  margin: 0 9px;
  height: inherit !important;
}

.trending-media-slider .slick-list {
  margin: 0 -9px;
}

.most-viewed-slider .slick-slide {
  margin: 0 9px;
  height: inherit !important;
}

.most-viewed-slider .slick-list {
  margin: 0 -9px;
}

.featured-media-slider .slick-slide {
  margin: 0 9px;
  height: inherit !important;
}

.featured-media-slider .slick-list {
  margin: 0 -9px;
}


</style>
