<div class="lg:h-screen lg:overflow-hidden">
  <div class="w-full px-5 mx-auto">
    <div class="flex justify-center">
      <div class="lg:w-1/2 hidden sm:hidden md:hidden lg:block lg:h-screen">
        <img
          src="../../images/create-account-banner.jpg"
          alt="Create Account Banner"
          class="max-h-full w-full object-contain"
        />
      </div>
      <div
        class="flex flex-col justify-items-center justify-center w-full sm:w-full md:w-full lg:w-1/2 sm:px-10 py-10"
      >
        <h1 class="font-bold text-blueDark text-2xl sm:text-3xl md:text-4xl mb-8 sm:mb-5">
          Login to your account!
        </h1>
        <!-- <div
        class="flex flex-row max-[515px]:flex-col lg:flex-col xl:flex-row 2xl:flex-row mb-7"
      >
        <button
          type="button"
          class="border-solid border-silver border-1 rounded-full px-5 pt-5 pb-4 mt-6 mr-5 text-center w-auto lg:w-full max-[515px]:w-full flex justify-center justify-items-center hover:bg-silver transition ease-in-out duration-300"
        >
          <img src="../../images/google.svg" alt="Google" />
          <span class="ml-3 font-medium text-blueDark text-sm"
            >Login with Google</span
          >
        </button>
        <button
          type="button"
          class="border-solid border-silver border-1 rounded-full px-5 py-4 mt-6 text-center w-auto lg:w-full flex justify-center justify-items-center hover:bg-silver transition ease-in-out duration-300"
        >
          <img src="../../images/facebook.svg" alt="Facebook" />
          <span
            class="ml-3 xl:ml-1 text-nowrap font-medium text-blueDark text-sm mt-1"
            >Login with Facebook</span
          >
        </button>
      </div> -->
        <div
          class="login-form xl:w-2/3 max-[639px]:w-full lg:w-full md:w-full sm:w-full"
        >
          <form>
            <div class="flex flex-col mb-14">
              <label for="email" class="text-blueDark text-sm"
                >Email Address</label
              >
              <input
                v-model="email"
                type="email"
                name="email"
                id="email"
                placeholder="youraddress@mail.com"
                class="border-b-2 border-silver py-4 text-blueDark focus:border-b-2 text-xl font-medium focus:outline-none"
              />
            </div>
            <div class="flex flex-col relative">
              <label for="password" class="text-blueDark text-sm"
                >Password</label
              >
              <div class="relative">
                <input
                  v-model="password"
                  :type="passwordFieldType"
                  name="password"
                  id="password"
                  placeholder="Enter your password"
                  class="border-b-2 w-full pr-4 border-silver py-4 text-blueDark focus:border-b-2 text-xl font-medium focus:outline-none"
                />
                <a
                  @click="togglePasswordVisibility"
                  class="absolute right-5 bottom-5 cursor-pointer"
                >
                  <svg
                    width="28"
                    height="24"
                    viewBox="0 0 28 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M13.8853 15.358C14.7773 15.358 15.6327 15.0007 16.2634 14.3647C16.8942 13.7286 17.2485 12.866 17.2485 11.9665C17.2485 11.8672 17.244 11.7688 17.2356 11.6716L13.5928 15.345C13.6892 15.3535 13.7865 15.358 13.8853 15.358ZM25.0176 1.47589L23.7347 0.183492C23.6897 0.138095 23.6286 0.112595 23.5649 0.112595C23.5012 0.112595 23.4402 0.138095 23.3951 0.183492L20.1124 3.49474C18.3014 2.56148 16.2658 2.09484 14.0054 2.09484C8.23384 2.09484 3.92771 5.12599 1.08698 11.1883C0.972793 11.4321 0.913574 11.6984 0.913574 11.968C0.913574 12.2377 0.972793 12.504 1.08698 12.7478C2.22207 15.1588 3.58888 17.0912 5.18742 18.5451L2.01066 21.7474C1.96565 21.7928 1.94036 21.8544 1.94036 21.9186C1.94036 21.9828 1.96565 22.0444 2.01066 22.0898L3.29259 23.3825C3.33764 23.4279 3.39872 23.4534 3.46241 23.4534C3.52609 23.4534 3.58717 23.4279 3.63222 23.3825L25.0176 1.81868C25.0399 1.79618 25.0576 1.76946 25.0697 1.74005C25.0818 1.71064 25.088 1.67912 25.088 1.64729C25.088 1.61545 25.0818 1.58393 25.0697 1.55452C25.0576 1.52511 25.0399 1.49839 25.0176 1.47589ZM8.60019 11.9665C8.60011 11.046 8.83647 10.1411 9.28625 9.34009C9.73604 8.53906 10.3839 7.86916 11.1667 7.39566C11.9496 6.92216 12.8407 6.6612 13.7533 6.6382C14.6658 6.61521 15.5688 6.83097 16.3741 7.26445L14.9141 8.73672C14.3253 8.54661 13.6959 8.52371 13.095 8.67055C12.4942 8.81738 11.945 9.12828 11.5079 9.56913C11.0707 10.01 10.7624 10.5637 10.6168 11.1696C10.4712 11.7756 10.4939 12.4102 10.6824 13.004L9.22239 14.4762C8.81268 13.7043 8.59892 12.8421 8.60019 11.9665Z"
                      fill="#03014C"
                    />
                    <path
                      opacity="0.3"
                      d="M27.4117 11.3353C26.3323 9.13296 25.0476 7.33716 23.5575 5.94785L19.1374 10.2302C19.51 11.1737 19.5926 12.2016 19.3752 13.1896C19.1578 14.1776 18.6497 15.0836 17.9124 15.7979C17.175 16.5122 16.2399 17.0044 15.22 17.215C14.2001 17.4257 13.1391 17.3456 12.1651 16.9847L8.41602 20.6167C10.1553 21.3964 12.0899 21.7863 14.2197 21.7863C20.1135 21.7863 24.5108 18.8126 27.4117 12.8652C27.5284 12.626 27.5889 12.3648 27.5889 12.1002C27.5889 11.8357 27.5284 11.5745 27.4117 11.3353Z"
                      fill="#03014C"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <button
              @click.prevent="login_user()"
              type="submit"
              class="text-md mt-16 sm:text-xl text-white font-bold text-center border-2 border-blue bg-blue py-3 px-6 rounded-2xl hover:border-blue hover:border-1 hover:bg-white hover:text-blue duration-500" 
            >
              Login to Continue
            </button>
          </form>
        </div>
        <p class="mt-10 text-sm">
          Don't you have account?
          <a
            @click.prevent="create_account_navigate()"
            href="/"
            class="text-blue font-bold"
          >
            Sign Up
          </a>
        </p>
      </div>
    </div>
  </div>
</div>
