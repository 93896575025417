<template>
  <LoginPopup v-if="show_login_popup" />
  <div class="w-full mx-auto">
    <!-- Header Start -->
    <HomeHeaders />
    <!-- Header End -->
    <div v-if="loading" class="loading-indicator">
      <div class="loader"></div>
    </div>
    <div class="mx-auto  ">
      <div class="relative overflow-hidden w-full py-[80px] sm:py-[100px] bg-cover flex justify-center items-center"
        :style="{ backgroundImage: `url(${require('../../images/media-search-bg.jpg')})` }">

        <!-- Overlay with semi-transparent black background -->
        <!-- <div class="absolute inset-0 bg-black opacity-60 "></div> -->

        <!-- Text on top of overlay -->
        <div class="relative">
          <h2 class="text-2xl sm:text-4xl md:text-6xl text-center text-white font-bold w-full ">
            {{ text_heading }}
          </h2>
          <div class="p-5 flex justify-center">
            <button
            class="text-white bg-blue xs:px-6 xs:py-3 px-4 py-2 sm:text-lg font-medium border-1 border-blue rounded-2xl hover:text-blue hover:bg-white duration-500"
            @click="goBack">
            Back To Home
          </button>
          </div>
        </div>

      </div>


      <div class="px-2 sm:px-5">
        <div class="flex gap-2 items-center text-xs vsx:text-sm sm:text-md flex-wrap my-6">
          <button v-for="(text, type) in media_type_dict" :key="type" @click="select_media_type(type, text)"
            :class="get_class(type)">
            {{ text }}
          </button>
        </div>

        <!-- <h2 class="text-4xl max-[640px]:text-2xl border-2 border-black font-bold w-full mb-9 max-[640px]:mb-5">
          {{ text_heading }}
        </h2> -->
        <div v-if="search_result_data_list.length === 0" class="text-2xl font-semibold my-5 text-center">
          No Results Found
        </div>
        <template v-if="search_result_data">
          <div class="grid gap-2 xs:gap-4  min-[350px]:px-0 px-3 max-[640px]:gap-y-4 mb-10" :class="search_type === 'favorite_media' ||
            search_type === 'favorite_search_query'
            ? 'grid-cols-1 min-[350px]:grid-cols-2 min-[550px]:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xxl:grid-cols-6'
            : 'grid-cols-1 min-[350px]:grid-cols-2 min-[550px]:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xxl:grid-cols-6'
            ">
            <!-- start -->
            <template v-if="
              search_type === 'favorite_media' ||
              search_type === 'favorite_search_query'
            ">
              <div v-for="(item, index) in search_result_data_list" :key="index">
                <FavCardComponent :favorite_list_data="item" />
              </div>
            </template>
            <template v-else>
              <div v-for="(item, index) in search_result_data_list" :key="index">
                <CardComponent :card_data="item" />
              </div>
            </template>
          </div>
          <div v-if="show_more_result" class="main-item">
            <div class="animated-background">
              <div class="background-masker btn-divide-left"></div>
            </div>
          </div>
          <div v-if="search_result_data.result.next != null" class="flex justify-center items-center cursor-pointer">
            <SeeMoreIcon @click="see_more_result()" />
          </div>
        </template>
      </div>
    </div>

    <div class="recently-bottom-border border-b-1 border-borderColor mt-10 mb-7"></div>
    <!-- Footer Start -->
    <HomeFooter />
    <!-- Footer End -->
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import HomeHeaders from '@/common_components/header/header.vue';
import HomeFooter from '@/common_components/footer/footer.vue';
import CardComponent from '@/common_components/card/card_component.vue';
import LoginPopup from '@/common_components/login_popup/login_popup.vue';
import FavCardComponent from '@/common_components/fav_card/fav_card_component.vue';
import SeeMoreIcon from '@/common_components/show_more/show_more_component.vue';
import { all_media_type_values } from '@/common_variables/media_type';
import { all_entity_type_values } from '@/common_variables/entity_type';

export default {
  name: 'SearchResult',
  components: {
    HomeHeaders,
    HomeFooter,
    CardComponent,
    LoginPopup,
    FavCardComponent,
    SeeMoreIcon,
  },

  computed: {
    ...mapState({
      search_result_data: (state) =>
        state.search_result_store.search_result_data,
      search_result_data_list: (state) =>
        state.search_result_store.search_result_data_list,
      show_login_popup: (state) => state.home_logout_store.show_login_popup,
    }),

    // Object.keys(all_media_type_values).reduce((acc, key) => {
    //                     acc[key] = all_media_type_values[key].creator_label;
    //                   return acc; }, {})

    media_type_dict() {
      const all_option =
        this.$route.query.search_type === 'favorite_media' ||
          this.$route.query.search_type === 'favorite_search_query'
          ? 'All'
          : 'All Media';

      let media_dict = {
        all: all_option,
      };

      Object.keys(all_media_type_values).forEach((key) => {
        media_dict[key] = all_media_type_values[key].plural;
      });

      if (this.$route.query.search_type === 'favorite_media') {
        Object.keys(all_entity_type_values).forEach((key) => {
          media_dict[key] = all_entity_type_values[key].plural;
        });
      }

      return media_dict;
    },

    text_heading() {
      if (this.search_type === 'search_query') {
        return `Search results for '${this.$route.query.query}' in ${this.selected_media_text}`;
      } else if (this.search_type === 'favorite_search_query') {
        if (this.selected_media_text.includes('All')) {
          return `Search results for '${this.$route.query.query}' in All Favorites`;
        } else {
          return `Search results for '${this.$route.query.query}' in All Favorites ${this.selected_media_text}`;
        }
      } else if (this.search_type === 'most_viewed') {
        return `Most Viewed in ${this.selected_media_text}`;
      } else if (this.search_type === 'trending_media') {
        return `Trending Media in ${this.selected_media_text}`;
      } else if (this.search_type === 'featured_media') {
        return `Featured Media in ${this.selected_media_text}`;
      } else if (this.search_type === 'recently_viewed') {
        return `Recently Viewed in ${this.selected_media_text}`;
      } else if (
        this.search_type === 'favorite_media' &&
        this.selected_media_text != 'All'
      ) {
        return `All Favorites in  ${this.selected_media_text}`;
      } else if (this.search_type === 'favorite_media') {
        return 'All Favorites';
      } else {
        return '';
      }
    },
  },

  data() {
    return {
      loading: false,
      show_more_result: false,
      page: 1,
      media_selected: this.$route.query.media,
      search_type: null,
      selected_media_text: this.$route.query.selected_media,
      entity_type_display: Object.keys(all_entity_type_values).reduce(
        (acc, key) => {
          acc[key] = all_entity_type_values[key].plural;
          return acc;
        },
        {},
      ),
    };
  },

  async created() {
    this.search_type = this.$route.query.search_type;
    if (
      this.search_type === 'favorite_media' ||
      this.search_type === 'favorite_search_query'
    ) {
      this.entity_type_display['media'] = {
        entity_type_key: 'media',
        singular: 'Media',
        plural: 'Media',
      };
      this.media_type_dict = {
        ...this.media_type_dict,
        ...this.entity_type_display,
      };
    }
    this.reset_search_result_data_list();
    await this.fetchDataFromApis(
      this.$route.query.media,
      this.$route.query.query,
      this.page,
      this.search_type,
    );
  },

  methods: {
    ...mapActions('search_result_store', ['search_result_api']),
    ...mapMutations('search_result_store', ['reset_search_result_data_list']),

    get_class(type) {
      return this.media_selected === type
        ? 'text-md font-semibold text-white border-1 border-blue bg-blue text-center rounded-xl sm:rounded-2xl  py-2 px-5 mb-3'
        : 'text-md font-semibold text-blue border-1 border-blue text-center rounded-xl sm:rounded-2xl py-2 px-5  mb-3';
    },

    async see_more_result() {
      if (this.search_result_data.result.next != null) {
        await this.show_more_results(
          this.$route.query.media,
          this.$route.query.query,
          this.search_result_data.result.next,
          this.search_type,
        );
      }
    },
    async select_media_type(media_type, media_text) {
      this.selected_media_text = media_text;
      this.reset_search_result_data_list();
      this.media_selected = media_type;
      await this.fetchDataFromApis(
        this.media_selected,
        this.$route.query.query,
        this.page,
        this.search_type,
      );
    },

    async fetchDataFromApis(media_type, query, page, search_type) {
      this.loading = true;
      try {
        await Promise.all([
          this.search_result_api({
            media_type: media_type,
            query: query,
            page: page,
            search_type: search_type,
          }),
        ]);
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.loading = false;
      }
    },

    async show_more_results(media_type, query, page, search_type) {
      this.show_more_result = true;
      try {
        await Promise.all([
          this.search_result_api({
            media_type: media_type,
            query: query,
            page: page,
            search_type: search_type,
          }),
        ]);
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.show_more_result = false;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style></style>
