<template>
  <div id="profileSettingModal"
    class="fixed hidden z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4">
    <div class="relative h-screen flex justify-center items-center">
      <div class="bg-white px-28 py-16 rounded-3xl font-inter text-center w-[784px]">
        <h2 class="text-4xl text-blue font-bold mb-4">Profile Settings</h2>
        <p class="text-lg text-black opacity-60 mb-11">
          Update your profile information and preferences.
        </p>
        <button
          class="bg-blue border-1 text-sm font-medium text-white px-11 py-3 rounded-full hover:bg-white hover:text-blue hover:border-1 hover:border-blue duration-500"
          @click="closeModal()">
          Close
        </button>
      </div>
    </div>
  </div>
  <div class="w-full mx-auto">
    <!-- Header Start -->
    <HomeHeader :full_name_header="this.full_name_header" />
    <!-- Header End -->

    <template v-if="loading">
      <div class="loading-indicator">
        <div class="loader"></div>
      </div>
    </template>

    <template v-else>
      <div class="bg-cover" :style="{
        backgroundImage: `url(${require('../../images/card-bg.jpg')})`,
        // backgroundSize: 'auto',
        // clipPath: 'ellipse(90% 100% at center top)'
      }">
        <!-- User Profile Start -->
        <div class="  font-inter">
          <div class="max-w-5xl mx-auto max-[1024px]:px-4">
            <h2 class="md:text-4xl sm:text-3xl text-2xl lg:text-5xl font-semibold text-center py-12">
              Profile Settings
            </h2>
            <!-- <h2 class="text-[45px] text-blue font-semibold text-center">Level</h2> -->
            <!-- <div
            class="mx-auto  w-[976px] max-[1023px]:w-full pb-12 max-[1023px]:px-5"
          >
            <div class="flex justify-between mb-6 max-[1023px]:mb-3">
              <div class="text-4xl max-[1023px]:text-2xl font-semibold">
                Level: <span class="text-blue">{{ profile.level }}</span>
              </div>
              <div class="text-4xl max-[1023px]:text-2xl font-semibold">
                XP: <span class="text-blue">{{ profile.xp }}</span>
              </div>
            </div>

            <div class="relative bg-[#d6dcf4] h-9 w-full rounded-full">
              <span
                class="absolute h-9 bg-blue h-6 rounded-full"
                :style="{ width: profile.progress + '%' }"
              ></span>
            </div>
          </div> -->
          </div>
        </div>
        <!-- User Profile End -->

        <!-- Profile Form Start -->
        <div class=" font-inter text-profileText">
          <div class="max-w-5xl  mx-auto max-[1024px]:px-4 py-6 ">
            <form @submit.prevent="updateProfile">
              <div class="flex flex-col">
                <h3 class="text-xl sm:text-2xl font-bold text-profileText pb-2">
                  User Profile
                </h3>
                <div class="border-b-1 border-borderColor"></div>
              </div>
              <div class="flex justify-between max-[767px]:flex-col items-center mt-11 mb-20 pb-6 text-profileText">
                <div class="flex items-center max-[767px]:mb-5 max-[440px]:flex-col">
                  <!-- <img :src="profile.avatar" alt="Profile Picture" class="rounded-full shadow-lg w-11" /> -->
                  <div v-if="show_avatar">
                    <img :src="show_avatar" alt="Profile Picture" class="rounded-full shadow-lg w-11" />
                  </div>
                  <div v-else>
                    <img src="https://d3tzzn5ci4hmjp.cloudfront.net/media/avatars/default_pp.png" alt="Profile Picture"
                      class="rounded-full shadow-lg w-11 h-11" />
                  </div>
                  <div class="flex flex-col ml-5 max-[440px]:ml-0 max-[440px]:text-center max-[440px]:mt-3">
                    <h3 class="text-xl font-bold">{{ profile.full_name }}</h3>
                    <p class="text-xl">{{ profile.email }}</p>
                  </div>
                </div>
                <div class="flex max-[440px]:flex-col">
                  <div class="max-[440px]:mb-3">
                    <input type="file" id="avatarUpload" style="display: none" @change="handleAvatarUpload" />
                    <button
                      class="text-sm font-bold text-white bg-blue border-1 rounded-2xl px-5 p-3 hover:bg-white hover:border-1 hover:text-blue duration-500 hover:border-blue"
                      @click="openAvatarUpload()">
                      Upload New Photo
                    </button>
                  </div>
                  <div class="ml-14 max-[440px]:ml-0">
                    <button
                      class="text-sm font-bold text-blue bg-white border-1 border-blue rounded-2xl p-3 w-44 hover:bg-blue hover:border-blue hover:text-white duration-500"
                      @click="deleteAvatar()">
                      Delete
                    </button>
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-2 gap-x-6 gap-y-4 max-[767px]:grid-cols-1">
                <div class="flex flex-col">
                  <label for="firstName" class="text-base font-bold mb-4">First Name</label>
                  <input type="text" class="border-1 border-borderColor rounded-2xl px-5 py-3" id="firstName"
                    v-model="profile.first_name" placeholder="eg. Zia" />
                </div>
                <div class="flex flex-col">
                  <label for="lastName" class="text-base font-bold mb-4">Last Name</label>
                  <input type="text" class="border-1 border-borderColor rounded-2xl px-5 py-3" id="lastName"
                    v-model="profile.last_name" placeholder="eg. Umer" />
                </div>
                <div class="flex flex-col">
                  <label for="fullname" class="text-base font-bold mb-4">Email</label>
                  <input type="text" class="border-1 border-borderColor rounded-2xl px-5 py-3" id="fullname"
                    v-model="profile.email" placeholder="eg. ziaumer@gmail.com" />
                </div>
                <div class="flex flex-col">
                  <label for="age" class="text-base font-bold mb-4">Age</label>
                  <input type="number" class="border-1 border-borderColor rounded-2xl px-5 py-3" id="age"
                    v-model="profile.age" placeholder="18" />
                </div>
              </div>

              <div class="flex justify-end max-[440px]:flex-col mt-8 mb-5 max-[440px]:items-center">
                <div class="ml-14 max-[440px]:ml-0">
                  <button type="submit"
                    class="text-sm font-bold text-white bg-blue border-1 rounded-2xl px-5 p-3 w-44 hover:bg-white hover:border-1 hover:text-blue duration-500 hover:border-blue">
                    Save Changes
                  </button>
                </div>
              </div>

              <div class="grid grid-cols-2 gap-x-6 gap-y-4 max-[767px]:grid-cols-1">
                <div class="flex flex-col w-full mt-6 max-[767px]:mt-3">
                  <label for="newPassword" class="text-base font-bold mb-4">New Password</label>
                  <div class="relative">
                    <span class="absolute icon-key top-4 left-5 pointer-events-none"></span>
                    <input :type="showNewPassword ? 'text' : 'password'"
                      class="border-1 border-borderColor rounded-2xl px-14 py-3 w-full" id="newPassword"
                      v-model="profile.new_password" placeholder="" />
                    <span class="absolute icon-newpassword-eye top-4 right-5"
                      @click="toggleShowPassword('newPassword')"></span>
                  </div>
                </div>
                <div class="flex flex-col w-full mt-6">
                  <label for="confirmNewPassword" class="text-base font-bold mb-4">Confirm New Password</label>
                  <div class="relative">
                    <span class="absolute icon-key top-4 left-5 pointer-events-none"></span>
                    <input :type="showConfirmNewPassword ? 'text' : 'password'"
                      class="border-1 border-borderColor rounded-2xl px-14 py-3 w-full" id="confirmNewPassword"
                      v-model="profile.confirm_new_password" placeholder="" />
                    <span @click="toggleShowPassword('confirmNewPassword')"
                      class="absolute icon-newpassword-eye top-4 right-5">
                    </span>
                  </div>
                </div>
              </div>

              <div class="flex justify-end max-[440px]:flex-col mt-20 mb-20 max-[440px]:items-center">
                <div class="max-[440px]:mb-3">
                  <button type="button"
                    class="text-sm font-bold text-blue bg-white border-1 border-blue rounded-2xl px-5 p-3 w-44 hover:bg-blue hover:border-blue hover:text-white duration-500"
                    @click="cancelUpdate()">
                    Cancel
                  </button>
                </div>
                <div class="ml-14 max-[440px]:ml-0">
                  <button type="button"
                    class="text-sm font-bold text-white bg-blue border-1 rounded-2xl px-5 p-3 w-44 hover:bg-white hover:border-1 hover:text-blue duration-500 hover:border-blue"
                    @click="changePassword()">
                    Change Password
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Profile Form end -->
      <!-- <div class="recently-bottom-border border-b-1 border-borderColor mt-20 mb-4 mx-20"></div> -->
    </template>

    <!-- Footer Start -->
    <HomeFooter />
    <!-- Footer End -->
  </div>
</template>

<script>
import HomeHeader from '@/common_components/header/header.vue';
import HomeFooter from '@/common_components/footer/footer.vue';

export default {
  name: 'ProfileSettingController',
  components: {
    HomeHeader,
    HomeFooter,
  },
  data() {
    return {
      profile: {
        first_name: '',
        last_name: '',
        full_name: '',
        email: '',
        avatar: null,
        age: null,
        new_password: '',
        confirm_new_password: '',
      },
      full_name_header: '',
      initialAvatar: null,
      show_avatar: null,
      showNewPassword: false,
      showConfirmNewPassword: false,
      loading: false,
    };
  },
  methods: {
    validateInputs() {
      const errors = [];
      if (!this.profile.first_name.trim()) {
        errors.push('First Name cannot be empty.');
      }
      if (!this.profile.last_name.trim()) {
        errors.push('Last Name cannot be empty.');
      }
      if (!this.profile.email.trim()) {
        errors.push('Email cannot be empty.');
      }
      if (!this.profile.age) {
        errors.push('Age cannot be empty.');
      }
      return errors;
    },
    openAvatarUpload() {
      document.getElementById('avatarUpload').click();
    },
    handleAvatarUpload(event) {
      const file = event.target.files[0];
      if (file.size > 1048576) {
        this.$toast.open({
          message: 'Profile picture must be less than 1 MB',
          type: 'error',
          duration: 1000 * 5,
          dismissible: true,
        });
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        this.profile.avatar = reader.result;
        this.show_avatar = reader.result;
      };
      reader.readAsDataURL(file);
    },
    deleteAvatar() {
      this.profile.avatar = '';
      this.show_avatar = null;
    },

    updateProfile() {
      const errors = this.validateInputs();
      if (errors.length > 0) {
        this.$toast.open({
          message: errors.join(' '),
          type: 'error',
          duration: 1000 * 5,
          dismissible: true,
        });
        return;
      }
      this.profile.full_name =
        this.profile.first_name + ' ' + this.profile.last_name;

      // Remove avatar from the profile if it has not been changed
      if (this.profile.avatar === this.initialAvatar) {
        delete this.profile.avatar;
      }

      this.loading = true;
      if (
        this.profile.avatar &&
        typeof this.profile.avatar === 'string' &&
        this.profile.avatar.startsWith('http')
      ) {
        this.convertImageToBase64(this.profile.avatar)
          .then((base64Image) => {
            this.profile.avatar = base64Image;
            this.sendProfileUpdateRequest();
          })
          .catch((error) => {
            console.error('Error converting image to base64:', error);
            this.$toast.open({
              message: 'Error converting image to base64',
              type: 'error',
              duration: 1000 * 5,
              dismissible: true,
            });
            this.loading = false;
          });
      } else {
        this.sendProfileUpdateRequest();
      }
    },
    sendProfileUpdateRequest() {
      const { id, ...profileData } = this.profile;
      this.$store
        .dispatch('profile_setting_store/update_profile', {
          id,
          profile: profileData,
        })
        .then(() => {
          this.full_name_header = this.profile.full_name;
          this.$emit('profileUpdated', this.profile.avatar);
          this.$toast.open({
            message: 'Profile Updated Successfully!',
            type: 'success',
            duration: 1000 * 5,
            dismissible: true,
          });
          this.loading = false;
        })
        .catch((error) => {
          console.error('Error updating profile:', error);
          this.$toast.open({
            message: error.message || 'Error updating profile',
            type: 'error',
            duration: 1000 * 5,
            dismissible: true,
          });
          this.loading = false;
        });
    },
    convertImageToBase64(url) {
      return new Promise((resolve, reject) => {
        const proxyUrl = 'https://api.allorigins.win/raw?url=';
        const proxiedUrl = proxyUrl + encodeURIComponent(url);

        fetch(proxiedUrl)
          .then((response) => response.blob())
          .then((blob) => {
            const reader = new FileReader();
            reader.onload = () => {
              resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
          .catch((error) => {
            console.error('Error fetching image via proxy:', error);
            reject(new Error('Failed to load image via proxy'));
          });
      });
    },

    cancelUpdate() {
      this.$router.push({ name: 'home' });
    },
    fetchProfile() {
      this.loading = true;
      this.$store
        .dispatch('profile_setting_store/fetch_profile')
        .then((response) => {
          this.profile = response;
          this.initialAvatar = response.avatar; // Store the initial avatar
          this.show_avatar = response.avatar;
          this.loading = false;
        })
        .catch((error) => {
          console.error('Error fetching profile:', error);
          this.$toast.open({
            message: 'Error fetching profile',
            type: 'error',
            duration: 1000 * 5,
            dismissible: true,
          });
          this.loading = false;
        });
    },
    toggleShowPassword(field) {
      switch (field) {
        case 'newPassword':
          this.showNewPassword = !this.showNewPassword;
          break;
        case 'confirmNewPassword':
          this.showConfirmNewPassword = !this.showConfirmNewPassword;
          break;
        default:
          break;
      }
    },
    changePassword() {
      const { new_password, confirm_new_password } = this.profile;
      // Check if either password field is empty
      if (!new_password || !confirm_new_password) {
        this.$toast.open({
          message:
            'Both New Password and Confirm New Password fields are required!',
          type: 'error',
          duration: 1000 * 5,
          dismissible: true,
        });
        return;
      }
      // Check if passwords do not match
      if (new_password !== confirm_new_password) {
        this.$toast.open({
          message: 'New Password and Confirm New Password do not match!',
          type: 'error',
          duration: 1000 * 5,
          dismissible: true,
        });
        return;
      }

      const passwordData = {
        password: new_password,
      };

      this.loading = true;
      this.$store
        .dispatch('profile_setting_store/change_password', { passwordData })
        .then(() => {
          this.$toast.open({
            message: 'Password Changed Successfully!',
            type: 'success',
            duration: 1000 * 5,
            dismissible: true,
          });
          this.profile.new_password = '';
          this.profile.confirm_new_password = '';
          this.loading = false;
        })
        .catch((error) => {
          this.$toast.open({
            message: error.message || 'Error changing password',
            type: 'error',
            duration: 1000 * 5,
            dismissible: true,
          });
          this.loading = false;
        });
    },
  },
  created() {
    this.fetchProfile();
  },
};
</script>

<style>
@import '../../css/icomoon.css';
@import '../../css/style.css';
@import '../../css/discovery.css';

.recently-viewed-slider .slick-track {
  display: flex;
  gap: 3rem;
}
</style>
