

<template>
    <div class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div class="bg-white rounded-lg p-10 max-w-md mx-4">
            <div class="text-center">
                <div class="mb-4">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-16 text-yellow-500 mx-auto" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                    </svg>
                </div>
                <h3 class="text-xl font-semibold text-gray-900 mb-2">Feature Under Development</h3>
                <p class="text-gray-600 mb-4">
                    This section is currently under development. We are working to enhance your experience. Please check
                    back soon for exciting updates!
                </p>
                <button @click="$emit('close')"
                    class="bg-blue hover:bg-blue-600 my-6 text-white font-medium py-2 px-4 rounded-lg transition duration-200">
                    Return to Home
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ComingSoon'
}
</script>

// Now, let's modify the ChatScreen component to include the popup logic