<template>
  <li
    :class="{ 'cursor-pointer hover:scale-105 transition-transform duration-300': stats_data.is_active }"
    @click="handleBadgeClick"
  class="flex  rounded-2xl border-1 border-borderColor overflow-hidden flex-col">
    <div
      class="flex justify-center items-center bg-white px-2 py-6 "
    >
      <img
      class="w-32 mx-auto"
        :src="stats_data.image"
        alt="Bookworm Badge"
        :class="{ 'filter grayscale': !stats_data.is_active }"
      />
    </div>
    <div
      class="bg-badgeTitleBG h-full px-2 py-4 text-center  "
    >
      <h3
        class="text-lg  font-bold"
        :class="{
          'text-blue': stats_data.is_active,
          'text-inactiveBadgeTitle': !stats_data.is_active,
        }"
      >
        {{ stats_data.name }}
      </h3>
      <p class="text-sm">{{ stats_data.description }}</p>
    </div>
  </li>
</template>

<script>
export default {
  name: 'BadgeStatsComponent',
  props: {
    stats_data: {
      type: Object,
      required: false,
    },
  },
  methods: {
    handleBadgeClick() {
      // Only emit click event if badge is active
      if (this.stats_data.is_active) {
        this.$emit('click', this.stats_data);
      }
    }
  }
};
</script>

<style></style>

<!--                 <div class="mt-6">
                    <ul class="grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 w-full">
                        <li class="flex flex-col px-2 py-4">
                            <div
                                class="flex justify-center items-center bg-white px-2 py-6 rounded-tl-3xl rounded-tr-3xl">
                                <img src="@/images/badges/bookworm.png" alt="Bookworm Badge" />
                            </div>
                            <div class="bg-badgeTitleBG px-2 py-4 text-center rounded-bl-3xl rounded-br-3xl">
                                <h3 class="text-2xl text-blue font-extrabold">
                                    Bookworm Badge
                                </h3>
                                <p class="text-base">10 Books accessed/read/listing to</p>
                            </div>
                        </li>
                        <li class="flex flex-col px-2 py-4">
                            <div
                                class="flex justify-center items-center bg-white px-2 py-6 rounded-tl-3xl rounded-tr-3xl">
                                <img src="@/images/badges/music-maestro.png" alt="Music Maestro Badge" />
                            </div>
                            <div class="bg-badgeTitleBG px-2 py-4 text-center rounded-bl-3xl rounded-br-3xl">
                                <h3 class="text-2xl text-blue font-extrabold">
                                    Music Maestro Badge
                                </h3>
                                <p class="text-base">Song accessed/listened to.</p>
                            </div>
                        </li>
                        <li class="flex flex-col bg-badgeBG px-2 py-4">
                            <div
                                class="flex justify-center items-center bg-white px-2 py-6 rounded-tl-3xl rounded-tr-3xl">
                                <img src="@/images/badges/cinephile.png" alt="Cinephile Badge" class="grayscale" />
                            </div>
                            <div class="bg-badgeTitleBG px-2 py-4 text-center rounded-bl-3xl rounded-br-3xl">
                                <h3 class="text-2xl text-inactiveBadgeTitle font-extrabold">
                                    Cinephile Badge
                                </h3>
                                <p class="text-base">5 Films/Movies accessed/watched</p>
                            </div>
                        </li>
                        <li class="flex flex-col bg-badgeBG px-2 py-4">
                            <div
                                class="flex justify-center items-center bg-white px-2 py-6 rounded-tl-3xl rounded-tr-3xl">
                                <img src="@/images/badges/podcast-explorer.png" alt="Podcast Explorer Badge"
                                    class="grayscale" />
                            </div>
                            <div class="bg-badgeTitleBG px-2 py-4 text-center rounded-bl-3xl rounded-br-3xl">
                                <h3 class="text-2xl text-inactiveBadgeTitle font-extrabold">
                                    Podcast Explorer Badge
                                </h3>
                                <p class="text-base">10 Podcasts accessed/listened to.</p>
                            </div>
                        </li>
                        <li class="flex flex-col bg-badgeBG px-2 py-4">
                            <div
                                class="flex justify-center items-center bg-white px-2 py-6 rounded-tl-3xl rounded-tr-3xl">
                                <img src="@/images/badges/gamer-guru.png" alt="Gamer Guru Badge" class="grayscale" />
                            </div>
                            <div class="bg-badgeTitleBG px-2 py-4 text-center rounded-bl-3xl rounded-br-3xl">
                                <h3 class="text-2xl text-inactiveBadgeTitle font-extrabold">
                                    Gamer Guru Badge
                                </h3>
                                <p class="text-base">10 Games accessed/played</p>
                            </div>
                        </li>
                        <li class="flex flex-col bg-badgeBG px-2 py-4">
                            <div
                                class="flex justify-center items-center bg-white px-2 py-6 rounded-tl-3xl rounded-tr-3xl">
                                <img src="@/images/badges/art-aficionado.png" alt="Art Aficionado Badge"
                                    class="grayscale" />
                            </div>
                            <div class="bg-badgeTitleBG px-2 py-4 text-center rounded-bl-3xl rounded-br-3xl">
                                <h3 class="text-2xl text-inactiveBadgeTitle font-extrabold">
                                    Art Aficionado Badge
                                </h3>
                                <p class="text-base">10 Artifacts seen.</p>
                            </div>
                        </li>
                        <li class="flex flex-col bg-badgeBG px-2 py-4">
                            <div
                                class="flex justify-center items-center bg-white px-2 py-6 rounded-tl-3xl rounded-tr-3xl">
                                <img src="@/images/badges/theater-buff.png" alt="Theater Buff Badge" class="grayscale" />
                            </div>
                            <div class="bg-badgeTitleBG px-2 py-4 text-center rounded-bl-3xl rounded-br-3xl">
                                <h3 class="text-2xl text-inactiveBadgeTitle font-extrabold">
                                    Theater Buff Badge
                                </h3>
                                <p class="text-base">Threatrical productions attended/seen.</p>
                            </div>
                        </li>
                        <li class="flex flex-col bg-badgeBG px-2 py-4">
                            <div
                                class="flex justify-center items-center bg-white px-2 py-6 rounded-tl-3xl rounded-tr-3xl">
                                <img src="@/images/badges/melomaniac.png" alt="Melomaniac Badge" class="grayscale" />
                            </div>
                            <div class="bg-badgeTitleBG px-2 py-4 text-center rounded-bl-3xl rounded-br-3xl">
                                <h3 class="text-2xl text-inactiveBadgeTitle font-extrabold">
                                    Melomaniac Badge
                                </h3>
                                <p class="text-base">10 Musical pieces accessed/listened to</p>
                            </div>
                        </li>
                        <li class="flex flex-col bg-badgeBG px-2 py-4">
                            <div
                                class="flex justify-center items-center bg-white px-2 py-6 rounded-tl-3xl rounded-tr-3xl">
                                <img src="@/images/badges/couch-potato.png" alt="Couch Potato Badge" class="grayscale" />
                            </div>
                            <div class="bg-badgeTitleBG px-2 py-4 text-center rounded-bl-3xl rounded-br-3xl">
                                <h3 class="text-2xl text-inactiveBadgeTitle font-extrabold">
                                    Couch Potato Badge
                                </h3>
                                <p class="text-base">10 TV programs accessed/watched</p>
                            </div>
                        </li>
                        <li class="flex flex-col bg-badgeBG px-2 py-4">
                            <div
                                class="flex justify-center items-center bg-white px-2 py-6 rounded-tl-3xl rounded-tr-3xl">
                                <img src="@/images/badges/data-diver.png" alt="Data Diver Badge" class="grayscale" />
                            </div>
                            <div class="bg-badgeTitleBG px-2 py-4 text-center rounded-bl-3xl rounded-br-3xl">
                                <h3 class="text-2xl text-inactiveBadgeTitle font-extrabold">
                                    Data Diver Badge
                                </h3>
                                <p class="text-base">1 Data page accessed/viewed</p>
                            </div>
                        </li>
                        <li class="flex flex-col bg-badgeBG px-2 py-4">
                            <div
                                class="flex justify-center items-center bg-white px-2 py-6 rounded-tl-3xl rounded-tr-3xl">
                                <img src="@/images/badges/data-creator.png" alt="Data Creator Badge" class="grayscale" />
                            </div>
                            <div class="bg-badgeTitleBG px-2 py-4 text-center rounded-bl-3xl rounded-br-3xl">
                                <h3 class="text-2xl text-inactiveBadgeTitle font-extrabold">
                                    Data Creator Badge
                                </h3>
                                <p class="text-base">5 Data pages created</p>
                            </div>
                        </li>
                        <li class="flex flex-col bg-badgeBG px-2 py-4">
                            <div
                                class="flex justify-center items-center bg-white px-2 py-6 rounded-tl-3xl rounded-tr-3xl">
                                <img src="@/images/badges/character-detective.png" alt="Character Detective Badge"
                                    class="grayscale" />
                            </div>
                            <div class="bg-badgeTitleBG px-2 py-4 text-center rounded-bl-3xl rounded-br-3xl">
                                <h3 class="text-2xl text-inactiveBadgeTitle font-extrabold">
                                    Character Detective Badge
                                </h3>
                                <p class="text-base">20 People/Characters identified.</p>
                            </div>
                        </li>
                        <li class="flex flex-col bg-badgeBG px-2 py-4">
                            <div
                                class="flex justify-center items-center bg-white px-2 py-6 rounded-tl-3xl rounded-tr-3xl">
                                <img src="@/images/badges/globe-trotter.png" alt="Globe Trotter Badge"
                                    class="grayscale" />
                            </div>
                            <div class="bg-badgeTitleBG px-2 py-4 text-center rounded-bl-3xl rounded-br-3xl">
                                <h3 class="text-2xl text-inactiveBadgeTitle font-extrabold">
                                    Globe Trotter Badge
                                </h3>
                                <p class="text-base">20 Places identified.</p>
                            </div>
                        </li>
                        <li class="flex flex-col bg-badgeBG px-2 py-4">
                            <div
                                class="flex justify-center items-center bg-white px-2 py-6 rounded-tl-3xl rounded-tr-3xl">
                                <img src="@/images/badges/item-tracker.png" alt="Item Tracker Badge" class="grayscale" />
                            </div>
                            <div class="bg-badgeTitleBG px-2 py-4 text-center rounded-bl-3xl rounded-br-3xl">
                                <h3 class="text-2xl text-inactiveBadgeTitle font-extrabold">
                                    Item Tracker Badge
                                </h3>
                                <p class="text-base">20 Things/Items identified.</p>
                            </div>
                        </li>
                        <li class="flex flex-col bg-badgeBG px-2 py-4">
                            <div
                                class="flex justify-center items-center bg-white px-2 py-6 rounded-tl-3xl rounded-tr-3xl">
                                <img src="@/images/badges/topic-navigator.png" alt="Topic Navigator Badge"
                                    class="grayscale" />
                            </div>
                            <div class="bg-badgeTitleBG px-2 py-4 text-center rounded-bl-3xl rounded-br-3xl">
                                <h3 class="text-2xl text-inactiveBadgeTitle font-extrabold">
                                    Topic Navigator Badge
                                </h3>
                                <p class="text-base">5 Topics identified.</p>
                            </div>
                        </li>
                        <li class="flex flex-col bg-badgeBG px-2 py-4">
                            <div
                                class="flex justify-center items-center bg-white px-2 py-6 rounded-tl-3xl rounded-tr-3xl">
                                <img src="@/images/badges/theme-spotter.png" alt="Theme Spotter Badge"
                                    class="grayscale" />
                            </div>
                            <div class="bg-badgeTitleBG px-2 py-4 text-center rounded-bl-3xl rounded-br-3xl">
                                <h3 class="text-2xl text-inactiveBadgeTitle font-extrabold">
                                    Theme Spotter Badge
                                </h3>
                                <p class="text-base">5 Themes identified.</p>
                            </div>
                        </li>
                        <li class="flex flex-col bg-badgeBG px-2 py-4">
                            <div
                                class="flex justify-center items-center bg-white px-2 py-6 rounded-tl-3xl rounded-tr-3xl">
                                <img src="@/images/badges/time-traveler.png" alt="Time Traveler Badge"
                                    class="grayscale" />
                            </div>
                            <div class="bg-badgeTitleBG px-2 py-4 text-center rounded-bl-3xl rounded-br-3xl">
                                <h3 class="text-2xl text-inactiveBadgeTitle font-extrabold">
                                    Time Traveler Badge
                                </h3>
                                <p class="text-base">10 Time Periods identified.</p>
                            </div>
                        </li>
                        <li class="flex flex-col bg-badgeBG px-2 py-4">
                            <div
                                class="flex justify-center items-center bg-white px-2 py-6 rounded-tl-3xl rounded-tr-3xl">
                                <img src="@/images/badges/genre-guru.png" alt="Genre Guru Badge" class="grayscale" />
                            </div>
                            <div class="bg-badgeTitleBG px-2 py-4 text-center rounded-bl-3xl rounded-br-3xl">
                                <h3 class="text-2xl text-inactiveBadgeTitle font-extrabold">
                                    Genre Guru Badge
                                </h3>
                                <p class="text-base">10 Genres identified.</p>
                            </div>
                        </li>
                        <li class="flex flex-col bg-badgeBG px-2 py-4">
                            <div
                                class="flex justify-center items-center bg-white px-2 py-6 rounded-tl-3xl rounded-tr-3xl">
                                <img src="@/images/badges/median-maven.png" alt="Media Maven Badge" class="grayscale" />
                            </div>
                            <div class="bg-badgeTitleBG px-2 py-4 text-center rounded-bl-3xl rounded-br-3xl">
                                <h3 class="text-2xl text-inactiveBadgeTitle font-extrabold">
                                    Media Maven Badge
                                </h3>
                                <p class="text-base">10 Related Media identified.</p>
                            </div>
                        </li>
                        <li class="flex flex-col bg-badgeBG px-2 py-4">
                            <div
                                class="flex justify-center items-center bg-white px-2 py-6 rounded-tl-3xl rounded-tr-3xl">
                                <img src="@/images/badges/activity-ace.png" alt="Activity Ace Badge" class="grayscale" />
                            </div>
                            <div class="bg-badgeTitleBG px-2 py-4 text-center rounded-bl-3xl rounded-br-3xl">
                                <h3 class="text-2xl text-inactiveBadgeTitle font-extrabold">
                                    Activity Ace Badge
                                </h3>
                                <p class="text-base">5 Activities identified.</p>
                            </div>
                        </li>
                        <li class="flex flex-col bg-badgeBG px-2 py-4">
                            <div
                                class="flex justify-center items-center bg-white px-2 py-6 rounded-tl-3xl rounded-tr-3xl">
                                <img src="@/images/badges/median-magician.png" alt="Media Magician Badge"
                                    class="grayscale" />
                            </div>
                            <div class="bg-badgeTitleBG px-2 py-4 text-center rounded-bl-3xl rounded-br-3xl">
                                <h3 class="text-2xl text-inactiveBadgeTitle font-extrabold">
                                    Media Magician Badge
                                </h3>
                                <p class="text-base">
                                    5 Related Media identified for activities.
                                </p>
                            </div>
                        </li>
                        <li class="flex flex-col bg-badgeBG px-2 py-4">
                            <div
                                class="flex justify-center items-center bg-white px-2 py-6 rounded-tl-3xl rounded-tr-3xl">
                                <img src="@/images/badges/shopaholic.png" alt="Shopaholic Badge" class="grayscale" />
                            </div>
                            <div class="bg-badgeTitleBG px-2 py-4 text-center rounded-bl-3xl rounded-br-3xl">
                                <h3 class="text-2xl text-inactiveBadgeTitle font-extrabold">
                                    Shopaholic Badge
                                </h3>
                                <p class="text-base">2 Purchase opportunities identified.</p>
                            </div>
                        </li>
                        <li class="flex flex-col bg-badgeBG px-2 py-4">
                            <div
                                class="flex justify-center items-center bg-white px-2 py-6 rounded-tl-3xl rounded-tr-3xl">
                                <img src="@/images/badges/career-connoisseur.png" alt="Career Connoisseur Badge"
                                    class="grayscale" />
                            </div>
                            <div class="bg-badgeTitleBG px-2 py-4 text-center rounded-bl-3xl rounded-br-3xl">
                                <h3 class="text-2xl text-inactiveBadgeTitle font-extrabold">
                                    Career Connoisseur Badge
                                </h3>
                                <p class="text-base">5 Careers identified.</p>
                            </div>
                        </li>
                    </ul>
                </div> -->
