<template>
  <div v-if="entity_detail_result" class="min-[900px]:flex xl:gap-5 justify-between ">
    <div class="flex flex-col  w-full mb-8"> 
      <h2 class="min-[1200px]:text-5xl sm:text-3xl text-2xl md:text-4xl text-blue  font-bold">
        {{ entity_detail_result.result.name }}
        <template v-if="
          entity_detail_result && entity_detail_result.result.google_map_link
        ">
          <a :href="entity_detail_result.result.google_map_link" target="_blank" class="inline-flex" @click.stop="">
            <svg height="15%" width="15%" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 293.334 293.334" xml:space="preserve"
              fill="#3048c1">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <g>
                  <g>
                    <path style="fill: #3048c1"
                      d="M146.667,0C94.903,0,52.946,41.957,52.946,93.721c0,22.322,7.849,42.789,20.891,58.878 c4.204,5.178,11.237,13.331,14.903,18.906c21.109,32.069,48.19,78.643,56.082,116.864c1.354,6.527,2.986,6.641,4.743,0.212 c5.629-20.609,20.228-65.639,50.377-112.757c3.595-5.619,10.884-13.483,15.409-18.379c6.554-7.098,12.009-15.224,16.154-24.084 c5.651-12.086,8.882-25.466,8.882-39.629C240.387,41.962,198.43,0,146.667,0z M146.667,144.358 c-28.892,0-52.313-23.421-52.313-52.313c0-28.887,23.421-52.307,52.313-52.307s52.313,23.421,52.313,52.307 C198.98,120.938,175.559,144.358,146.667,144.358z">
                    </path>
                    <circle style="fill: #3048c1" cx="146.667" cy="90.196" r="21.756"></circle>
                  </g>
                </g>
              </g>
            </svg>
          </a>
        </template>
      </h2>
      <p class="lg:text-[28px] sm:text-xl text-lg text-gray-500 font-semibold pt-4">
        {{ entity_detail_result.result.entity_display }}
      </p>
      <template v-if="
        'groups' in entity_detail_result?.result &&
        entity_detail_result.result.groups.length > 0
      ">
        <div class="text-md xs:text-lg sm:text-xl flex  font-semibold text-black pt-4">
          <span class="mr-2">{{
            groupName[entity_detail_result.result.entity] || 'GROUPS'
          }}:&nbsp;</span>
          <div class="flex items-center flex-wrap gap-2">
            <span v-for="(group, index) in entity_detail_result.result.groups" :key="index"
              class="text-blue border-1 border-blue text-xs sm:text-sm bg-white font-semibold px-2 sm:px-4 xs:py-1 rounded-full">
              {{ group }}
            </span>
          </div>
        </div>
      </template>
      <template v-if="'o_type' in entity_detail_result.result">
        <div class="flex   pt-4">
          <div
            class="flex font-semibold justify-center items-center border-1 border-blue rounded-full  px-3 py-1 mr-3 max-[376px]:mb-3 ">
            <span class="sm:text-xl" :class="entity_detail_result.result.o_type === 'real'
              ? 'text-blue ml-1'
              : 'text-black opacity-20'
              ">Real</span>
            <span class="sm:text-xl text-black opacity-20">&nbsp;|&nbsp;</span>
            <span class="sm:text-xl" :class="entity_detail_result.result.o_type === 'fiction'
              ? 'text-blue ml-1'
              : 'text-black opacity-20'
              ">Fiction</span>
          </div>
        </div>
      </template>
      <p
        class="sm:text-xl text-lg text-black opacity-60 mb-8 mr-8 max-[767px]:mr-0 max-[767px]:pr-5 font-medium pt-6">
        {{
          entity_detail_result.result.description ||
          entity_detail_result.result.summary
        }}
      </p>

      <template v-if="
        entity_detail_result &&
        'places' in entity_detail_result.result &&
        entity_detail_result.result.places.length > 0
      ">
        <div class="text-black my-2 flex">
          <span class="text-md xs:text-lg sm:text-xl font-semibold mr-2 mt-1">Location:</span>
          <div class="flex flex-wrap gap-2">
            <template v-for="(place, index) in entity_detail_result.result.places" :key="index">
              <div class="flex justify-center items-center border-1 border-blue rounded-full px-3 py-1">
                <span class="text-md xs:text-lg sm:text-xl text-blue ml-1">{{ place }}</span>
              </div>
            </template>
          </div>
        </div>
      </template>
      <template v-if="
        entity_detail_result &&
        'no_of_participants' in entity_detail_result.result &&
        entity_detail_result.result.no_of_participants
      ">
        <p class="text-black my-2">
          <span class="text-md xs:text-lg sm:text-xl font-semibold">Minimum Participants:&nbsp;</span>
          <span class="text-md xs:text-lg sm:text-xl opacity-40">{{
            entity_detail_result.result.no_of_participants
          }}</span>
        </p>
      </template>
      <template v-if="
        entity_detail_result &&
        'traits' in entity_detail_result.result &&
        entity_detail_result.result.traits.length > 0
      ">
        <h4 class="sm:text-xl text-lg font-bold">Character Traits:</h4>
        <div class="flex flex-wrap my-5">
          <template v-for="(trait, index) in entity_detail_result.result.traits" :key="index">
            <div
              class="flex justify-center items-center bg-white text-blue border-1 border-blue font-semibold xs:text-sm text-xs sm:text-md rounded-full px-3 py-1 mr-3 mb-2">
              <span class=" text-blue ml-1">{{ trait }}</span>
            </div>
          </template>
        </div>
      </template>
      <!-- <template
        v-if="
          entity_detail_result && entity_detail_result.result.google_map_link
        "
      >
        <a
          :href="entity_detail_result.result.google_map_link"
          target="_blank"
          class="py-2"
        >
          <button
            class="text-sm text-white bg-blue font-semibold py-1 px-5 mt-1 mb-10 text-center rounded-full hover:text-blue hover:bg-white duration-500 border-1 hover:border-blue hover:border-1"
          >
            Open In Map
          </button>
        </a>
      </template> -->
    </div>

    <div class="flex  min-[1200px]:w-96  min-[380px]:w-80 mx-auto  flex-col">
      <div class="overflow-hidden mx-auto w-60 h-64  rounded-2xl border-borderColor border-1">
        <img 
          :src="entity_detail_result.result.image" 
          :alt="entity_detail_result.result.name" 
          class="w-full h-full" 
        />
      </div>
      <div class=" my-4">
        <div class="mb-3 flex justify-center">
          <div>
            <MediaLikeComponent :identify_media_data="entity_detail_result.result" />
            <span class="text-xl mx-3">Like</span>
          </div>
        </div>
        <div class="flex justify-center">
          <template v-if="
            'additional_resources' in entity_detail_result?.result &&
            entity_detail_result.result.additional_resources.length > 0
          ">
            <button
              class="text-xs sm:text-sm text-white bg-blue font-medium py-2 px-3 mr-2 text-center rounded-full border-1 hover:text-blue hover:bg-white duration-500 hover:border-blue hover:border-1"
              @click.stop="
                additionalSources($event, entity_detail_result.result.id)
                ">
              {{ showAdditionalSources ? 'Hide' : 'View' }} Additional Sources
            </button>
          </template>
        </div>
      </div>
      <ul class="flex justify-center flex-wrap gap-5 sm:text-center max-[640px]:text-center  hidden"
        :id="'additionalSources-' + entity_detail_result.result.id">
        <template v-for="(add_src, index) in entity_detail_result.result
          .additional_resources" :key="index">
          <li>
            <span class="text-black bg-white text-xs px-2 py-1 rounded-full text-blue mb-2 underline">
              <a v-if="add_src.link" :href="add_src.link" target="_blank">
                {{ add_src.description }}
              </a>
            </span>
          </li>
        </template>
      </ul>
    </div>
  </div>
  <template v-if="
    entity_detail_result && entity_detail_result.result.google_embed_iframe
  ">
    <div class="border-b-1 border-borderColor my-12"></div>
    <div v-html="entity_detail_result.result.google_embed_iframe" class="iframe-wrapper"></div>
  </template>
</template>

<script>
import MediaLikeComponent from '@/common_components/media_fav_icon/media_like_component.vue';
import { mapState } from 'vuex';
export default {
  name: 'DetailComponent',

  components: {
    MediaLikeComponent,
  },

  computed: {
    ...mapState({
      entity_detail_result: (state) =>
        state.specific_data_store.entity_detail_result,
    }),
  },

  data() {
    return {
      showAdditionalSources: false,
      groupName: {
        item: 'TYPE',
        character: 'ROLES',
        place: 'LOCATIONS',
      },
    };
  },
  methods: {
    additionalSources(e, id) {
      const div_id = 'additionalSources-' + id;
      this.showAdditionalSources = !this.showAdditionalSources;
      document.querySelector('#' + div_id).classList.toggle('hidden');
    },
  },
};
</script>

<style>
.iframe-container {
  width: 100%;
  /* Ensures the iframe container takes full width of its parent */
  max-width: 100%;
  /* Optionally restricts maximum width if needed */
  margin: 0 auto;
  /* Centers the container horizontally */
}

.iframe-wrapper {
  position: relative;
  /* Ensures iframe is positioned relative to its wrapper */
  width: 100%;
  /* Makes iframe take full width of its container */
  height: 0;
  /* Ensures aspect ratio is maintained */
  padding-bottom: 56.25%;
  /* 16:9 aspect ratio (modify as needed) */
  overflow: hidden;
  /* Prevents iframe overflow */
}

.iframe-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* Ensures iframe takes full width of its wrapper */
  height: 100%;
  /* Ensures iframe takes full height of its wrapper */
  border: none;
  /* Removes border */
}
</style>
