<template>
    <div v-if="isVisible" class="absolute inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
        <div class="bg-white rounded-xl w-full max-w-md shadow-lg">
            <div class="p-6">
                <div>
                    <div class="flex mb-4 justify-between items-center">
                        <h2 class="text-xl font-semibold">Create a Event</h2>
                        <button @click="$emit('close')" class=" text-gray-500 hover:text-gray-700">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round">
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                        </button>
                    </div>
                    <p class="text-gray-500 text-sm mb-5">Create a new challenge for allies by setting a goal,
                        defining tasks, and assigning roles."</p>
                    <div>

                    </div>
                </div>
                <form @submit.prevent="createEvent">
                    <div class="mb-4">
                        <div class="w-40 h-40 border-2 border-gray-300 rounded-lg flex items-center justify-center cursor-pointer hover:border-blue-500"
                            @click="triggerFileUpload">
                            <input type="file" ref="fileInput" class="hidden" @change="handleFileUpload"
                                accept="image/*" />
                            <div v-if="!selectedImage" class="text-center text-gray-500">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 mx-auto mb-2" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                        d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                </svg>
                                Upload Image
                            </div>
                            <img v-else :src="selectedImage" class="w-full h-full object-cover rounded-lg" />
                        </div>
                    </div>

                    <div class="space-y-4">
                        <input type="text" v-model="eventForm.name" placeholder="Name"
                            class="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500" />

                        <textarea v-model="eventForm.description" placeholder="Description"
                            class="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                            rows="3"></textarea>

                        <input type="text" v-model="eventForm.location" placeholder="Location"
                            class="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500" />

                        <input type="text" v-model="eventForm.url" placeholder="URL (Optional)"
                            class="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500" />

                        <div class="grid grid-cols-3 gap-2 ">
                            <div class="">
                                <input type="date" v-model="eventForm.startDate"
                                    class="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500" />
                            </div>
                            <div class="">
                                <input type="date" v-model="eventForm.endDate"
                                    class="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500" />
                            </div>
                            <div class="">
                                <input type="time" v-model="eventForm.startTime"
                                    class="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500" />
                            </div>
                        </div>
                    </div>

                    <button type="submit"
                        class="w-full mt-6 bg-blue font-semibold text-white py-3 rounded-full hover:bg-blue-700 transition duration-300">
                        Create Event
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CreateEventPopup',
    props: {
        isVisible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            eventForm: {
                name: '',
                description: '',
                location: '',
                url: '',
                startDate: '',
                endDate: '',
                startTime: '',
                endTime: '',
                image: null
            },
            selectedImage: null
        }
    },
    methods: {
        triggerFileUpload() {
            this.$refs.fileInput.click();
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.selectedImage = e.target.result;
                    this.eventForm.image = file;
                }
                reader.readAsDataURL(file);
            }
        },
        createEvent() {
            // Validation
            if (!this.eventForm.name) {
                alert('Please enter event name');
                return;
            }

            // Here you would typically send the event data to a backend
            console.log('Event created:', this.eventForm);

            // Reset form and close popup
            this.resetForm();
            this.$emit('close');
        },
        resetForm() {
            this.eventForm = {
                name: '',
                description: '',
                location: '',
                url: '',
                startDate: '',
                endDate: '',
                startTime: '',
                endTime: '',
                image: null
            };
            this.selectedImage = null;
        }
    }
}
</script>