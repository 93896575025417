<template>
  <a
    @click.prevent="handleClick"
    :class="[
      'xs:text-sm text-xs sm:text-lg md:text-xl border-1 rounded-xl sm:rounded-2xl flex mr-1 sm:mr-3 md:mr-4 px-4 py-2 items-center justify-center whitespace-nowrap cursor-pointer',
      isSelected ? 'text-white bg-blue' : 'border-blue text-blue bg-white',
    ]"  
  >
    {{ formattedLabel }}
  </a>
</template>
<script>
export default {
  props: {
    item_key: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    selected_category: {
      type: String,
      default: null,
    },
    
  },
  computed: {
    isSelected() {
      return this.selected_category === this.item_key;
    },
    formattedLabel() {
      return `${this.label} (${this.count})`;
    },
  },
  methods: {
    handleClick() {
      this.$emit('select', this.item_key);
    },
  },
};
</script>

<style scoped>
.bg-blue {
  background-color: #242fb3;
}

.text-blue {
  color: #242fb3;
}

.border-blue {
  border-color: #242fb3;
}
</style>
