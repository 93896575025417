<template>
  <li
    class="flex justify-between mb-5 bg-gray-100 px-7 py-5 rounded-2xl max-[576px]:flex-col"
  >
    <div class="flex flex-col max-[576px]:items-center">
      <p class="text-sm">
        {{ data.quote }}
        <span class="text-sm" style="font-style: italic"
          >- {{ data.location }}</span
        >
      </p>
      <p class="flex items-center text-sm font-bold">
        - {{ data.said_by }}
        <img
          src="../../../images/fillstar.png"
          alt="Filled Star"
          class="ml-3"
        />
      </p>
    </div>
    <!-- <img
      src="../../../images/albus.png"
      alt="Albus Dumbledore"
      class="w-8 h-8 rounded-full max-[576px]:order-first max-[576px]:mx-auto max-[576px]:mb-4"
    /> -->
  </li>
</template>

<script>
export default {
  name: 'QuoteComponent',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
