import { createWebHistory, createRouter } from 'vue-router';
import CreateAccount from '@/controller/create_account/create_account_controller.vue';
import LoginAccount from '@/controller/login_account/login_controller.vue';
import MediaData from '@/controller/media_data/media_data_controller.vue';
import HomeScreen from '@/controller/home/home_controller.vue';
import SatisfyCuriosity from '@/controller/satisfy_curiosity/satisfy_curiosity_controller.vue';
import SearchSatisfy from '@/controller/search_satisfy/search_satisfy_controller.vue';
import ShowoffKnowledge from '@/controller/showoff_knowledge/showoff_knowlwdge_controller.vue';
import ShowoffInitialResult from '@/controller/showoff_initial_result/showoff_initial_result_controller.vue';
import HomeLogout from '@/controller/home_logout/home_logout_controller.vue';
import SearchResult from '@/controller/search_result/search_result_controller.vue';
import ProfileSetting from '@/controller/profile_setting/profile_setting_controller.vue';
import SpecificData from '@/controller/specific_data/specific_data_controller.vue';
import CreateNewMedia from '@/controller/create_new_media/create_new_media_controller.vue';
import HomeSearch from '@/controller/home_search/home_search_controller.vue';
import ReviewQuote from '@/controller/review_quote/review_quote_controller.vue';
import AddQuote from '@/controller/add_quotes/add_quote_controller.vue';
import AddMissingInfo from '@/controller/add_missing_info/add_missing_info_controller.vue';
import SeeMoreEntity from '@/controller/see_all_media/see_more_media_controller.vue';
import StatsScreen from '@/controller/stats/stats_controller.vue';
import JournalScreen from '@/controller/journal/journal_controller.vue';
import XPscreen from '@/controller/xp_page/xp_history_controller.vue';
import NotFoundPage from '@/controller/not_found_page/not_found.vue';
import ChatScreen from '@/controller/chat_section/chat_controller.vue';
import AlliesView from '@/controller/chat_section/components/my_allies_component.vue';
import SingleChatScreen from '@/controller/chat_section/single_chat_section/single_chat_component.vue';
import GroupChatScreen from '@/controller/chat_section/groups_chat_section/group_chat_component.vue';
import EarnPoints from '@/controller/chat_section/earn_ponits_section/earn_points_component.vue';
import ManagerEvents from '@/controller/chat_section/manage_events_section/manage_event_component.vue';
import TaskManagemnet from '@/controller/chat_section/task_managemnet_section/task_management.vue';
import TaskDetail from '@/controller/chat_section/task_managemnet_section/component/task_detail_component.vue';
import AllMedia from '@/controller/all_media/all_media_controller.vue';

const routes = [
  {
    name: 'home-logout',
    path: '/',
    component: HomeLogout,
  },
  {
    name: 'login-account',
    path: '/login-account',
    component: LoginAccount,
  },
  {
    name: 'create-account',
    path: '/create-account',
    component: CreateAccount,
  },
  {
    path: '/media-data/:id',
    name: 'media-data',
    component: MediaData,
  },
  {
    name: 'home',
    path: '/home',
    component: HomeScreen,
    meta: { requiresAuth: true },
  },
  {
    name: 'satisfy-curiosity',
    path: '/satisfy-curiosity',
    component: SatisfyCuriosity,
    meta: { requiresAuth: false },
  },
  {
    name: 'search-satisfy',
    path: '/search-satisfy/:search_topic/:search_interest?',
    component: SearchSatisfy,
    meta: { requiresAuth: true },
  },
  {
    name: 'showoff-knowledge',
    path: '/showoff-knowledge',
    component: ShowoffKnowledge,
    meta: { requiresAuth: false },
  },
  {
    name: 'showoff-initial-result',
    path: '/showoff-initial-result/:find_by/:media_type/:q',
    component: ShowoffInitialResult,
    meta: { requiresAuth: true },
  },
  {
    name: 'search-result',
    path: '/search-result',
    component: SearchResult,
    meta: { requiresAuth: false },
  },
  {
    path: '/all-media/:entity_type',
    name: 'all_media',
    component: AllMedia, 
    meta: {
      requiresAuth: true 
    }
  },
  {
    name: 'profile-setting',
    path: '/profile-setting',
    component: ProfileSetting,
    meta: { requiresAuth: true },
  },
  {
    name: 'specific-data',
    path: '/specific-data',
    component: SpecificData,
    meta: { requiresAuth: true },
  },
  {
    name: 'create-new-media',
    path: '/create-new-media',
    component: CreateNewMedia,
    meta: { requiresAuth: true },
  },
  {
    name: 'home-search',
    path: '/home-search/:search_topic/:search_interest?',
    component: HomeSearch,
    meta: { requiresAuth: false },
  },
  {
    name: 'explore',
    path: '/explore',
    component: HomeSearch,
    meta: { requiresAuth: false },
  },
  {
    path: '/review-quote/:id/:media_name',
    name: 'review-quote',
    component: ReviewQuote,
  },
  {
    path: '/add-quote/:id/:media_name',
    name: 'add-quote',
    component: AddQuote,
  },
  {
    path: '/add-missing-info/:id',
    name: 'add-missing-info',
    component: AddMissingInfo,
  },
  {
    path: '/see_more_entity/:id/:type/:media_name/:media_type',
    name: 'see_more_entity',
    component: SeeMoreEntity,
  },
  {
    name: 'stats',
    path: '/stats',
    component: StatsScreen,
  },
  {
    name: 'xp_page',
    path: '/xp_page',
    component: XPscreen,
  },
  {
    name: 'chat_page',
    path: '/chat',
    component: ChatScreen,
    children: [
      {
        name: 'allies_view',
        path: 'allies',
        component: AlliesView,
      },
      {
        name: 'my_groups',
        path: 'groups',
        component: GroupChatScreen,
      },
      {
        name: 'manage_events',
        path: 'manage_events',
        component: ManagerEvents,
      },
      {
        name: 'task_manage',
        path: 'task_manage',
        component: TaskManagemnet,
      },
      {
        name: 'task_detail',
        path: 'task_manage/task_detail',
        component: TaskDetail,
      },
      {
        name: 'single_chat',
        path: 'single_chat',
        component: SingleChatScreen,
      },
      {
        name: 'earn_points',
        path: 'earn_points',
        component: EarnPoints,
      },
  
    ]
  },
  {
    name: 'journal',
    path: '/journal',
    component: JournalScreen,
  },
  {
    name: 'not_found_page',
    path: '/not_found_page',
    component: NotFoundPage,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'not_found_page' },
  },
  // {
  //   name: 'not_found_page',
  //   path: '/not_found_page',
  //   component: NotFoundPage,
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');

  // Handle root URL navigation
  if (to.path === '/') {
    if (token) {
      if (to.name !== 'home') {
        return next({ name: 'home' });
      }
    } else {
      if (to.name !== 'home-logout') {
        return next({ name: 'home-logout' });
      }
    }
  }

  // Handle routes requiring authentication
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (token) {
      return next();
    } else {
      if (to.name !== 'home-logout') {
        return next({ name: 'home-logout' });
      }
    }
  } else {
    return next();
  }
});

export default router;
