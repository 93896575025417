<template>
  <div @click="
    detail_navigation(
      purchase_oppurtunity.purchase_oppurtunity.id,
      purchase_oppurtunity.purchase_oppurtunity.entity,
      purchase_oppurtunity.purchase_oppurtunity.name,
      purchase_oppurtunity.purchase_oppurtunity.linked_media_id,
      purchase_oppurtunity.purchase_oppurtunity.linked_media_type,
    )
    " class="overflow-hidden bg-gray-100 mt-5 rounded-xl cursor-pointer">
    <div class="">
      <div class="h-60 rounded-2xl">
        <img :src="purchase_oppurtunity.purchase_oppurtunity.image"
          :alt="purchase_oppurtunity.purchase_oppurtunity.name" class="w-full h-full  " />
      </div>
      <div class="p-3">
        <h2 class="text-lg my-1 font-semibold mb-2 truncate w-full">
          {{ purchase_oppurtunity.purchase_oppurtunity.name }}
        </h2>

        <div class="flex justify-between gap-2 flex-wrap items-center ">
          <!-- <span class="font-medium   text-black">FROM</span> -->
          <span class="bg-blue px-3 py-1 rounded-full text-white font-semibold text-xs">MEDIA</span>
          <div class="flex items-center gap-2 justify-center ">
            <MediaLikeComponent :identify_media_data="purchase_oppurtunity.purchase_oppurtunity" />
            <span class="text-xl mr-2">Like</span>
          </div>
        </div>
        <template v-if="purchase_oppurtunity.purchase_oppurtunity.link">
          <!-- <div class="flex justify-center"> -->
            <a :href="purchase_oppurtunity.purchase_oppurtunity.link" target="_blank"><button
              class="border-1 border-blue text-md font-semibold  text-blue hover:bg-blue hover:text-white hover:border-1 rounded-2xl w-full px-3 py-2 my-4 duration-500">
              Purchase
            </button></a>
          <!-- </div> -->
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import MediaLikeComponent from '@/common_components/media_fav_icon/media_like_component.vue';
import { specific_page_routing } from '@/common_components/common_routing/specific_data_routing';
import { media_page_navigation } from '@/common_components/common_routing/media_page_routing';
export default {
  name: 'PurchaseCardActivityComponent',
  components: {
    MediaLikeComponent,
  },
  props: {
    purchase_oppurtunity: {
      type: Object,
    },
  },
  data() {
    return {
      isLike: this.purchase_oppurtunity.purchase_oppurtunity.like,
    };
  },

  methods: {
    detail_navigation(
      id,
      component,
      media_name = null,
      media_id = null,
      media_type = null,
    ) {
      if (media_id && media_type) {
        media_page_navigation(this.$router, media_id, media_name, media_type);
      } else {
        specific_page_routing(this.$router, id, component);
      }
    },
  },
};
</script>

<style scoped>
/* Add your component-specific styles here */
</style>
