<template>
  <!-- <template v-if="false"> -->
  <div
    v-if="loading === false && related_media_result_list.length > 0"
    class="py-7"
  >
    <p class="lg:text-3xl md:text-2xl text-xl font-semibold sm:font-bold px-5 text-center mb-10">
      Related Media Based On Activity
      <span class="text-blue md:text-xl sm:text-lg text-md lg:text-2xl">({{ related_media_result?.count }})</span>
    </p>
    <div
     class="grid xxl:grid-cols-6 lg:grid-cols-5 mb-10 md:grid-cols-4 min-[380px]:px-0 px-5 min-[550px]:grid-cols-3 min-[380px]:grid-cols-2 grid-cols-1 gap-4 "
    >

      <CardComponent
        v-for="(card_data, index) in related_media_result_list"
        :key="index"
        :card_data="card_data"
      />
      <!-- RelatedMediaComponent Component -->
      <!-- <RelatedMediaCardComponent
        v-for="(card_data, index) in related_media_result_list"
        :key="index"
        :card_data="card_data"
      /> -->
    </div>
    <div v-if="show_more_result" class="main-item">
      <div class="animated-background">
        <div class="background-masker btn-divide-left"></div>
      </div>
    </div>

    <div
      v-if="related_media_result.next !== null"
      class="flex justify-center items-center"
    >
      <SeeMoreIcon @click="see_more_result(related_media_result.next)" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
// import RelatedMediaCardComponent from './related_media_card_component.vue';
import CardComponent from '@/common_components/card/card_component.vue';
import SeeMoreIcon from '@/common_components/show_more/show_more_component.vue';

export default {
  name: 'RelatedMediaComponent',
  components: {
    // RelatedMediaCardComponent,
    CardComponent,
    SeeMoreIcon,
  },
  computed: {
    ...mapState({
      related_media_result: (state) =>
        state.related_media_activity_store.related_media_result,
      related_media_result_list: (state) =>
        state.related_media_activity_store.related_media_result_list,
    }),
  },
  async created() {
    this.reset_related_media_result();
    await this.fetchDataFromApis(this.$route.params.id, this.page);
  },
  data() {
    return {
      loading: false,
      show_more_result: false,
      page: 1,
    };
  },

  watch: {
    '$route.params.id': {
      handler(newId) {
        this.fetchDataFromApis(newId, this.page);
        this.set_selected_tab('identify');
      },
    },
  },

  methods: {
    ...mapActions('related_media_activity_store', [
      'related_media_show_result_api',
    ]),
    ...mapMutations('related_media_activity_store', [
      'reset_related_media_result',
    ]),
    ...mapMutations('home_store', ['set_selected_tab']),

    async fetchDataFromApis(media_id, page) {
      this.page = 1;
      this.loading = true;
      try {
        await Promise.all([
          this.related_media_show_result_api({
            id: media_id,
            page: page,
          }),
        ]);
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.loading = false;
      }
    },

    async see_more_result(page) {
      if (this.related_media_result.next !== null) {
        await this.show_more_results(this.$route.params.id, page);
      }
    },

    async show_more_results(media_id, page) {
      this.show_more_result = true;
      try {
        await Promise.all([
          this.related_media_show_result_api({
            id: media_id,
            page: page,
          }),
        ]);
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.show_more_result = false;
      }
    },
  },
};
</script>

<style></style>
