<template>
    <section class="">
        <div class="haeder flex justify-between py-2 px-4 shadow-xl">
            <div class="flex gap-3 items-center font-semibold text-[#959BA3] px-2">
                <div class="w-12 rounded-full relative">
                    <img src="../../../images/ziaumer.png" alt="user.name" class="rounded-full" />
                    <img src='../../../images/offline.png' alt="offline"
                        class="absolute bottom-1 right-1 bg-white rounded-full w-3 h-3" />
                </div>
                <div>Figgy</div>
            </div>
            <div class="icons flex gap-5 items-center text-[#959BA3]">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                    class="bi bi-file-plus-fill" viewBox="0 0 16 16">
                    <path
                        d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M8.5 6v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 1 0" />
                </svg>
                <div class="">
                    <span @click.stop="toggleMenu" class="cursor-pointer relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                            class="bi bi-person-fill-add" viewBox="0 0 16 16">
                            <path
                                d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0m-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                            <path
                                d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4" />
                        </svg>
                    </span>

                    <div v-if="isMenuOpen" ref="menu"
                        class="absolute px-6 right-0 xs:right-24 top-32 lg:top-16 bg-white text-black border rounded-lg shadow-lg py-3 z-50">
                        <h3 class=" font-semibold p-2 border-b">Select allies for group</h3>
                        <p class="text-xs p-2">Add people</p>
                        <div class="py-2">
                            <div
                                class="search-name flex pr-4 items-center rounded-xl overflow-hidden bg-white border-2">
                                <input class="w-full p-4 focus:outline-none" type="text" placeholder="Username ...">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                                        class="bi bi-search" viewBox="0 0 16 16">
                                        <path
                                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                    </svg>
                                </span>
                            </div>
                            <div class="flex items-center my-3 gap-2 p-2 bg-gray-100 rounded cursor-pointer">
                                <img src="../../../images/ziaumer.png" alt="user.name" class="w-12 rounded-full" />
                                <div class="flex-1">
                                    <p class="text-sm font-medium">Zia Umar</p>
                                    <p class="text-xs text-gray-500">Online</p>
                                </div>
                                <div class="flex items-center gap-3">
                                    <p class="text-[10px] flex text-blue bg-gray-300 p-2 rounded-xl ">
                                        <span>Data privacy</span>
                                    </p>
                                    <input class="w-5 h-5" type="checkbox">
                                </div>
                            </div>
                            <div class="flex items-center my-3 gap-2 p-2 bg-gray-100 rounded cursor-pointer">
                                <img src="../../../images/ziaumer.png" alt="user.name" class="w-12 rounded-full" />
                                <div class="flex-1">
                                    <p class="text-sm font-medium">Zia Umar</p>
                                    <p class="text-xs text-gray-500">Online</p>
                                </div>
                                <div class="flex items-center gap-3">
                                    <p class="text-[10px] flex text-blue bg-gray-300 p-2 rounded-xl ">
                                        <span>Data privacy</span>
                                    </p>
                                    <input class="w-5 h-5" type="checkbox">
                                </div>
                            </div>
                            <div class="flex items-center my-3 gap-2 p-2 bg-gray-100 rounded cursor-pointer">
                                <img src="../../../images/ziaumer.png" alt="user.name" class="w-12 rounded-full" />
                                <div class="flex-1">
                                    <p class="text-sm font-medium">Zia Umar</p>
                                    <p class="text-xs text-gray-500">Online</p>
                                </div>
                                <div class="flex items-center gap-3">
                                    <p class="text-[10px] flex text-blue bg-gray-300 p-2 rounded-xl ">
                                        <span>Data privacy</span>
                                    </p>
                                    <input class="w-5 h-5" type="checkbox">
                                </div>
                            </div>
                        </div>
                        <div class="flex justify-center">
                            <button class="bg-blue text-white text-xl px-6 py-2 rounded-xl">Create Group</button>
                        </div>
                    </div>
                </div>
                <span class="see-members cursor-pointer" @click="toggleMembers">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                        class="bi bi-people-fill" viewBox="0 0 16 16">
                        <path
                            d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
                    </svg>
                </span>
                <div>
                    <span @click.stop="settingMenu" class="cursor-pointer relative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                            class="bi bi-gear-fill" viewBox="0 0 16 16">
                            <path
                                d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                        </svg>
                    </span>
                    <div v-if="isSettingOpen" ref="menu"
                        class="absolute px-6 right-0 xs:right-12 top-32 lg:top-0 bg-white text-black border rounded-lg shadow-lg py-3 z-50">
                        <h3 @click.stop="privacyMenu"
                            class="relative cursor-pointer font-semibold flex gap-3 items-center w-60 bg-gray-300 rounded-lg  p-2 border-b">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                class="bi bi-file-lock2" viewBox="0 0 16 16">
                                <path
                                    d="M8 5a1 1 0 0 1 1 1v1H7V6a1 1 0 0 1 1-1m2 2.076V6a2 2 0 1 0-4 0v1.076c-.54.166-1 .597-1 1.224v2.4c0 .816.781 1.3 1.5 1.3h3c.719 0 1.5-.484 1.5-1.3V8.3c0-.627-.46-1.058-1-1.224" />
                                <path
                                    d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1" />
                            </svg>
                            <span>Privacy</span>
                        </h3>
                        <div v-if="isPrivacyOpen" ref="menu"
                            class="absolute w-96 px-6 right-0 xs:right-0 top-32 lg:top-0 bg-white text-black border rounded-lg shadow-lg py-3 z-50">
                            <p class="py-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                                    class="bi bi-arrow-left" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
                                </svg>
                            </p>
                            <h3 class=" font-semibold p-2 border-b">Who can communicate in the group?</h3>
                            <div class=" p-3 text-sm">
                                <p class="font-semibold mb-1">Only admin</p>
                                <p class="text-gray-500">Switch ON/OFF for Only admin access.</p>
                            </div>
                            <div class=" p-3 text-sm">
                                <p class="font-semibold mb-1">Group members</p>
                                <p class="text-gray-500">Switch ON/OFF for Group members access.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- chat screen message view  -->

        <section class=" flex ">
            <div class=" w-full">
                <div class="chat-screen  relative overflow-y-auto h-[79vh] lg:h-[84vh]  px-6 py-10">
                    <div class="person-one mb-5 flex gap-3 ">
                        <div>
                            <img src="../../../images/ziaumer.png" alt="user.name" class="rounded-full w-12 " />
                        </div>
                        <div>
                            <p class="text-[#607D8B] mb-5 gap-6 font-[500] text-[15px]">
                                <span>muatex</span><span class="mx-4 text-[11px]">11/16/2021 8:14 AM</span>
                            </p>
                            <div class="bg-gray-300 rounded-lg p-4 xs:p-6 border-l-4 border-blue">
                                Hope you're doing great—just wanted to check in and say hello!"
                            </div>
                        </div>
                    </div>

                </div>
                <div class="message-field flex gap-3 p-2 pb-3">
                    <div class="bg-gray-300 rounded-full flex justify-center items-center w-12">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                            class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                            <path
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                        </svg>
                    </div>
                    <div class="flex w-full overflow-hidden px-4 rounded-full items-center bg-gray-300">
                        <input type="text" placeholder="Message #Group name"
                            class="p-2 w-full focus:outline-none bg-gray-300">
                        <div class="flex gap-4">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                                class="bi bi-person-video" viewBox="0 0 16 16">
                                <path d="M8 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
                                <path
                                    d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm10.798 11c-.453-1.27-1.76-3-4.798-3-3.037 0-4.345 1.73-4.798 3H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1z" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                                class="bi bi-emoji-smile-fill" viewBox="0 0 16 16">
                                <path
                                    d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5M4.285 9.567a.5.5 0 0 1 .683.183A3.5 3.5 0 0 0 8 11.5a3.5 3.5 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683M10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <transition name="slide">
                <div v-show="showMembers" class="members text-gray-500 bg-gray-100 w-96 p-4 ">
                    <h3 class="font-semibold text-gray-500 mb-5">Group Members-3</h3>
                    <div class="flex items-center my-3 gap-2  rounded cursor-pointer">
                        <img src="../../../images/ziaumer.png" alt="user.name" class="w-12 rounded-full" />
                        <div class="flex-1">
                            <p class="text-sm font-medium">Zia Umar</p>
                        </div>
                        <div class="flex items-center gap-3">
                            <p class="text-[10px] font-semibold flex text-blue bg-gray-300 p-2 rounded-xl ">
                                <span>Data privacy</span>
                            </p>
                        </div>
                    </div>
                    <div class="flex items-center my-3 gap-2  rounded cursor-pointer">
                        <img src="../../../images/ziaumer.png" alt="user.name" class="w-12 rounded-full" />
                        <div class="flex-1">
                            <p class="text-sm font-medium">Zia Umar</p>
                        </div>
                        <div class="flex items-center gap-3">
                            <p class="text-[10px] font-semibold flex text-blue bg-gray-300 p-2 rounded-xl ">
                                <span>Data privacy</span>
                            </p>
                        </div>
                    </div>
                    <div class="flex items-center my-3 gap-2  rounded cursor-pointer">
                        <img src="../../../images/ziaumer.png" alt="user.name" class="w-12 rounded-full" />
                        <div class="flex-1 text-gray-500">
                            <p class="text-sm font-medium">Zia Umar</p>
                            <p class="text-xs font-medium">Admin</p>
                        </div>
                        <div class="flex items-center gap-3">
                            <p class="text-[10px] font-semibold flex text-blue bg-gray-300 p-2 rounded-xl ">
                                <span>Data privacy</span>
                            </p>
                        </div>
                    </div>
                </div>
            </transition>
        </section>
    </section>
</template>
<script>


export default {
    name: 'GroupChatScreen',

    data() {
        return {
            isMenuOpen: false,
            isSettingOpen: false,
            isPrivacyOpen: false,
            showMembers: false,
        };
    },
    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        settingMenu() {
            this.isSettingOpen = !this.isSettingOpen;
        },
        privacyMenu() {
            this.isPrivacyOpen = !this.isPrivacyOpen;
        },
        closeMenu(event) {
            if (this.$refs.menu && !this.$refs.menu.contains(event.target)) {
                this.isMenuOpen = false;
                this.isSettingOpen = false;
                this.isPrivacyOpen = false;
            }
        },
        toggleMembers() {
            this.showMembers = !this.showMembers;
        }
    },
    mounted() {
        document.addEventListener("click", this.closeMenu);
    },
    beforeUnmount() {
        document.removeEventListener("click", this.closeMenu);
    },

};
</script>

