<template>
    <section class="">
        <div class="all-invites border-x border-gray-500 max-w-[700px] py-10 px-3 mx-auto sm:p-10">
            <p class="text-xl mb-4 font-semibold">Assign Events</p>
            <div class="bg-white shadow-xl mb-4 gap-2 text-[15px] font-[500] sm:px-6 py-6 p-4 rounded-xl ">
                <div class="flex justify-between w-full">
                    <div class="flex gap-2 items-center    ">
                        <div class="w-[80px] rounded-full">
                            <img src="../../../../images/ziaumer.png" alt="image" />
                        </div>
                        <p class="text-xl text-black font-semibold mb-1">The Adventures of Alice Wonderland-Dinner Event
                        </p>
                    </div>
                    <div class="flex gap-5">
                        <span @click.stop="toggleMenu" class="cursor-pointer relative">
                            <img class="w-[5px]" src="../../../../images/more.png" alt="image" />
                        </span>
                    </div>
                </div>
                <div class="text-gray-500">
                    <p class="mb-1 text-sm">Jump down the rabbit hole with Alice and our culinary students for an
                        eclectic
                        evening...
                    </p>
                    <p class="text-blue text-lg my-2">Status: <span class="text-red-600">Closed</span></p>
                    <div class="flex justify-between flex-wrap items-center">
                        <p class="text-[10px] flex gap-3 mb-1"><span>Start date: 1/24/2025</span><span>End date:
                                1/30/2025</span>
                        </p>
                        <div class="flex mb-1">
                            <img class="w-10" src="../../../../images/ziaumer.png" alt="image" />
                            <img class="w-10 relative -left-6" src="../../../../images/ziaumer.png" alt="image" />
                            <img class="w-10 relative -left-12" src="../../../../images/ziaumer.png" alt="image" />
                        </div>
                        <div>
                            <p class="bg-white mb-1 shadow-lg px-4 py-2 rounded-lg cursor-pointer text-black">View Details
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-white shadow-xl mb-4 gap-2 text-[15px] font-[500] sm:px-6 py-6 p-4 rounded-xl ">
                <div class="flex justify-between w-full">
                    <div class="flex gap-2 items-center    ">
                        <div class="w-[80px] rounded-full">
                            <img src="../../../../images/ziaumer.png" alt="image" />
                        </div>
                        <p class="text-xl text-black font-semibold mb-1">The Adventures of Alice Wonderland-Dinner Event
                        </p>
                    </div>
                    <div class="flex gap-5">
                        <span @click.stop="toggleMenu" class="cursor-pointer relative">
                            <img class="w-[5px]" src="../../../../images/more.png" alt="image" />
                        </span>
                    </div>
                </div>
                <div class="text-gray-500">
                    <p class="mb-1 text-sm">Jump down the rabbit hole with Alice and our culinary students for an
                        eclectic
                        evening...
                    </p>
                    <p class="text-blue text-lg my-2">Status: <span class="text-red-600">Closed</span></p>
                    <div class="flex justify-between flex-wrap items-center">
                        <p class="text-[10px] flex gap-3 mb-1"><span>Start date: 1/24/2025</span><span>End date:
                                1/30/2025</span>
                        </p>
                        <div class="flex mb-1">
                            <img class="w-10" src="../../../../images/ziaumer.png" alt="image" />
                            <img class="w-10 relative -left-6" src="../../../../images/ziaumer.png" alt="image" />
                            <img class="w-10 relative -left-12" src="../../../../images/ziaumer.png" alt="image" />
                        </div>
                        <div>
                            <p class="bg-white mb-1 shadow-lg px-4 py-2 rounded-lg cursor-pointer text-black">View Details
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-white shadow-xl mb-4 gap-2 text-[15px] font-[500] sm:px-6 py-6 p-4 rounded-xl ">
                <div class="flex justify-between w-full">
                    <div class="flex gap-2 items-center    ">
                        <div class="w-[80px] rounded-full">
                            <img src="../../../../images/ziaumer.png" alt="image" />
                        </div>
                        <p class="text-xl text-black font-semibold mb-1">The Adventures of Alice Wonderland-Dinner Event
                        </p>
                    </div>
                    <div class="flex gap-5">
                        <span @click.stop="toggleMenu" class="cursor-pointer relative">
                            <img class="w-[5px]" src="../../../../images/more.png" alt="image" />
                        </span>
                    </div>
                </div>
                <div class="text-gray-500">
                    <p class="mb-1 text-sm">Jump down the rabbit hole with Alice and our culinary students for an
                        eclectic
                        evening...
                    </p>
                    <p class="text-blue text-lg my-2">Status: <span class="text-red-600">Closed</span></p>
                    <div class="flex justify-between flex-wrap items-center">
                        <p class="text-[10px] flex gap-3 mb-1"><span>Start date: 1/24/2025</span><span>End date:
                                1/30/2025</span>
                        </p>
                        <div class="flex mb-1">
                            <img class="w-10" src="../../../../images/ziaumer.png" alt="image" />
                            <img class="w-10 relative -left-6" src="../../../../images/ziaumer.png" alt="image" />
                            <img class="w-10 relative -left-12" src="../../../../images/ziaumer.png" alt="image" />
                        </div>
                        <div>
                            <p class="bg-white mb-1 shadow-lg px-4 py-2 rounded-lg cursor-pointer text-black">View Details
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
    </section>
</template>

<script>
export default {
    name: "AssignEvent",
};
</script>

<style>
.relative {
    position: relative;
}

.absolute {
    position: absolute;
}
</style>