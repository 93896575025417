<template>
  <div v-if="loading" class="loading-indicator">
    <div class="loader"></div>
  </div>
  <div v-else class="w-full mx-auto font-inter">
    <HomeHeaders />
    <div class="flex flex-col justify-center items-center py-[160px] bg-cover relative bgClass">

      <h2 class="text-3xl relative z-10 md:text-5xl text-white text-center font-bold pb-16">
        Quick Media Search
      </h2>

      <SearchComponent />
    </div>
    <!-- Quick Media Search End -->

    <div class="bg-white">

      <!-- Leftside Start -->
      <div class=" h-auto sm:p-3 py-3 mt-8 rounded-2x mx-2 rounded-2xl">
        <!-- My Progress Start -->
        <div class="my-progress relative rounded-2xl">
          <!-- <div
            class="absolute z-10 w-full text-center mt-10 bg-blur-lightblue py-5"
          >
            <h3 class="text-2xl font-semibold">Coming Soon!</h3>
            <p>Stay Tuned For Updates</p>
          </div> -->
          <div class=" mb-6">
            <div class="sm:px-5 w-[70%] mx-auto px-0">
              <h2 class="text-[28px] text-center font-bold mb-3">
                My Progress
              </h2>
              <div class="flex justify-between items-center mb-2">
                <h3 class="text-2xl text-blue font-bold">
                  Level {{ badge_curoisity_data.level }}
                </h3>
                <p class="text-xs sm:text-lg font-medium">
                  {{ badge_curoisity_data.xp }} XP
                </p>
              </div>
              <div class="relative bg-silver rounded-full h-6 w-full mb-7">
                <span class="absolute left-0 top-0 bg-blue h-6 rounded-full"
                  :style="{ width: badge_curoisity_data.progress + '%' }"></span>
              </div>
            </div>
            <div v-if="badge_curoisity_data.badge.length > 0">
              <div
                class="grid grid-cols-2 gap-4 min-[410px]:flex justify-center min-[410px]:gap-4 sm:flex-nowrap min-[410px]:flex-wrap py-6 px-3">
                <BadgeCuroisityComponent v-for="(badge_curoisity_data, index
                ) in badge_curoisity_data.badge" :key="index" :curoisity_data="badge_curoisity_data" />
              </div>
              <!-- <div class="flex flex-wrap justify-center gap-4 py-6 px-3">
                <BadgeCuroisityComponent v-for="(badge_curoisity_data, index) in badge_curoisity_data.badge"
                  :key="index" :curoisity_data="badge_curoisity_data"  />
              </div> -->
              <!-- class="w-[calc(100%/6-16px)] max-w-[160px]" -->
              <!-- <div
                class="grid grid-cols-2 sm:grid-cols-4 min-[950px]:grid-cols-6 min-[1280px]:grid-cols-8 gap-x-3 gap-y-4 py-6 px-3">
                <BadgeCuroisityComponent v-for="(badge_curoisity_data, index
                ) in badge_curoisity_data.badge" :key="index" :curoisity_data="badge_curoisity_data" />
              </div> -->
              <div class="my-10 flex gap-6 justify-center">
                <div
                  class="flex items-center px-3 py-2 rounded-xl border border-blue bg-blue text-white cursor-pointer transition-all duration-300 ease-in-out hover:bg-white hover:border-blue hover:text-blue"
                  @click="navigate_to_stats">
                  <span class="text-[10px] vsx:text-xs sm:text-base cursor-pointer">Badges & Levels</span>
                  <span class="ml-3 sm:block hidden font-semibold">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                      class="bi bi-arrow-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                    </svg>
                  </span>
                </div>
                <div
                  class="flex items-center px-5 py-2 rounded-xl bg-blue text-white cursor-pointer transition-all duration-300 ease-in-out hover:bg-white hover:border-blue hover:text-blue border border-transparent"
                  @click="navigate_to_xp">
                  <span class="text-[10px] vsx:text-xs sm:text-base cursor-pointer">XP </span>
                  <span class="ml-3 sm:block hidden font-semibold">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                      class="bi bi-arrow-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                    </svg>
                  </span>
                </div>
              </div>
            </div>

            <div v-else class="grid text-center xl:grid-cols-2 xl:gap-x-3 gap-y-4 h-40 overflow-y-auto px-3">
              No Badges Available
            </div>
          </div>
        </div>
        <!-- My Progress End -->
      </div>

      <!-- Recently View Section Start -->
      <section class="pt-8 mb-10">
        <!-- Rightside Start -->
        <div class="sm:px-10 px-5 w-full">
          <div class="flex justify-between items-center mb-8 ">
            <h2 class="sm:text-2xl text-lg md:text-3xl font-bold  ">
              Recently Viewed
            </h2>
            <template v-if="
              recently_viewed_data &&
              recently_viewed_data.result.results.length > 3
            ">
              <a @click="search_result_navigate('recently_viewed')"
                class="flex items-center px-3 py-2 rounded-full bg-blue text-white">
                <span class="text-[10px] vsx:text-xs sm:text-md text-white cursor-pointer">See more</span>
                <span class="ml-3 sm:block hidden font-semibold text-white">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                    class="bi bi-arrow-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                  </svg>
                </span>
              </a>
            </template>
          </div>
          <!-- Recently viewed slider start -->
          <template v-if="
            recently_viewed_data &&
            recently_viewed_data.result.results.length > 0
          ">
            <div class="relative  sm:mx-0 mx-3">
              <div class="recently-viewed-slider swiper relative">
                <!-- 1st -->
                <div class="swiper-wrapper">
                  <template v-for="(item, index) in recently_viewed_data.result.results" :key="index">
                    <CardComponent :card_data="item" :card_page="recent_view" />
                  </template>
                </div>
              </div>
              <template v-if="recently_viewed_data.result.results.length > 3">
                <div class="previous-viewed">
                  <button type="button"
                    class="absolute p-4 py-2 rounded-full bg-blue text-white z-20 top-[40%] -left-8">
                    <span class="icon-slider-leftarrow "></span>
                  </button>
                </div>
                <div class="next-viewed">
                  <button type="button"
                    class="absolute p-4 py-2 rounded-full bg-blue text-white z-20 top-[40%] -right-8">
                    <span class="icon-slider-rightarrow"></span>
                  </button>
                </div>
              </template>
            </div>
          </template>
          <template v-else>
            <div class="w-full">
              <p class="text-2xl font-semibold py-4  text-gray-500">No Recently Viewed Media</p>
            </div>
          </template>
        </div>
        <!-- Rightside Ed -->
      </section>
      <!-- Recently View Section End -->

      <!-- New All Favorites slider start   -->
      <section class="bg-gray-200 sm:px-10 px-5 py-20 w-full">
        <div class="flex justify-between items-center mb-8 ">
          <h2 class="sm:text-2xl text-lg   md:text-3xl font-bold  ">
            Favorites
          </h2>
          <template v-if="favorite_data && favorite_data.result.results.length > 2">
            <a @click="search_result_navigate('favorite_media')"
              class="flex items-center px-3 py-2 rounded-full bg-blue text-white">
              <span class="text-[10px] vsx:text-xs sm:text-md text-white cursor-pointer">See more</span>
              <span class="ml-3 sm:block hidden font-semibold text-white">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                  class="bi bi-arrow-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                </svg>
              </span>
            </a>
          </template>
        </div>

        <!-- New All Favorites slider End -->
        <template v-if="favorite_data && favorite_data.result.results.length > 0">
          <div class="relative  sm:mx-0 mx-3">
            <template v-if="favorite_data.result.results.length > 2">
              <div class="previous-newfavorites">
                <button type="button" class="absolute p-4 py-2 rounded-full bg-white text-blue z-20 top-[40%] -left-8">
                  <span class="icon-slider-leftarrow "></span>
                </button>
              </div>
              <div class="next-newfavorites">
                <button type="button" class="absolute p-4 py-2 rounded-full bg-white text-blue z-20 top-[40%] -right-8">
                  <span class="icon-slider-rightarrow"></span>
                </button>
              </div>
            </template>
            <div class="new-all-favorites-slider swiper w-full">
              <!-- component -->
              <div class="swiper-wrapper">
                <template v-for="(item, index) in favorite_data_list" :key="index">
                  <FavCardComponent :favorite_list_data="item" />
                </template>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="text-2xl font-semibold py-4  text-gray-500">No Favourites</div>
          <div>
            <img class="w-40  mx-auto" src="../../images/nofav.png" alt="fav">
          </div>
        </template>
        <!-- New All Favorites slider end -->
      </section>

      <!-- Trending Media Start -->
      <section class="sm:px-10 px-5 w-full py-16">
        <div class="flex justify-between items-center mb-8 ">
          <h2 class="sm:text-2xl text-lg  md:text-3xl font-bold  ">
            Trending Media
          </h2>
          <template v-if="
            trending_media_data && trending_media_data.result.results.length > 3
          ">
            <a @click="search_result_navigate('trending_media')"
              class="flex items-center px-3 py-2 rounded-full bg-blue text-white">
              <span class="text-[10px] vsx:text-xs sm:text-md text-white cursor-pointer">See more</span>
              <span class="ml-3 sm:block hidden font-semibold text-white">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                  class="bi bi-arrow-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                </svg>
              </span>
            </a>
          </template>
        </div>

        <!-- Trending Media slider start -->
        <template v-if="
          trending_media_data && trending_media_data.result.results.length > 0
        ">
          <div class="relative  sm:mx-0 mx-3">
            <template v-if="trending_media_data.result.results.length > 4">
              <div class="previous-trending-media">
                <button type="button" class="absolute p-4 py-2 rounded-full bg-blue text-white z-20 top-[40%] -left-8">
                  <span class="icon-slider-leftarrow "></span>
                </button>
              </div>
              <div class="next-trending-media">
                <button type="button" class="absolute p-4 py-2 rounded-full bg-blue text-white z-20 top-[40%] -right-8">
                  <span class="icon-slider-rightarrow"></span>
                </button>
              </div>
            </template>
            <div class="trending-media-slider swiper justify-center w-full">
              <div class="swiper-wrapper">
                <!-- 1st -->
                <template v-for="(item, index) in trending_media_data.result.results" :key="index">
                  <CardComponent :card_data="item" />
                </template>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="text-2xl font-semibold py-4  text-gray-500">
            <p>No Trending Media</p>
          </div>
        </template>
        <!-- Trending Media slider end -->
      </section>
      <!-- Trending Media End -->

      <!-- Feature Media Start -->
      <section class="sm:px-10 px-5 w-full pb-16">
        <div class="flex justify-between items-center mb-8 ">
          <h2 class="sm:text-2xl text-lg  md:text-3xl font-bold  ">
            Featured Media
          </h2>
          <template v-if="
            feature_media_data && feature_media_data.result.results.length > 4
          ">
            <a @click="search_result_navigate('featured_media')"
              class="flex items-center px-3 py-2 rounded-full bg-blue text-white">
              <span class="text-[10px] vsx:text-xs sm:text-md text-white cursor-pointer">See more</span>
              <span class="ml-3 sm:block hidden font-semibold text-white">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                  class="bi bi-arrow-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                </svg>
              </span>
            </a>
          </template>
        </div>

        <!-- Feature Media slider start -->
        <template v-if="
          feature_media_data && feature_media_data.result.results.length > 0
        ">
          <div class="relative  sm:mx-0 mx-3">
            <template v-if="feature_media_data.result.results.length > 4">
              <div class="previous-feature-media">
                <button type="button" class="absolute p-4 py-2 rounded-full bg-blue text-white z-20 top-[40%] -left-8">
                  <span class="icon-slider-leftarrow "></span>
                </button>
              </div>
              <div class="next-feature-media">
                <button type="button" class="absolute p-4 py-2 rounded-full bg-blue text-white z-20 top-[40%] -right-8">
                  <span class="icon-slider-rightarrow"></span>
                </button>
              </div>
            </template>
            <div class="featured-media-slider swiper justify-center w-full">
              <div class="swiper-wrapper">
                <template v-for="(item, index) in feature_media_data.result.results" :key="index">
                  <CardComponent :card_data="item" />
                </template>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="w-full pt-10 px-10">
            <p>No Featured Media</p>
          </div>
        </template>
        <!-- Feature Media slider end -->
      </section>
      <!-- Feature Media End -->
    </div>

    <div class="recently-bottom-border border-b-1 border-borderColor mt-20 mb-4 mx-20"></div>

    <HomeFooter />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import HomeHeaders from '@/common_components/header/header.vue';
import HomeFooter from '@/common_components/footer/footer.vue';
import { initializeSliders } from './home_slider.js';
import CardComponent from '@/common_components/card/card_component.vue';
import SearchComponent from '@/controller/home/components/search_component.vue';
import FavCardComponent from '@/common_components/fav_card/fav_card_component.vue';
import { all_media_type_values } from '@/common_variables/media_type.js';
import BadgeCuroisityComponent from './components/badge_curoisity_component.vue';

export default {
  name: 'HomeScreen',
  components: {
    HomeHeaders,
    HomeFooter,
    CardComponent,
    SearchComponent,
    FavCardComponent,
    BadgeCuroisityComponent,
  },

  data() {
    return {
      loading: false,
      recent_view: 'recent_view',
      favorite_view: 'favorite_view',
      selected_favorite_data: null,
      show_more_result: false,
      page: 1,
      media_type_list: Object.keys(all_media_type_values).map(
        (key) => all_media_type_values[key].media_type_key,
      ),
    };
  },

  computed: {
    ...mapState({
      recently_viewed_data: (state) => state.home_store.recently_viewed_data,
      trending_media_data: (state) => state.home_store.trending_media_data,
      feature_media_data: (state) => state.home_store.feature_media_data,
      favorite_data: (state) => state.home_store.favorite_data,
      favorite_data_list: (state) => state.home_store.favorite_data_list,
      selected_tab: (state) => state.home_store.selected_tab,
      badge_curoisity_data: (state) => state.home_store.badge_curoisity_data,
    }),
  },

  async created() {
    this.set_selected_tab('identify');
    await this.fetchDataFromApis();
    initializeSliders();
  },

  mounted() { },

  methods: {
    ...mapActions('home_store', [
      'recently_viewed_api',
      'trending_media_api',
      'feature_media_api',
      'profile_api',
      'all_favorite_api',
      'badge_curoisity_api',
    ]),
    ...mapMutations('home_store', ['set_selected_tab']),

    navigate_to_stats() {
      this.$router.push({ name: 'stats' });
    },

    navigate_to_xp() {
      this.$router.push({ name: 'xp_page' });
    },

    mediaSentence() {
      if (!this.selected_favorite_data) {
        return '';
      }
      if (
        this.media_type_list.includes(this.selected_favorite_data.entity_type)
      ) {
        return `Related media for this ${this.selected_favorite_data.fav_type} are as follows:`;
      } else {
        return `This ${this.selected_favorite_data.fav_type} appears in the following media:`;
      }
    },
    async show_more_results(page) {
      this.show_more_result = true;
      try {
        await Promise.all([
          this.all_favorite_api({
            page: page,
          }),
        ]);
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.show_more_result = false;
      }
    },

    select_favorite_data(data) {
      data;
    },

    search_result_navigate(search_type) {
      this.$router.push({
        name: 'search-result',
        query: {
          media: 'all',
          search_type: search_type,
          selected_media: 'All',
        },
      });
    },

    openAllMediaDropdown() {
      this.dropdownHidden = !this.dropdownHidden;
    },

    async fetchDataFromApis() {
      this.loading = true;
      try {
        await Promise.all([
          this.recently_viewed_api(),
          this.trending_media_api(),
          this.feature_media_api(),
          this.profile_api(),
          this.all_favorite_api({ page: this.page }),
          this.badge_curoisity_api(),
        ]);
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.selected_favorite_data = this.favorite_data.result.results[0];
        this.loading = false;
      }
    },
  },
};
</script>

<style>
@import '../../css/icomoon.css';
@import '../../css/style.css';
@import '../../css/discovery.css';

.recently-viewed-slider,
.all-favorites-slider,
.activity-media-slider,
.trending-media-slider,
.new-all-favorites-slider,
.featured-media-slider {
  overflow: hidden;
}

.bgClass::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("/src/images/media-search-bg.jpg");
  background-size: contain;
  clip-path: ellipse(90% 100% at 50% 0);
  overflow: visible;
  z-index: 1;
}

/* Remove clip-path below 500px */
@media (max-width: 500px) {
  .bgClass::after {
    clip-path: none;
  }
}
</style>
