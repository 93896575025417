<template>
    <div class="all-invites h-[100vh] border-x border-gray-500 max-w-[700px] mx-auto py-10 px-3 sm:p-10">
        <p class="text-xl mb-4 font-semibold">Invite Allies</p>
        <p class="text-gray-500 my-4">You can add allies by entering their email address.</p>
        <div class="search-name flex rounded-xl overflow-hidden bg-white shadow-xl">
            <input class="w-full p-4 focus:outline-none" type="text" placeholder="Enter Email ...">
            <button class="bg-blue rounded-xl text-white w-40">Send Invite</button>
        </div>


    </div>
</template>
