<template>
    <div class="event-detail-container lg:h-[92vh] h-full px-5 sm:px-10 py-6">
        <div class="bg-white pb-6 rounded-xl lg:h-full">
            <div class="header p-4 cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                    class="bi bi-arrow-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
                </svg>
            </div>
            <div class="content h-full lg:h-[80vh] lg:flex justify-between gap-4 p-6">
                <div class=" w-full mb-10 lg:w-[60%]">
                    <p class="text-gray-500 text-lg font-semibold mb-2">Task</p>
                    <p class="text-xl font-semibold mb-5">Review and Update Book Experience </p>
                    <div class="text-gray-500 font-semibold mb-5">
                        <p class="mb-2 text-lg">Description </p>
                        <p class="text-md font-normal">Enhance the "Experience-The complete Persepolis" page by refining
                            the reading progress, book status, review, quotes, and rating sections for better usability.
                            Reviews, adding quotes, and rating books.</p>
                    </div>
                    <div class="text-gray-500 font-semibold mb-8">
                        <h2 class="text-lg">Event</h2>
                        <p class="text-md font-normal text-black">The Adventures of Alice Wonderland-Dinner Event</p>
                    </div>
                    <div class="text-lg text-gray-500 flex flex-wrap items-center font-semibold gap-5 ">
                        <p>Start date:<span class="text-sm font-normal">1/24/2025</span></p>
                        <p>End date:<span class="text-sm font-normal">1/30/2025 </span></p>
                    </div>
                </div>
                <div class="summary  w-full lg:w-[350px] text-gray-500  py-6 px-6 rounded-xl bg-gray-100 ">
                    <p class="text-[18px] mb-2 font-semibold">Participants</p>
                    <div class="mb-10 ">
                        <div class="flex flex-wrap gap-3">
                            <div class="w-16 relative">
                                <img src="../../../../images/ziaumer.png" alt="image" />
                                <span class="absolute top-1 bg-[#12B625] text-white rounded-full right-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-check" viewBox="0 0 16 16">
                                        <path
                                            d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                                    </svg>
                                </span>
                                <p class="text-xs mx-auto text-center font-semibold">Ziaumer</p>
                            </div>
                            <div class="w-16 rounded-full relative">
                                <img src="../../../../images/ziaumer.png" alt="image" />
                                <span class="absolute top-1 bg-gray-500 text-white rounded-full right-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-clock" viewBox="0 0 16 16">
                                        <path
                                            d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
                                    </svg>
                                </span>
                                <p class="text-xs mx-auto text-center font-semibold">Tom Ford</p>
                            </div>
                            <div class="w-16 rounded-full relative">
                                <img src="../../../../images/ziaumer.png" alt="image" />
                                <span class="absolute top-1 bg-[#12B625] text-white rounded-full right-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-check" viewBox="0 0 16 16">
                                        <path
                                            d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                                    </svg>
                                </span>
                                <p class="text-xs mx-auto text-center font-semibold">Isebella</p>
                            </div>
                        </div>
                    </div>
                    <div  class="mb-10 ">
                        <h2 class="font-semibold text-[18px]">Mark task as completed</h2>
                        <div class="py-6 flex flex-wrap gap-3">
                            <span class="text-black cursor-pointer text-sm bg-[#26C74E] py-2 px-4 rounded-full">Completed</span>
                            <span class="text-sm cursor-pointer bg-white py-2 px-4 rounded-full">2x Bonus</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EventDetail',
    props: {
        eventId: {
            type: [String, Number],
            required: true
        }
    }
}
</script>