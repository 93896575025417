<template>
  <div @click="
    detail_navigation(
      career_interest.career_interest.id,
      career_interest.career_interest.entity,
      career_interest.career_interest.name,
      career_interest.career_interest.linked_media_id,
      career_interest.career_interest.linked_media_type,
    )
    " class="border-1 border-borderColor p-5 rounded-2xl cursor-pointer"
    :style="{ backgroundImage: `url(${require('../../../../../images/card2.png')})` }">
    <div class="xs:flex xs:h-[400px] ">
      <div class="flex  flex-col  xs:min-w-40 lg:min-w-52 xs:h-full">
        <div class=" overflow-hidden lg:h-60  mx-auto w-60 xs:w-full h-48 rounded-2xl">
          <img :src="career_interest.career_interest.image" :alt="career_interest.career_interest.name"
            class="w-full h-full" />
        </div>
        <div class="flex flex-col items-center w-full ">
          <div class="flex justify-center mt-3 max-[560px]:flex-col">
            <div class="flex items-center">
              <MediaLikeComponent :identify_media_data="career_interest.career_interest" />
              <span class="text-xl mx-3">Like</span>
            </div>
          </div>
          <template v-if="career_interest.career_interest.additional_resources.length > 0">
            <button
              class="text-xs lg:block hidden sm:text-sm text-white bg-blue font-medium py-2 px-2 my-3 text-center rounded-full border-1 hover:text-blue hover:bg-white duration-500 hover:border-blue hover:border-1"
              @click.stop="additionalSources($event, career_interest.id)">
              {{ showAdditionalSources ? 'Hide' : 'View' }} Additional Sources
            </button>
            <button
              class="text-xs block lg:hidden sm:text-sm text-white bg-blue font-medium py-2 px-2 my-3 text-center rounded-full border-1 hover:text-blue hover:bg-white duration-500 hover:border-blue hover:border-1"
              @click.stop="additionalSources($event, career_interest.id)">
              {{ showAdditionalSources ? 'Hide' : 'View' }} more Sources
            </button>
            <ul class="flex flex-col mt-2 gap-2 sm:text-center max-[640px]:text-center hidden"
              :id="'additionalSources-cri-' + career_interest.id">
              <template v-for="(add_src, index) in career_interest.career_interest
                .additional_resources" :key="index">
                <li>
                  <span class="text-black underline text-xs">
                    <a v-if="add_src.link" :href="add_src.link" target="_blank" @click.stop="">{{ add_src.description
                    }}</a></span>
                </li>
              </template>
            </ul>
          </template>

        </div>
      </div>
        <div class="flex  overflow-y-hidden hover:overflow-y-scroll h-[300px] xs:h-full flex-col xs:ml-2 xxl:ml-5 w-full ">
          <h2 class="text-[28px] font-bold mb-2 mr-2 max-[640px]:text-[24px]">
            {{ career_interest.career_interest.name }}
          </h2>
          <!-- <p class="text-sm mt-3 h-[100px] overflow-y-auto pr-3">
                    <template v-if="career_interest.career_interest.description">
                        <p class="py-1"><b>Description:</b></p>
                        {{ career_interest.career_interest.description }}
                    </template>
                 <template v-if="career_interest.description">
                        <p class="py-1"><b>Title Specific Description:</b></p>
                        {{ career_interest.description }}
                    </template>
                  </p> -->
          <template v-if="career_interest.career_interest.description">
            <p class="py-1">
              <span><b>Description:</b></span>
            </p>
            <p class="text-sm mt-1 pr-3">
              {{ career_interest.career_interest.description }}
            </p>
          </template>
          <template v-if="career_interest.description">
            <p class="py-1 mt-1">
              <span><b>Title Specific Description:</b></span>
            </p>
            <p class="text-sm mt-1 pr-3">
              {{ career_interest.description }}
            </p>
          </template>
        </div>
    </div>
  </div>
</template>

<script>
import MediaLikeComponent from '@/common_components/media_fav_icon/media_like_component.vue';
import { specific_page_routing } from '@/common_components/common_routing/specific_data_routing';
import { media_page_navigation } from '@/common_components/common_routing/media_page_routing';
export default {
  name: 'CareerInterestCardComponent',
  components: {
    MediaLikeComponent,
  },
  props: {
    career_interest: {
      type: Object,
    },
  },
  data() {
    return {
      showAdditionalSources: false,
      isLike: this.career_interest.career_interest.like,
    };
  },
  methods: {
    detail_navigation(
      id,
      component,
      media_name = null,
      media_id = null,
      media_type = null,
    ) {
      if (media_id && media_type) {
        media_page_navigation(this.$router, media_id, media_name, media_type);
      } else {
        specific_page_routing(this.$router, id, component);
      }
    },

    additionalSources(e, id) {
      const div_id = 'additionalSources-cri-' + id;
      this.showAdditionalSources = !this.showAdditionalSources;
      document.querySelector('#' + div_id).classList.toggle('hidden');
    },
  },
};
</script>

<style scoped>
/* Add your component-specific styles here */
</style>
