<template>
    <section class="">
        <div class="haeder flex justify-between py-2 px-4 shadow-xl">
            <div class="flex gap-3 items-center font-semibold text-[#959BA3] px-2">
                <div class="w-12 rounded-full relative">
                    <img src="../../../images/ziaumer.png" alt="user.name" class="rounded-full" />
                    <img src='../../../images/offline.png' alt="offline"
                        class="absolute bottom-1 right-1 bg-white rounded-full w-3 h-3" />
                </div>
                <div>Figgy</div>
            </div>
            <div class="icons flex gap-5 items-center text-[#959BA3]">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                    class="bi bi-people-fill" viewBox="0 0 16 16">
                    <path
                        d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
                </svg>
                <div class="border border-[#959BA3] h-6"></div>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                    class="bi bi-envelope-fill" viewBox="0 0 16 16">
                    <path
                        d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                    class="bi bi-question-circle-fill" viewBox="0 0 16 16">
                    <path
                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247m2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
                </svg>
            </div>
        </div>
        <div class="chat-screen relative overflow-y-auto h-[79vh] lg:h-[84vh]  px-6 py-10">
            <div class="person-one mb-5 flex gap-3 ">
                <div>
                    <img src="../../../images/ziaumer.png" alt="user.name" class="rounded-full w-12 " />
                </div>
                <div>
                    <p class="text-[#607D8B] mb-5 gap-6 font-[500] text-[15px]">
                        <span>muatex</span><span class="mx-4 text-[11px]">11/16/2021 8:14 AM</span>
                    </p>
                    <div class="bg-gray-300 rounded-lg p-4 xs:p-6 border-l-4 border-blue">
                        Hope you're doing great—just wanted to check in and say hello!"
                    </div>
                </div>
            </div>
            <div class="person-two mb-5 flex gap-3">
                <div>
                    <img src="../../../images/Frame.png" alt="user.name" class="rounded-full w-12 " />
                </div>
                <div>
                    <p class="text-[#607D8B] mb-5 gap-6 font-[500] text-[15px]">
                        <span>Figgy</span><span class="mx-4 text-[11px]">11/16/2021 8:14 AM</span>
                    </p>
                    <div class="bg-gray-300 rounded-lg p-4 xs:p-6 border-l-4 border-blue">
                        Hey! Great to hear from you. I'm doing well—how about you?
                    </div>
                </div>
            </div>
            <div class="person-one mb-5 flex gap-3 ">
                <div>
                    <img src="../../../images/ziaumer.png" alt="user.name" class="rounded-full w-12 " />
                </div>
                <div>
                    <p class="text-[#607D8B] mb-5 gap-6 font-[500] text-[15px]">
                        <span>muatex</span><span class="mx-4 text-[11px]">11/16/2021 8:14 AM</span>
                    </p>
                    <div class="bg-gray-300 rounded-lg p-4 xs:p-6 border-l-4 border-blue">
                        Hope you're doing great—just wanted to check in and say hello!"
                    </div>
                </div>
            </div>
            <div class="person-two mb-5 flex gap-3">
                <div>
                    <img src="../../../images/Frame.png" alt="user.name" class="rounded-full w-12 " />
                </div>
                <div>
                    <p class="text-[#607D8B] mb-5 gap-6 font-[500] text-[15px]">
                        <span>Figgy</span><span class="mx-4 text-[11px]">11/16/2021 8:14 AM</span>
                    </p>
                    <div class="bg-gray-300 rounded-lg p-4 xs:p-6 border-l-4 border-blue">
                        Hey! Great to hear from you. I'm doing well—how about you?
                    </div>
                </div>
            </div>
            <div class="person-one mb-5 flex gap-3 ">
                <div>
                    <img src="../../../images/ziaumer.png" alt="user.name" class="rounded-full w-12 " />
                </div>
                <div>
                    <p class="text-[#607D8B] mb-5 gap-6 font-[500] text-[15px]">
                        <span>muatex</span><span class="mx-4 text-[11px]">11/16/2021 8:14 AM</span>
                    </p>
                    <div class="bg-gray-300 rounded-lg p-4 xs:p-6 border-l-4 border-blue">
                        Hope you're doing great—just wanted to check in and say hello!"
                    </div>
                </div>
            </div>
            <div class="person-two mb-5 flex gap-3">
                <div>
                    <img src="../../../images/Frame.png" alt="user.name" class="rounded-full w-12 " />
                </div>
                <div>
                    <p class="text-[#607D8B] mb-5 gap-6 font-[500] text-[15px]">
                        <span>Figgy</span><span class="mx-4 text-[11px]">11/16/2021 8:14 AM</span>
                    </p>
                    <div class="bg-gray-300 rounded-lg p-4 xs:p-6 border-l-4 border-blue">
                        Hey! Great to hear from you. I'm doing well—how about you?
                    </div>
                </div>
            </div>
            
        </div>
        <div class="message-field flex gap-3 p-2 pb-3">
            <div class="bg-gray-300 rounded-full flex justify-center items-center w-12">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                    class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                    <path
                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                </svg>
            </div>
            <div class="flex w-full overflow-hidden px-4 rounded-full items-center bg-gray-300">
                <input type="text" placeholder="Message #Group name" class="p-2 w-full focus:outline-none bg-gray-300">
                <div class="flex gap-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                        class="bi bi-person-video" viewBox="0 0 16 16">
                        <path d="M8 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
                        <path
                            d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm10.798 11c-.453-1.27-1.76-3-4.798-3-3.037 0-4.345 1.73-4.798 3H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1z" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                        class="bi bi-emoji-smile-fill" viewBox="0 0 16 16">
                        <path
                            d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5M4.285 9.567a.5.5 0 0 1 .683.183A3.5 3.5 0 0 0 8 11.5a3.5 3.5 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683M10 8c-.552 0-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5S10.552 8 10 8" />
                    </svg>
                </div>
            </div>
        </div>
    </section>
</template>
<script>


export default {
    name: 'SingleChatScreen',

};
</script>