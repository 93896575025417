<template>
  <HomeHeaders />

  <section>
    <div v-if="loading" class="loading-indicator">
      <div class="loader"></div>
    </div>
    <div v-else class="bg-contain px-6 pb-12  font-inter" :style="{
      backgroundImage: `url(${require('../../images/radial-shadow.png')})`,
      // backgroundSize: 'auto',
      // clipPath: 'ellipse(90% 100% at center top)'
    }">
      <h2 class="text-5xl font-semibold text-center text-blue p-16">
        Journal
      </h2>
      <!-- Journal buttons -->
      <div class="my-6  grid grid-cols-1 sm:flex sm:justify-start gap-3 flex-wrap">
        <!-- <button
                        class="bg-white py-5 rounded-tl-lg rounded-tr-lg hover:border hover:border-blue text-[20px] mb-4 text-[#C4C4C4] font-bold px-2 sm:px-12">
                        <span class="icon-stats text-2xl mr-3 "></span>
                        <span>Game system</span>
                    </button>
                    <button
                        class="bg-white py-5 rounded-tl-lg rounded-tr-lg hover:border hover:border-blue text-[20px] mb-4 text-[#C4C4C4] font-bold  px-2 sm:px-12">
                        <span class="icon-stats text-2xl mr-3 "></span>
                        <span>Journey system</span>
                    </button> -->
        <!-- <button
          class="bg-blue py-5 text-[20px] flex gap-3 items-center rounded-2xl mb-4 text-white font-bold px-2 sm:px-12">
          <span class="icon-stats text-2xl mr-3"></span>
          <span>Discovery system</span>
        </button> -->
        <!-- <button
                        class="bg-white py-5 text-[20px] rounded-tl-lg hover:border hover:border-blue rounded-tr-lg mb-4 text-[#C4C4C4] font-bold  px-2 sm:px-12">
                        <span class="icon-stats text-2xl mr-3 "></span>
                        <span>World system</span>
                    </button>
                    <button
                        class="bg-white py-5  text-[20px] rounded-tl-lg hover:border hover:border-blue rounded-tr-lg mb-4 text-[#C4C4C4] font-bold  px-2 sm:px-12">
                        <span class="icon-stats text-2xl mr-3 "></span>
                        <span>Community system</span>
                    </button> -->
      </div>
      <div class="font-bold text-xl sm:text-2xl my-14 ">
        View and manage your saved reviews and quotes for each media item below:
      </div>

      <div
        class="grid xxl:grid-cols-6 lg:grid-cols-5 md:grid-cols-4  min-[550px]:grid-cols-3 min-[380px]:grid-cols-2 grid-cols-1 sm:gap-4 max-[640px]:gap-y-4 mb-10">
        <template v-if="journal_media_data.result.results.length !== 0">
          <template v-for="(item, index) in journal_media_data.result.results" :key="index">
            <DefaultCard :card_data="item" />
            <!-- <JournalCard :card_data="item" /> -->
          </template>
        </template>
        <template v-else>
          <div class="text-2xl font-semibold py-4  text-gray-500">
            No Data Available
          </div> 
         </template>
      </div>
      <!-- Journal End -->
    </div>
    <!-- <div class="recently-bottom-border border-b-1 border-borderColor mt-20 mb-4 mx-20"></div> -->
  </section>

  <HomeFooter />
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import HomeHeaders from '@/common_components/header/header.vue';
import HomeFooter from '@/common_components/footer/footer.vue';
// import JournalCard from '@/common_components/card/journal_card_component.vue';
import DefaultCard from '@/common_components/card/default_journal_card.vue';
// import { all_media_type_values } from '@/common_variables/media_type.js';

export default {
  name: 'JournalScreen',
  components: {
    HomeHeaders,
    HomeFooter,
    DefaultCard,
    // JournalCard,
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapState({
      journal_media_data: (state) => state.journal_store.journal_media_data,
    }),
  },

  async created() {
    this.set_selected_tab('identify');
    await this.fetchDataFromApis();
  },

  mounted() { },

  methods: {
    ...mapActions('journal_store', ['journal_media_api']),
    ...mapMutations('journal_store', ['set_selected_tab']),

    async fetchDataFromApis() {
      this.loading = true;
      try {
        await Promise.all([this.journal_media_api()]);
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        // this.selected_favorite_data = this.favorite_data.result.results[0];
        this.loading = false;
      }
    },
  },
};
</script>

<style>
@import '../../css/style.css';
@import '../../css/discovery.css';
</style>
