<template>
  <div class="w-full bg-white shadow-gray-400 shadow-[rgba(0,0,0,0.2)_0px_0px_5px_0px]">
    <HomeHeader />
  </div>
  <div v-if="loading" class="loading-indicator">
    <div class="loader"></div>
  </div>

  <div v-else class="w-full mx-auto bg-contain font-inter py-8 sm:p-8" :style="{
    backgroundImage: `url(${require('../../images/form-shadow.png')})`,
    // backgroundSize: 'auto',
    // clipPath: 'ellipse(90% 100% at center top)'
  }">
    <button
      class="text-blue xs:px-6 xs:py-3 bg-white px-4 py-2 sm:text-lg font-medium border-1 border-blue rounded-2xl hover:text-white hover:bg-blue duration-500"
      @click="goBack">
      Back
    </button>
    <div class="relative flex justify-center items-center">
      <div class="relative mx-auto px-3 sm:px-5 py-5 sm:p-10 bg-white rounded-2xl my-6 w-full sm:w-[900px]">
        <h2 class="text-xl sm:text-2xl md:text-3xl font-bold text-center">
          User Create Form
        </h2>
        <p class="text-xs xs:text-sm sm:text-base text-[#252525] text-center opacity-60 my-5">
          Any new data you add will only be visible to you until it is reviewed and
          approved by our curator team. This ensures the quality and accuracy of
          information on our platform.
        </p>
        <div class="mx-auto pb-2 my-8 max-[1023px]:px-5">
          <div class="flex justify-between items-center mb-6 max-[1023px]:mb-3">
            <div class=" text-xl font-semibold">
              Earned XP: <span class="text-blue">{{ currentXP }}</span>
            </div>
            <div class="xs:text-xl  sm:text-2xl  font-semibold text-blue">
              XP Points
            </div>
            <div class="text-xl font-semibold">
              Total XP: <span class="text-blue">{{ totalPossibleXP }}</span>
            </div>
          </div>

          <div class="relative bg-[#d6dcf4] mb-3 h-6 w-full rounded-full overflow-hidden">
            <div class="absolute h-full bg-blue rounded-full transition-all duration-300 ease-in-out"
              :style="{ width: progressPercentage + '%' }"></div>
          </div>
        </div>
        <h2 class="md:text-3xl sm:text-2xl text-xl font-bold mb-10 text-center text-blue">
          {{ media_name }}
        </h2>

        <form>
          <div class="w-full mb-10 relative">
            <span
              class="absolute top-[63px] right-[30px] max-[640px]:top-[52px] text-[#ADB5BD] icon-updownarrow pointer-events-none"></span>
            <div class="flex justify-between">
              <label for="dataType" class="font-semibold ml-2 sm:text-xl">Data Type<span
                  class="text-red-500">*</span></label>
              <!-- <p class="mr-3 text-xl italic">{{ xp_points.media_type.xp_points_display }}</p> -->
            </div>
            <select @change="selected_media_type_func($event)"
              class="border-1 border-borderColor rounded-2xl text-[#53585c] text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2 appearance-none"
              id="dataType">
              <option v-for="(value, key, index) in entity_list.result" :key="index" :value="key">
                {{ value }}
              </option>
            </select>
          </div>
          <div class="w-full mb-9">
            <div class="flex justify-between">
              <label for="name" class="font-semibold ml-2 sm:text-xl">Name<span class="text-red-500">*</span></label>
              <p class="mr-3 sm:text-xl italic">{{ xp_points.name.xp_points_display }}</p>
            </div>
            <input v-model="name" type="text"
              class="border-1 border-borderColor rounded-2xl text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"
              id="name" placeholder="" />
          </div>

          <div v-if="
            selected_media_type ===
            all_entity_values['media_credit']['entity_type_key']
          "
            class="flex flex-col border-1 border-borderColor rounded-2xl px-8 py-6 max-[654px]:px-3 max-[654px]:py-3 mb-10">
            <div class="flex justify-between ">
              <h3 class="font-semibold ml-2 sm:text-xl mb-3">Role</h3>
              <p class="mr-3 sm:text-xl italic">{{ xp_points.roles.xp_points_display }}</p>
            </div>
            <div class="flex relative mb-5">
              <input v-model="searchRoles" type="text"
                class="border-1 border-borderColor rounded-full w-full text-base text-[#ADB5BD] pl-6 py-3"
                placeholder="Search Role..." />
              <div class="absolute top-0 right-0"></div>
            </div>
            <div class="h-36 overflow-x-auto">
              <div v-for="(value, index) in filteredRoles" :key="index"
                class="px-5 py-2 mb-1 rounded-full hover:bg-badgeBG">
                <label class="flex items-center">
                  <input @change="selected_role_func(value)" type="radio" name="role" :value="value"
                    class="w-5 h-5 mr-3" :checked="selected_role === value" />
                  <span>{{ value }}</span>
                </label>
              </div>
            </div>
          </div>

          <div v-if="
            selected_media_type ===
            all_entity_values['activity']['entity_type_key']
          " class="w-full mb-9">
            <div class="flex justify-between">
              <label for="participants" class="font-semibold ml-2 sm:text-xl">Number of
                participants<span class="text-red-500">*</span></label>
              <p class="mr-3 sm:text-xl italic">{{ xp_points.no_of_participants.xp_points_display }}</p>
            </div>
            <input v-model="no_of_participants" type="number"
              class="border-1 border-borderColor rounded-2xl text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"
              id="participants" placeholder="" />
          </div>

          <div class="w-full mb-9">
            <label for="link" class="font-semibold ml-2 sm:text-xl">
              {{ selected_media_type === all_entity_values['purchase_oppurtunity']['entity_type_key'] ? 'Purchase Link' : 'Description Url' }}
            </label>
            <div class="flex mt-2 gap-2 border-1 border-borderColor rounded-2xl overflow-hidden">
              <input 
                v-model="link" 
                type="text"
                class="text-xl max-[640px]:text-lg font-light w-full focus:outline-none px-5 py-4 max-[640px]:px-4 max-[640px]:py-2"
                id="link" 
                placeholder="" 
              />
              <button 
                v-if="selected_media_type !== all_entity_values['purchase_oppurtunity']['entity_type_key']"
                type="button"
                @click="fetchDescription" 
                class="px-4 py-2 bg-blue text-white w-60 transition-colors duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
                :disabled="!link || isFetching"
              >
                {{ isFetching ? 'Fetching...' : 'Fetch Description' }}
              </button>
            </div>
          </div>

          <div class="w-full mb-9">
            <div class="flex justify-between">
              <label for="description" class="font-semibold ml-2 sm:text-xl">Description
              </label>
              <p class="mr-3 sm:text-xl italic">{{ xp_points.description.xp_points_display }}</p>
            </div>
            <textarea v-model="description" id="description" rows="5" cols="30"
              class="border-1 border-borderColor rounded-2xl text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"></textarea>
          </div>

          <div class="w-full mb-9" v-if="selected_media_type !== all_entity_values['media_credit']['entity_type_key']">
            <div class="flex justify-between">
              <label for="title_specific_description" class="font-semibold ml-2 sm:text-xl">Title Specific Description
                <span class="text-red-500"></span>
              </label>
              <p class="mr-3 sm:text-xl italic">{{ xp_points.title_specific_description?.xp_points_display || '' }}</p>
            </div>
            <textarea v-model="title_specific_description" id="title_specific_description" rows="5" cols="30"
              class="border-1 border-borderColor rounded-2xl text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"></textarea>
          </div>

          <!-- <div v-if="
            selected_media_type ===
            all_entity_values['media_credit']['entity_type_key']
          " class="w-full mb-9">
            <div class="flex justify-between">
              <label for="summary_description" class="font-semibold ml-2 sm:text-xl">Summary
                Description<span class="text-red-500">*</span></label>
              <p class="mr-3 sm:text-xl italic">{{ xp_points.summary_description.xp_points_display }}</p>
            </div>
            <textarea v-model="summary_description" id="summary_description" rows="5" cols="30"
              class="border-1 border-borderColor rounded-2xl text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"></textarea>
          </div> -->

          <!-- custom upload file -->
          <div class="flex flex-col  items-start justify-center w-full mb-10">
            <div class="">
              <div class=" flex justify-between mb-4 w-full xs:w-60 ">
                <p class="font-semibold ml-2 sm:text-xl">Image Upload</p>
                <p class="mr-3 sm:text-xl italic">{{ xp_points.image_upload.xp_points_display }}</p>
              </div>
              <!-- Image Preview Section -->
              <div class="relative w-full xs:w-52 h-52 flex items-center justify-center  rounded-2xl bg-gray-50">
                <template v-if="!show_avatar">
                  <span class="text-gray-300">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50"
                      class="bi bi-person-bounding-box text-gray-300" viewBox="0 0 16 16">
                      <path
                        d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5M.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5" />
                      <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                    </svg>
                  </span>
                </template>
                <template v-else>
                  <img :src="show_avatar" alt="Preview"
                    class="w-full h-full object-cover border border-gray-300 rounded-lg"
                    style="object-fit: contain; object-position: center" />
                  <span @click="deleteAvatar"
                    class="absolute bg-gray-300 top-2 right-2 text-red-600 p-3 rounded-xl cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                      class="bi bi-trash-fill" viewBox="0 0 16 16">
                      <path
                        d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0" />
                    </svg>
                  </span>
                </template>
              </div>

              <!-- Upload Image Section -->
              <label for="dropzone-file"
                class="flex justify-center items-center gap-2 px-6 w-full xs:w-52 py-4 my-4 text-blue rounded-lg cursor-pointer bg-gray-100 hover:bg-gray-200">
                <svg class="w-6 h-6 text-blue-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                  viewBox="0 0 20 16">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                </svg>
                <span class="text-blue-600 font-medium">Add an image</span>
                <input id="dropzone-file" type="file" class="hidden" @change="handleAvatarUpload"
                  accept=".png, .jpg, .jpeg" />
              </label>
            </div>
          </div>

          <div class="flex justify-between items-end gap-10 flex-wrap my-10">
            <template v-if="!not_o_source_type.includes(selected_media_type)">
              <div v-if="!not_o_type.includes(selected_media_type)" class="flex w-full flex-col">
                <div class=" flex justify-between items-center mb-4">
                  <p class="font-semibold ml-2 sm:text-xl">Fiction or Non-fiction?</p>
                  <p class="mr-3 text-center  sm:text-xl italic">{{ xp_points.fiction.xp_points_display }}</p>
                </div>
                <div class="flex items-center max-[654px]:justify-between w-72 ">
                  <label class="relative cursor-pointer rounded-2xl overflow-hidden border border-borderColor w-full">
                    <input v-model="real_fiction" type="checkbox" class="sr-only peer" />
                    <div class="overflow-hidden font-semibold flex items-center bg-white">
                      <p class="p-3 w-full text-center" :class="{ 'bg-blue text-white': !real_fiction }">Non-Fiction</p>
                      <p class="p-3 w-full text-center" :class="{ 'bg-blue text-white': real_fiction }">Fiction</p>
                    </div>
                  </label>
                </div>
              </div>
              <template v-if="
                selected_media_type === all_entity_values['place']['entity_type_key']
              ">
                <div class="w-full mb-9">
                  <div class="flex justify-between">
                    <label for="google_map_link" class="font-semibold ml-2 sm:text-xl">Google Map
                      Link<span v-if="!real_fiction" class="text-red-500">*</span></label>
                    <p class="mr-3 sm:text-xl italic">{{ xp_points.google_map_link.xp_points_display }}</p>
                  </div>
                  <input v-model="google_map_link" type="text"
                    class="border-1 border-borderColor rounded-2xl text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"
                    id="google_map_link" placeholder="" />
                </div>
                <div class="w-full mb-9">
                  <div class="flex justify-between">
                    <label for="google_embed_iframe" class="font-semibold ml-2 sm:text-xl">Google
                      Embed Iframe<span v-if="!real_fiction" class="text-red-500">*</span></label>
                    <p class="mr-3 sm:text-xl italic">{{ xp_points.google_iframe.xp_points_display }}</p>
                  </div>
                  <input v-model="google_embed_iframe" type="text"
                    class="border-1 border-borderColor rounded-2xl text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"
                    id="google_embed_iframe" placeholder="" />
                </div>
              </template>
              <div class="flex  flex-col w-full ">
                <div class="flex justify-between items-center mb-4">
                  <p  class="font-semibold ml-2 sm:text-xl">Is this {{all_entity_values[selected_media_type]['singular']}} mentioned in this media or native
                  to this media? </p>
                <p class="mr-3 text-center  sm:text-xl italic">{{ xp_points.native.xp_points_display }}</p>
                </div>
                <div class="flex  w-72 items-center max-[654px]:justify-between max-[654px]:my-3">
                  <label class="relative cursor-pointer rounded-2xl overflow-hidden border border-borderColor w-full">
                    <input v-model="native_mentioned" type="checkbox" class="sr-only peer" />
                    <div class="overflow-hidden  font-semibold flex items-center bg-white">
                      <p class="p-3  w-full text-center" :class="{ 'bg-blue text-white': !native_mentioned }">Native
                      </p>
                      <p class="p-3  w-full text-center" :class="{ 'bg-blue text-white': native_mentioned }">Mentioned
                      </p>
                    </div>
                  </label>
                </div>
              </div>
            </template>
            <!-- <div class="flex  w-60 flex-col">
              <p class="mr-3 text-center mb-2 sm:text-xl italic">{{ xp_points.curators.xp_points_display }}</p>
              <p class="mr-4 text-sm text-center font-medium text-gray-900 dark:text-gray-300">
                Submit to Curators
              </p>
              <div class="flex items-center max-[654px]:justify-between">
                <label class="relative cursor-pointer rounded-2xl overflow-hidden border border-borderColor w-full">
                  <input v-model="submit_curator" type="checkbox" class="sr-only peer" />
                  <div class="overflow-hidden font-semibold flex items-center bg-white">
                    <p class="p-3 w-full text-center" :class="{ 'bg-blue text-white': !submit_curator }">No</p>
                    <p class="p-3 w-full text-center" :class="{ 'bg-blue text-white': submit_curator }">Yes</p>
                  </div>
                </label>
              </div>
            </div> -->
          </div>
          <!-- <p class="text-sm text-[#8f8f8f] text-center my-7">
            Submitting spam, offensive content, or any other unwanted data to the
            curators for review may result in action taken against your account,
            including but not limited to temporary or permanent suspension of
            access.
          </p> -->

          <div v-if="
            selected_media_type ===
            all_entity_values['character']['entity_type_key']
          "
            class="flex flex-col border-1 border-borderColor rounded-3xl px-8 py-6 mb-10 max-[654px]:px-3 max-[654px]:py-3">
            <div class="flex justify-between ">
              <h3 class="font-semibold ml-2 sm:text-xl mb-3">Character Traits</h3>
              <p class="mr-3 sm:text-xl italic">{{ xp_points.character_traits.xp_points_display }}</p>
            </div>
            <div class="flex relative mb-5">
              <input v-model="searchTraits" type="text"
                class="border-1 border-borderColor rounded-2xl w-full text-base text-[#ADB5BD] pl-6 py-3"
                placeholder="Search Traits..." />
              <div class="absolute top-0 right-0"></div>
            </div>
            <div class="h-36 overflow-x-auto">
              <div v-for="(value, index) in filteredTraits" :key="index"
                class="px-5 py-2 mb-1 rounded-full hover:bg-badgeBG">
                <label class="flex items-center">
                  <input @click="selected_char_trait_func(value['id'])" type="checkbox" :id="'trait-' + index"
                    class="w-5 h-5 mr-3" />
                  <span>{{ value['label'] }}</span>
                </label>
              </div>
            </div>
          </div>

          <!-- <div v-if="
            selected_media_type ===
            all_entity_values['media_credit']['entity_type_key']
          "
            class="flex flex-col border-1 border-borderColor rounded-2xl px-8 py-6 max-[654px]:px-3 max-[654px]:py-3 mb-10">
            <div class="flex justify-between ">
              <h3 class="font-semibold ml-2 sm:text-xl mb-3">Role</h3>
              <p class="mr-3 sm:text-xl italic">{{ xp_points.roles.xp_points_display }}</p>
            </div>
            <div class="flex relative mb-5">
              <input v-model="searchRoles" type="text"
                class="border-1 border-borderColor rounded-full w-full text-base text-[#ADB5BD] pl-6 py-3"
                placeholder="Search Role..." />
              <div class="absolute top-0 right-0"></div>
            </div>
            <div class="h-36 overflow-x-auto">
              <div v-for="(value, index) in filteredRoles" :key="index"
                class="px-5 py-2 mb-1 rounded-full hover:bg-badgeBG">
                <label class="flex items-center">
                  <input @change="selected_role_func(value)" type="radio" name="role" :value="value"
                    class="w-5 h-5 mr-3" :checked="selected_role === value" />
                  <span>{{ value }}</span>
                </label>
              </div>
            </div>
          </div> -->

          <div v-if="
            selected_media_type !==
            all_entity_values['activity']['entity_type_key'] &&
            selected_media_type !==
            all_entity_values['purchase_oppurtunity']['entity_type_key']
          " class="w-full mb-9">
            <div class="flex justify-between">
              <label for="additional_resources" class="font-semibold ml-2 sm:text-xl">Additional Online
                Resources</label>
              <p class="mr-3 sm:text-xl italic">{{ xp_points.add_resources.xp_points_display }}</p>
            </div>
            <div v-for="(resource, index) in additionalResources" :key="index" class="relative flex items-center mt-2">
              <input type="text"
                class="border-1 border-borderColor rounded-2xl text-xl max-[640px]:text-lg font-light w-full px-5 py-4 max-[640px]:px-4 max-[640px]:py-2"
                :placeholder="'Resource ' + (index + 1)" v-model="additionalResources[index]" />
              <!-- Remove button -->
              <button type="button" @click="removeResource(index)" class="absolute right-2 text-red-500 text-2xl ml-3">
                -
              </button>
            </div>
            <button type="button" @click="addResource" class="text-blue-500 sm:text-xl mt-2 flex items-center">
              <span class="mr-1">+</span> Add Resource
            </button>
          </div>
        </form>
        <div class="flex flex-col w-full">
          <div class="flex mb-4 gap-10 justify-between w-full">
            <p class="font-semibold ml-2 sm:text-xl">
              Submit to Curators
            </p>
            <p class="mr-3 text-center sm:text-xl italic">{{ xp_points.curators.xp_points_display }}</p>
          </div>
          <div class="flex  w-72 flex-col">
            <div class="flex items-center max-[654px]:justify-between">
              <label class="relative cursor-pointer rounded-2xl overflow-hidden border border-borderColor w-full">
                <input v-model="submit_curator" type="checkbox" class="sr-only peer" />
                <div class="overflow-hidden font-semibold flex items-center bg-white">
                  <p class="p-3 w-full text-center" :class="{ 'bg-blue text-white': !submit_curator }">No</p>
                  <p class="p-3 w-full text-center" :class="{ 'bg-blue text-white': submit_curator }">Yes</p>
                </div>
              </label>
            </div>
          </div>
        </div>

        <div class="flex justify-center mt-10">
          <button @click.prevent="submit_form()"
            class="text-xl text-white font-bold text-center border-2 border-blue bg-blue py-3 px-6 rounded-2xl hover:border-blue hover:border-1 hover:bg-white hover:text-blue duration-500">
            Submit
          </button>
        </div>
        <p class=" text-gray-500 font-semibold text-center my-7">
          Submitting spam, offensive content, or any other unwanted data to the
          curators for review may result in action taken against your account,
          including but not limited to temporary or permanent suspension of
          access.
        </p>
      </div>
    </div>
  </div>
  <!-- Footer Start -->
  <HomeFooter />
  <!-- Footer End -->
</template>

<script>
import HomeHeader from '@/common_components/header/header.vue';
import HomeFooter from '@/common_components/footer/footer.vue';
import { media_page_navigation } from '@/common_components/common_routing/media_page_routing';
import { mapState, mapActions, mapMutations } from 'vuex';
import { all_entity_type_values } from '@/common_variables/entity_type';
import { xp_points } from '@/common_variables/xp_points';
import axios from 'axios';
import { APIs } from '@/apis/apis_collection';

export default {
  name: 'AddMissingInfo',
  components: {
    HomeHeader,
    HomeFooter,
  },

  async created() {
    await this.fetchDataFromApis();
  },

  computed: {

    totalPossibleXP() {
      let total = 0;

      // Base XP for required fields
      total += this.xp_points.name.xp_points;
      total += this.xp_points.media_type.xp_points;

      // Description XP
      if (!this.not_description.includes(this.selected_media_type)) {
        total += this.xp_points.description.xp_points;
      }

      // Activity specific XP
      if (this.selected_media_type === this.all_entity_values['activity']['entity_type_key']) {
        total += this.xp_points.no_of_participants.xp_points;
      }

      // Place specific XP
      if (this.selected_media_type === this.all_entity_values['place']['entity_type_key']) {
        total += this.xp_points.google_map_link.xp_points;
        total += this.xp_points.google_iframe.xp_points;
      }

      // Media credit specific XP
      if (this.selected_media_type === this.all_entity_values['media_credit']['entity_type_key']) {
        total += this.xp_points.summary_description.xp_points;
        total += this.xp_points.roles.xp_points;
      }

      // Character specific XP
      if (this.selected_media_type === this.all_entity_values['character']['entity_type_key']) {
        total += this.xp_points.character_traits.xp_points;
      }

      // Additional XP opportunities
      total += this.xp_points.image_upload.xp_points;
      total += this.xp_points.add_resources.xp_points;

      if (!this.not_o_source_type.includes(this.selected_media_type)) {
        total += this.xp_points.fiction.xp_points;
        total += this.xp_points.native.xp_points;
      }

      total += this.xp_points.curators.xp_points;

      return total;
    },
    currentXP() {
      let total = 0;

      // Add XP for filled fields
      if (this.name.trim()) {
        total += this.xp_points.name.xp_points;
      }

      if (this.selected_media_type) {
        total += this.xp_points.media_type.xp_points;
      }

      if (!this.not_description.includes(this.selected_media_type) && this.description.trim()) {
        total += this.xp_points.description.xp_points;
      }

      if (this.selected_media_type === this.all_entity_values['activity']['entity_type_key'] &&
        this.no_of_participants) {
        total += this.xp_points.no_of_participants.xp_points;
      }

      if (this.selected_media_type === this.all_entity_values['place']['entity_type_key']) {
        if (this.google_map_link.trim()) {
          total += this.xp_points.google_map_link.xp_points;
        }
        if (this.google_embed_iframe.trim()) {
          total += this.xp_points.google_iframe.xp_points;
        }
      }

      if (this.selected_media_type === this.all_entity_values['media_credit']['entity_type_key']) {
        if (this.summary_description.trim()) {
          total += this.xp_points.summary_description.xp_points;
        }
        if (this.selected_role) {
          total += this.xp_points.roles.xp_points;
        }
      }

      if (this.selected_media_type === this.all_entity_values['character']['entity_type_key'] &&
        Object.keys(this.selected_char_trait).length > 0) {
        total += this.xp_points.character_traits.xp_points;
      }

      if (this.image) {
        total += this.xp_points.image_upload.xp_points;
      }

      if (this.additionalResources.length > 0 && this.additionalResources[0].trim()) {
        total += this.xp_points.add_resources.xp_points;
      }

      if (!this.not_o_source_type.includes(this.selected_media_type)) {
        if (this.real_fiction) {
          total += this.xp_points.fiction.xp_points;
        }
        if (this.native_mentioned) {
          total += this.xp_points.native.xp_points;
        }
      }

      if (this.submit_curator) {
        total += this.xp_points.curators.xp_points;
      }

      return total;
    },

    progressPercentage() {
      return Math.min((this.currentXP / this.totalPossibleXP) * 100, 100);
    },



    ...mapState({
      char_traits: (state) => state.add_missing_info_store.char_traits,
      entity_list: (state) => state.add_missing_info_store.entity_list,
      role: (state) => state.add_missing_info_store.role,
      filteredTraits() {
        if (this.searchTraits) {
          return this.char_traits.result.filter((trait) =>
            trait.label.toLowerCase().includes(this.searchTraits.toLowerCase()),
          );
        }
        return this.char_traits.result;
      },
      filteredRoles() {
        let rolesArray = Object.values(this.role.result);
        if (this.searchRoles) {
          return rolesArray.filter((role) =>
            role.toLowerCase().includes(this.searchRoles.toLowerCase()),
          );
        }
        return rolesArray;
      },

      all_entity_values() {
        return all_entity_type_values;
      },
      // selected_media_type(){
      //   return all_entity_type_values['character']['entity_type_key']
      // },
      // selected_media(){
      //   return all_entity_type_values['character']['singular']
      // }
    }),
  },
  data() {
    return {
      loading: false,
      selected_char_trait: {},
      selected_role: null,
      searchTraits: '',
      searchRoles: '',
      name: '',
      google_embed_iframe: '',
      google_map_link: '',
      media_name: this.$route.query.media_name,
      media_type: this.$route.query.media_type,
      applicable_formats: this.$route.query.media_type,
      description: '',
      summary_description: '',
      title_specific_description: '',
      link: '',
      no_of_participants: '',
      real_fiction: false,
      native_mentioned: false,
      submit_curator: true,
      show_avatar: null,
      selected_media_type:
        all_entity_type_values['character']['entity_type_key'],
      selected_media: all_entity_type_values['character']['singular'],
      image: '',
      not_o_source_type: [
        'theme',
        'activity',
        'career_interest',
        'purchase_oppurtunity',
        'media_credit',
      ],
      not_o_type: ['time_period', 'genre', 'topic'],
      not_link: ['theme', 'genre', 'time_period', 'activity'],
      not_description: ['purchase_oppurtunity'],
      additionalResources: [],
      xp_points: xp_points,
      currentProgress: 0,
      totalXP: 0,
      isFetching: false,
    };
  },
  methods: {
    ...mapActions('add_missing_info_store', [
      'get_char_traits_api',
      'get_role_api',
      'get_entity_list_api',
      'add_entity_form_api',
    ]),

    ...mapMutations('home_store', ['set_selected_tab']),

    // openAvatarUpload() {
    //   document.getElementById('avatarUpload').click();
    // },

    calculateTotalXP() {
      let total = 0;

      // Add base XP for required fields
      total += this.name ? this.xp_points.name : 0;
      total += this.selected_media_type ? this.xp_points.data_type : 0;

      // Add XP for description if applicable
      if (!this.not_description.includes(this.selected_media_type) && this.description) {
        total += this.xp_points.description;
      }

      // Add XP for participants if activity
      if (this.selected_media_type === this.all_entity_values['activity']['entity_type_key'] && this.no_of_participants) {
        total += this.xp_points.participants;
      }

      // Add XP for place-specific fields
      if (this.selected_media_type === this.all_entity_values['place']['entity_type_key']) {
        if (this.google_map_link) total += this.xp_points.google_map;
        if (this.google_embed_iframe) total += this.xp_points.google_embed;
      }

      // Add XP for media credit fields
      if (this.selected_media_type === this.all_entity_values['media_credit']['entity_type_key']) {
        if (this.summary_description) total += this.xp_points.summary;
        if (this.selected_role) total += this.xp_points.role;
      }

      // Add XP for character traits
      if (this.selected_media_type === this.all_entity_values['character']['entity_type_key'] &&
        Object.keys(this.selected_char_trait).length > 0) {
        total += this.xp_points.traits;
      }

      // Add XP for image
      if (this.image) {
        total += this.xp_points.image;
      }

      // Add XP for additional resources
      if (this.additionalResources.length > 0 && this.additionalResources[0].trim()) {
        total += this.xp_points.additional_resources;
      }

      return total;
    },

    addResource() {
      this.additionalResources.push('');
    },
    removeResource(index) {
      this.additionalResources.splice(index, 1);
    },

    handleAvatarUpload(event) {
      const file = event.target.files[0];
      if (file.size > 1048576) {
        this.$toast.open({
          message: 'Profile picture must be less than 1 MB',
          type: 'error',
          duration: 1000 * 5,
          dismissible: true,
        });
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        this.show_avatar = reader.result;
        this.image = reader.result;
      };
      reader.readAsDataURL(file);
    },

    deleteAvatar() {
      this.image = '';
      this.show_avatar = null;
    },

    isValidURL(url) {
      try {
        // Use the built in JS  URL constructor to validate
        new URL(url);
        return true;
      } catch (e) {
        // If URL constructor throws an error, the URL is invalid
        return false;
      }
    },

    // isValidURL(url) {
    //   const pattern = new RegExp(
    //     '^(https?:\\/\\/)?' + // protocol
    //     '((([a-zA-Z0-9\\-\\_\\.]+)\\.([a-zA-Z]{2,}))|' + // domain name
    //     'localhost|' + // localhost
    //     '\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}|' + // IPv4
    //     '\\[([a-fA-F0-9:]+)\\])' + // IPv6
    //     '(\\:\\d+)?(\\/[-a-zA-Z0-9@:%_\\+.~#?&//=]*)?' + // port and path
    //     '(\\?[;&a-zA-Z0-9%_\\+.~#?&//=]*)?' + // query string
    //     '(\\#[-a-zA-Z0-9_]*)?$',
    //     'i',
    //   ); // fragment locator
    //   return pattern.test(url);
    // },

    validateInputs() {
      const errors = [];
      if (!this.name.trim()) {
        errors.push('Name cannot be empty.');
      }
      // if (
      //   !this.description.trim() &&
      //   !this.not_description.includes(this.selected_media_type)
      // ) {
      //   errors.push('Description cannot be empty.');
      // }
      // if (
      //   !this.summary_description.trim() &&
      //   this.selected_media_type ===
      //   all_entity_type_values['media_credit']['entity_type_key']
      // ) {
      //   errors.push('Summary Description cannot be empty.');
      // }
      if (
        this.no_of_participants === '' &&
        this.selected_media_type ===
        all_entity_type_values['activity']['entity_type_key']
      ) {
        errors.push('Number of participants cannot be empty.');
      } else if (parseInt(this.no_of_participants, 10) < 0) {
        errors.push('Number of participants cannot be negative.');
      }

      if (
        this.link.trim() &&
        !this.isValidURL(this.link) &&
        !this.not_link.includes(this.selected_media_type)
      ) {
        errors.push('Link must be a valid URL.');
      }
      if (
        Object.keys(this.selected_char_trait).length === 0 &&
        this.selected_media_type ===
        all_entity_type_values['character']['entity_type_key']
      ) {
        errors.push('Atleast select one Character Trait');
      }
      if (
        this.selected_media_type ===
        all_entity_type_values['media_credit']['entity_type_key'] &&
        this.selected_role === null
      ) {
        errors.push('Select Role');
      }
      if (
        this.selected_media_type === all_entity_type_values['place']['entity_type_key']
      ) {
        // Only validate Google map links for real places (not fiction)
        if (!this.real_fiction) { // If it's a real place
          if (!this.google_map_link.trim()) {
            errors.push('Google map link cannot be empty for real places.');
          } else if (!this.isValidURL(this.google_map_link)) {
            errors.push('Google map link must be a valid URL.');
          }

          if (!this.google_embed_iframe.trim()) {
            errors.push('Google embed frame cannot be empty for real places.');
          } else if (!this.isValidURL(this.google_embed_iframe)) {
            errors.push('Google embed frame link must be a valid URL.');
          }
        }
        // For fictional places, links are optional but should be valid if provided
        else {
          if (this.google_map_link.trim() && !this.isValidURL(this.google_map_link)) {
            errors.push('Google map link must be a valid URL.');
          }
          if (this.google_embed_iframe.trim() && !this.isValidURL(this.google_embed_iframe)) {
            errors.push('Google embed frame link must be a valid URL.');
          }
        }
      }
      // if (
      //   !this.google_map_link.trim() &&
      //   this.selected_media_type ===
      //   all_entity_type_values['place']['entity_type_key']
      // ) {
      //   errors.push('Google map link cannot be empty.');
      // } else if (
      //   this.selected_media_type ===
      //   all_entity_type_values['place']['entity_type_key'] &&
      //   !this.isValidURL(this.google_map_link)
      // ) {
      //   errors.push('Google map link must be a valid URL.');
      // }
      // if (
      //   !this.google_embed_iframe.trim() &&
      //   this.selected_media_type ===
      //   all_entity_type_values['place']['entity_type_key']
      // ) {
      //   errors.push('Google embed frame cannot be empty.');
      // } else if (
      //   this.selected_media_type ===
      //   all_entity_type_values['place']['entity_type_key'] &&
      //   !this.isValidURL(this.google_embed_iframe)
      // ) {
      //   errors.push('Google embed frame link must be a valid URL.');
      // }

      if (
        this.additionalResources.length > 0 &&
        this.additionalResources[0].trim()
      ) {
        for (const url of this.additionalResources) {
          if (!this.isValidURL(url)) {
            errors.push('Addition Resource link must be a valid URL.');
            break;
          }
        }
      }

      return errors;
    },

    selected_media_type_func(event) {
      this.selected_media = event.target.selectedOptions[0].text;
      this.selected_media_type = event.target.value;
    },

    selected_char_trait_func(char_id) {
      if (this.selected_char_trait[char_id]) {
        delete this.selected_char_trait[char_id];
      } else {
        this.selected_char_trait[char_id] = true;
      }
    },

    selected_role_func(role) {
      console.log(role);
      const role_dictionary =
        this.$store.state.add_missing_info_store.role.result;
      for (let key in role_dictionary) {
        if (role_dictionary[key] === role) {
          this.selected_role = key;
          break;
        }
      }
    },

    navigate_see_all_entity() {
      this.$router.replace({
        name: 'see_more_entity',
        params: {
          id: this.$route.params.id,
          type: this.selected_media_type,
          media_name: this.media_name,
          media_type: this.media_type,
        },
        query: {
          page: this.$route.query.page,
        },
      });
    },

    navigate_media_page() {
      this.set_selected_tab('identify');
      media_page_navigation(
        this.$router,
        this.$route.params.id,
        this.media_name,
        this.media_type,
      );
    },

    async submit_form() {
      const errors = this.validateInputs();
      if (errors.length > 0) {
        this.$toast.open({
          message: errors.join(' '),
          type: 'error',
          duration: 1000 * 5,
          dismissible: true,
        });
        return;
      }
      console.log('media_data_type: ', this.selected_media_type);
      let traits = [];
      traits.push(...Object.keys(this.selected_char_trait));

      let from_data_body = {
        media_id: this.$route.params.id,
        entity: this.selected_media_type,
        entity_data: {
          name: this.name,
          submit_to_curator: this.submit_curator,
          additional_resource: this.additionalResources,
          xp_points: this.currentXP
        },
      };

      // console.log('Form submission data with XP:', from_data_body);

     
      from_data_body['entity_data']['title_specific_description'] = this.title_specific_description;

      if (
        this.selected_media_type ===
        all_entity_type_values['activity']['entity_type_key']
      ) {
        from_data_body['entity_data']['no_of_participants'] =
          this.no_of_participants.toString();
      }

      if (
        this.selected_media_type ===
        all_entity_type_values['activity']['entity_type_key'] ||
        this.selected_media_type ===
        all_entity_type_values['purchase_oppurtunity']['entity_type_key']
      ) {
        delete from_data_body['entity_data']['additional_resource'];
      }

      if (
        this.selected_media_type ===
        all_entity_type_values['media_credit']['entity_type_key']
      ) {
        from_data_body['entity_data']['summary'] = this.summary_description;
        from_data_body['entity_data']['role'] = this.selected_role;
      }

      if (
        this.selected_media_type ===
        all_entity_type_values['genre']['entity_type_key']
      ) {
        from_data_body['entity_data']['applicable_formats'] = [
          this.applicable_formats,
        ];
      }

      if (
        this.selected_media_type ===
        all_entity_type_values['character']['entity_type_key']
      ) {
        from_data_body['entity_data']['traits'] = traits;
      }

      if (
        this.selected_media_type ===
        all_entity_type_values['place']['entity_type_key']
      ) {
        from_data_body['entity_data']['google_map_link'] = this.google_map_link;
        from_data_body['entity_data']['google_embed_iframe'] =
          this.google_embed_iframe;
      }

      if (!this.not_description.includes(this.selected_media_type)) {
        from_data_body['entity_data']['description'] = this.description;
      }

      if (
        this.link.trim()
      ) {
        from_data_body['entity_data']['link'] = this.link;
      }

      if (!this.not_o_type.includes(this.selected_media_type)) {
          from_data_body['entity_data']['o_type'] = this.real_fiction
            ? 'fiction'
            : 'real';
      }

      if (!this.not_o_source_type.includes(this.selected_media_type)) {
        from_data_body['entity_data']['source_type'] = this.native_mentioned
          ? 'mentioned'
          : 'native';
      }

      if (this.image !== '') {
        from_data_body['entity_data']['image'] = this.image;
      }

      this.loading = true;
      await Promise.all([
        this.add_entity_form_api(from_data_body)
          .then(() => {

            console.log('Form submitted successfully with XP points:');

            this.$toast.open({
              message: 'Form Submitted',
              type: 'success',
              duration: 1000 * 5,
              dismissible: true,
            });
            if (
              this.selected_media_type ===
              all_entity_type_values['media_credit']['entity_type_key']
            ) {
              this.navigate_media_page();
            } else {
              this.navigate_see_all_entity();
            }
          })
          .catch((error) => {
            this.$toast.open({
              message: error,
              type: 'error',
              duration: 1000 * 5,
              dismissible: true,
            });
          }),
      ]);
      this.loading = false;
    },

    async fetchDataFromApis() {
      this.loading = true;
      try {
        await Promise.all([
          this.get_char_traits_api(),
          this.get_role_api(),
          this.get_entity_list_api(),
        ]);
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.loading = false;
      }
    },
    goBack() {
      this.$router.go(-1);
    },

    async fetchDescription() {
      if (!this.link || this.isFetching) return;

      try {
        this.isFetching = true;
        const result = await this.getDataFromLink(this.link);
        if (result?.description) {
          this.description = result.description;
        } else {
          this.$toast.open({
            message: 'No description found from the provided URL',
            type: 'error',
            duration: 5000,
          });
        }
      } catch (error) {
        this.$toast.open({
          message: 'Error fetching description from URL',
          type: 'error',
          duration: 5000,
        });
        console.error('Error:', error);
      } finally {
        this.isFetching = false;
      }
    },

    async getDataFromUrl(url) {
      try {
        const response = await axios.post(APIs.get_summary, { url: url });
        return {
          title: response.data.result.title,
          description: response.data.result.description
        };
      } catch (error) {
        console.error("Error fetching data from URL:", error);
        return null;
      }
    },

    async getDataFromLink(link) {
      const result = await this.getDataFromUrl(link);
      if (result) {
        return result;
      }
      return { title: null, description: null };
    },
  },
  watch: {
    // Watch for changes in XP and update progress
    currentXP: {
      handler(newValue) {
        this.currentProgress = this.progressPercentage;
        this.totalXP = newValue;
      },
      immediate: true
    }
  }
};
</script>

<style></style>
