<template>
    <section class="md:flex justify-end gap-5 xl:gap-10 md:pr-4 py-4">
        <div class="all-invites border-x border-gray-500 w-full  md:max-w-[700px] py-10 px-3 sm:px-6 xl:p-10">
            <p class="text-xl mb-4 font-semibold">All Tasks</p>
            <div class="border border-gray-500 mb-4 shadow-xl rounded-xl overflow-hidden">
                <div class=" flex justify-between items-center p-4 bg-gray-200 text-blue">
                    <p class="text-lg font-semibold">Task</p>
                    <p>Status: <span class="text-gray-500">started</span></p>
                </div>
                <div class="bg-white flex gap-2 text-[15px] font-[500] p-4">
                    <div class="w-16 rounded-full relative">
                        <img src="../../../../images/ziaumer.png" alt="image" />
                    </div>
                    <div class="flex justify-between w-full">
                        <div class=" text-gray-500">
                            <p class="text-2xl text-black font-semibold mb-1">Review and Update Book Experience</p>
                            <p class="mb-1">Enhance the "Experience-The complete Persepolis" page by refining the reading progress, book status...</p>
                            <div class="flex flex-wrap mb-2 justify-between gap-3">
                                <p class="w-full font-semibold">Event: <span class="font-normal">The Adventures of Alice Wonderland-Dinner Event</span></p>
                                <p class="w-full font-semibold">Group: <span class="font-normal">The stars</span></p>
                            </div>
                            <p class="text-xs flex gap-5 "><span>Start date: 1/24/2025</span><span>End date:
                                    1/30/2025</span>
                            </p>
                            <div class="flex flex-wrap gap-3 my-2 justify-between items-center">
                                <div class="flex flex-wrap gap-4 text-xs my-2 text-black">
                                    <p class="bg-[#30C15469] rounded-full px-3 py-1 ">Completed Assignees: 2</p>
                                    <p class="bg-gray-200 rounded-full px-3 py-1 ">Pending Assignee: 1</p>
                                </div>
                                <div class="bg-white px-4 py-3 rounded-full text-black font-semibold border shadow-xl">
                                    View Details
                                </div>
                            </div>
                        </div>
                        <div class="flex gap-5">
                            <span @click.stop="toggleMenu" class="cursor-pointer relative">
                                <img class="w-2" src="../../../../images/more.png" alt="image" />
                                <!-- <div v-if="isMenuOpen" ref="menu"
                            class="absolute -right-5 xl:-right-36 xl:bottom-0 mt-2 w-40 bg-white border rounded shadow-lg">
                            <button @click="removeAlly"
                                class="block w-full px-4 py-2 text-left text-red-600 hover:bg-gray-100">
                                ⛔ Remove ally
                            </button>
                        </div> -->
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="border border-gray-500 mb-4 shadow-xl rounded-xl overflow-hidden">
                <div class=" flex justify-between items-center p-4 bg-gray-200 text-blue">
                    <p class="text-lg font-semibold">Task</p>
                    <p>Status: <span class="text-gray-500">started</span></p>
                </div>
                <div class="bg-white flex gap-2 text-[15px] font-[500] p-4">
                    <div class="w-16 rounded-full relative">
                        <img src="../../../../images/ziaumer.png" alt="image" />
                    </div>
                    <div class="flex justify-between w-full">
                        <div class=" text-gray-500">
                            <p class="text-2xl text-black font-semibold mb-1">Review and Update Book Experience</p>
                            <p class="mb-1">Enhance the "Experience-The complete Persepolis" page by refining the reading progress, book status...</p>
                            <div class="flex flex-wrap mb-2 justify-between gap-3">
                                <p class="w-full font-semibold">Event: <span class="font-normal">The Adventures of Alice Wonderland-Dinner Event</span></p>
                                <p class="w-full font-semibold">Group: <span class="font-normal">The stars</span></p>
                            </div>
                            <p class="text-xs flex gap-5 "><span>Start date: 1/24/2025</span><span>End date:
                                    1/30/2025</span>
                            </p>
                            <div class="flex flex-wrap gap-3 my-2 justify-between items-center">
                                <div class="flex flex-wrap gap-4 text-xs my-2 text-black">
                                    <p class="bg-[#30C15469] rounded-full px-3 py-1 ">Completed Assignees: 2</p>
                                    <p class="bg-gray-200 rounded-full px-3 py-1 ">Pending Assignee: 1</p>
                                </div>
                                <div class="bg-white px-4 py-3 rounded-full text-black font-semibold border shadow-xl">
                                    View Details
                                </div>
                            </div>
                        </div>
                        <div class="flex gap-5">
                            <span @click.stop="toggleMenu" class="cursor-pointer relative">
                                <img class="w-2" src="../../../../images/more.png" alt="image" />
                                <!-- <div v-if="isMenuOpen" ref="menu"
                            class="absolute -right-5 xl:-right-36 xl:bottom-0 mt-2 w-40 bg-white border rounded shadow-lg">
                            <button @click="removeAlly"
                                class="block w-full px-4 py-2 text-left text-red-600 hover:bg-gray-100">
                                ⛔ Remove ally
                            </button>
                        </div> -->
                            </span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="summary w-full md:w-96 text-gray-500  py-6 px-8 rounded-xl bg-gray-100 ">
            <p class="text-[18px] mb-2 ">Task Summary:</p>
            <div class="mb-5">
                <div class="flex justify-between text-[20px] font-semibold">
                    <p>Total Tasks:</p>
                    <p class="text-blue">04</p>
                </div>
                <div class="flex  gap-5">
                    <div class="font-semibold flex flex-col items-center p-4 ">
                        <p class="px-5 py-4 mb-2 border-4 border-blue rounded-full font-bold text-lg text-blue ">03</p>
                        <p>Completed</p>
                        <p>Tasks</p>
                    </div>
                    <div class="font-semibold flex flex-col items-center p-4 ">
                        <p class="px-5 py-4 mb-2 border-4 border-gray-500 rounded-full font-bold text-lg ">01</p>
                        <p>Remaining</p>
                        <p>Tasks</p>
                    </div>
                </div>
            </div>
            <div class="mb-5">
                <div class="flex justify-between text-[20px] font-semibold">
                    <p>My Total Tasks:</p>
                    <p class="text-blue">04</p>
                </div>
                <div class="flex gap-5">
                    <div class="font-semibold flex flex-col items-center p-4 ">
                        <p class="px-5 py-4 mb-2 border-4 border-blue rounded-full font-bold text-lg text-blue ">03</p>
                        <p>Completed</p>
                        <p>Tasks</p>
                    </div>
                    <div class="font-semibold flex flex-col items-center p-4 ">
                        <p class="px-5 py-4 mb-2 border-4 border-gray-500 rounded-full font-bold text-lg ">01</p>
                        <p>Remaining</p>
                        <p>Tasks</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "AllTasks",
};
</script>

<style>
.relative {
    position: relative;
}

.absolute {
    position: absolute;
}
</style>