<template>
  <div class="w-full bg-white">
    <!-- Header Start -->
    <HomeHeader />
    <!-- Header End -->
  </div>
  <LoginPopup v-if="show_login_popup" />
  <div class="w-full px-2 xs:px-5 py-8 font-inter bg-white">
    <div class="flex mb-2 justify-between">
      <button
        class="text-blue xs:px-6 xs:py-3 px-4 py-2 sm:text-lg font-medium border-1 border-blue rounded-2xl hover:text-white hover:bg-blue duration-500"
        @click="goBack">
        Back
      </button>
      <button v-if="isLogin" @click="add_missing_info()"
        class="text-white xs:block hidden px-6 py-3 bg-blue text-sm font-medium border-1 border-blue rounded-2xl hover:text-blue hover:bg-white duration-500">
        Add Helpful Information
      </button>
      <button v-if="isLogin" @click="add_missing_info()"
        class="text-white xs:px-6 px-4 xs:py-3 py-2 xs:hidden block bg-blue text-sm font-medium border-1 border-blue rounded-2xl hover:text-blue hover:bg-white duration-500">
        Add Info
      </button>
    </div>
    <h2 class="text-[28px] font-bold my-6 max-[640px]:text-[20px] text-center">
      {{ this.$route.query.media_name }}
    </h2>
    <!-- Tabs Start -->
    <div class="grid grid-cols-3 sm:grid-cols-5 justify-items-center mb-5 gap-2 font-semibold lg:gap-6 text-xs vsx:text-sm xs:text-md md:text-xl">
      <button class="w-full py-1 sm:py-3 rounded-lg sm:rounded-2xl" id="identify" @click="openTab('identify')" :class="{
        'bg-blue text-white  rounded-2xl': selected_tab === 'identify',
        'border-1 border-blue text-blue': selected_tab !== 'identify'
      }">
        Identify
      </button>

      <button class="w-full py-1 sm:py-3 rounded-lg sm:rounded-2xl" id="experience" @click="openTab('experience')" :class="{
        'bg-blue text-white rounded-2xl': selected_tab === 'experience',
        'border-1 border-blue text-blue': selected_tab !== 'experience'
      }">
        Experience
      </button>

      <button class="w-full py-1 sm:py-3 rounded-lg sm:rounded-2xl" id="describe" @click="openTab('describe')" :class="{
        'bg-blue text-white rounded-2xl': selected_tab === 'describe',
        'border-1 border-blue text-blue ': selected_tab !== 'describe'
      }">
        Describe
      </button>

      <button class="w-full py-1 sm:py-3 rounded-lg sm:rounded-2xl" id="enhance" @click="openTab('enhance')" :class="{
        'bg-blue text-white rounded-2xl': selected_tab === 'enhance',
        'border-1 border-blue text-blue': selected_tab !== 'enhance'
      }">
        Enhance
      </button>

      <button class="w-full rounded-lg sm:rounded-2xl py-1 sm:py-3" id="engage" @click="openTab('engage')" :class="{
        'bg-blue text-white rounded-2xl': selected_tab === 'engage',
        'border-1 border-blue text-blue ': selected_tab !== 'engage'
      }">
        Engage
      </button>

    </div>
    <!-- Tabs End -->

    <template v-if="selected_tab === 'experience' && isLogin">
      <ExperienceComponent />
    </template>

    <!-- Identify Section Start -->
    <template v-if="selected_tab === 'identify'">
      <IdentifyComponent />
    </template>
    <!-- Identify Section End -->

    <!-- Describe Section Start -->
    <template v-if="selected_tab === 'describe' && isLogin">
      <DescribeComponent />
    </template>
    <!-- Describe Section End -->

    <!-- Enhance Section Start -->
    <template v-if="selected_tab === 'enhance' && isLogin">
      <EnhanceComponent />
    </template>
    <!-- Enhance Section Start -->

    <!-- Enhance Section Start -->
    <template v-if="selected_tab === 'engage' && isLogin">
      <EngageComponent />
    </template>
    <!-- Enhance Section Start -->
  </div>

  <!-- Footer Start -->
  <HomeFooter />
  <!-- Footer End -->
</template>

<script>
// Import jQuery and Slick slider
import { mapState, mapMutations } from 'vuex';
import HomeHeader from '@/common_components/header/header.vue';
import HomeFooter from '@/common_components/footer/footer.vue';
import ExperienceComponent from './components/experience_component/experience_component.vue';
import IdentifyComponent from './components/identify_component/identify_component.vue';
import DescribeComponent from './components/describe_components/describe_component.vue';
import EnhanceComponent from './components/enhance/enhance_component.vue';
import EngageComponent from './components/engage_component/engage_component.vue';
import LoginPopup from '@/common_components/login_popup/login_popup.vue';
import { logout_messages } from '@/common_variables/logout_messages';

export default {
  name: 'MediaData',
  components: {
    HomeHeader,
    HomeFooter,
    ExperienceComponent,
    IdentifyComponent,
    DescribeComponent,
    EnhanceComponent,
    EngageComponent,
    LoginPopup,
  },

  computed: {
    ...mapState({
      identify_media_data: (state) =>
        state.media_data_store.identify_media_data,
      enhance_media_data: (state) => state.media_data_store.enhance_media_data,
      show_login_popup: (state) => state.home_logout_store.show_login_popup,
      selected_tab: (state) => state.home_store.selected_tab,
    }),
  },

  data() {
    return {
      initialized: false,
      isLogin: false,
      show_tabs: null,
      tab_class: {
        underline_tab:
          'tab-button text-2xl text-blue border-b-2 pb-1 border-blue max-[767px]:ml-0 ml-7',
        without_underline_tab:
          'tab-button text-2xl text-black/40 max-[767px]:ml-0 ml-7',
      },
    };
  },

  async created() {
    this.initialized = true;
    const token = localStorage.getItem('token');
    if (token === undefined || token === null || token === '') {
      this.set_selected_tab('identify');
      this.isLogin = false;
      this.set_show_login_popup(true);
      this.set_msg(logout_messages.media_page);
    } else {
      this.isLogin = true;
    }
  },

  methods: {
    ...mapMutations('home_logout_store', ['set_show_login_popup', 'set_msg']),
    ...mapMutations('home_store', ['set_selected_tab']),

    openTab(tab) {
      if (this.isLogin) {
        this.set_selected_tab(tab);
      } else {
        this.show_tabs = false;
        this.set_show_login_popup(true);
        this.set_msg(logout_messages.media_page);
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    add_missing_info() {
      this.$router.push({
        name: 'add-missing-info',
        params: { id: this.$route.params.id },
        query: {
          media_name: this.$route.query.media_name,
          media_type: this.$route.query.media_type,
          page: 'see_more_page',
        },
      });
    },
  },
};
</script>
