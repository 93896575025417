<template>
    <div v-if="isVisible" class="absolute inset-0 bg-black bg-opacity-50 z-[100] flex items-center justify-center">
        <div class="bg-white rounded-lg overflow-y-auto p-6 w-full max-w-md relative">
            <button @click="closePopup" class="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
            </button>

            <h2 class="text-xl font-semibold mb-4">Create a task</h2>
            <p class="text-gray-500 text-sm mb-5">Create a task by entering the title, description, start date and end
                date.</p>

            <form @submit.prevent="createTask">
                <div class="mb-4">
                    <div class="border-2 border-gray-300 h-40 w-40 rounded-lg overflow-hidden text-center cursor-pointer hover:border-blue-500"
                        @click="triggerFileUpload">
                        <input type="file" ref="fileInput" class="hidden" @change="handleFileUpload" accept="image/*" />
                        <div v-if="!selectedImage"
                            class="text-gray-500 h-full text-xs gap-4 flex flex-col justify-center items-center">
                            <p>Upload image</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                                class="bi bi-image" viewBox="0 0 16 16">
                                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                <path
                                    d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1z" />
                            </svg>
                        </div>
                        <img v-else :src="selectedImage" class="text-gray-500 h-full w-full" />
                    </div>
                </div>

                <div class="mb-4">
                    <input type="text" v-model="taskForm.title" placeholder="Enter task title"
                        class="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500" />
                </div>

                <div class="mb-4">
                    <input type="text" v-model="taskForm.url" placeholder="Enter URL (optional)"
                        class="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500" />
                </div>

                <div class="mb-4 relative">
                    <div @click="toggleDropdown"
                        class="w-full px-3 py-2 border rounded-lg flex items-center justify-between cursor-pointer hover:border-blue-500">
                        <template v-if="taskForm.event">
                            <div class="flex items-center">
                                <div class="text-sm font-medium">{{ getEventName(taskForm.event) }}</div>
                            </div>
                        </template>
                        <template v-else>
                            <span class="text-gray-500">Select an event</span>
                        </template>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" class="text-gray-500">
                            <path d="m6 9 6 6 6-6" />
                        </svg>
                    </div>

                    <!-- Dropdown menu -->
                    <div v-if="isDropdownOpen" class="absolute z-10 w-full mt-1 bg-white shadow-xl py-5 px-3 rounded-lg">
                        <div class="p-3">
                            <p class="font-semibold mb-2">Select Event</p>
                            <p class="text-xs">Select the event for this task to align with your goals and objectives.</p>
                        </div>
                        <div v-for="event in events" :key="event.value" @click="selectEvent(event)"
                            class="px-3 py-2 bg-gray-100 mb-1 rounded-full flex items-center cursor-pointer">
                            <img src="../../../../images/ziaumer.png" alt="Event icon"
                                class="w-10 h-10 mr-2 rounded-full" />
                            <div class="flex-grow">
                                <div class="text-sm font-medium">{{ event.name }}</div>
                                <div class="text-xs text-gray-500">{{ event.group }}</div>
                            </div>
                            <svg v-if="taskForm.event === event.value" xmlns="http://www.w3.org/2000/svg" width="20"
                                height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                class="text-blue-500">
                                <polyline points="20 6 9 17 4 12" />
                            </svg>
                        </div>
                    </div>
                </div>

                <div class="mb-4">
                    <textarea v-model="taskForm.description" placeholder="Enter task description"
                        class="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                        rows="4"></textarea>
                </div>

                <div class="flex space-x-4 mb-4">
                    <div class="w-1/2">
                        <input type="date" v-model="taskForm.startDate"
                            class="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500" />
                    </div>
                    <div class="w-1/2">
                        <input type="date" v-model="taskForm.endDate"
                            class="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500" />
                    </div>
                </div>

                <button type="submit"
                    class="w-full bg-blue font-semibold rounded-full text-white py-2 hover:bg-blue-700 transition duration-300">
                    Create a task
                </button>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CreateTask',
    props: {
        isVisible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            taskForm: {
                title: '',
                url: '',
                event: '',
                description: '',
                startDate: '',
                endDate: '',
                image: null
            },
            selectedImage: null,
            isDropdownOpen: false,
            events: [
                {
                    value: 'conference',
                    name: 'Conference Event',
                    group: 'Professional',
                    icon: '/api/placeholder/40/40'
                },
                {
                    value: 'meeting',
                    name: 'Team Meeting',
                    group: 'Internal',
                    icon: '/api/placeholder/40/40'
                },
                {
                    value: 'workshop',
                    name: 'Skills Workshop',
                    group: 'Learning',
                    icon: '/api/placeholder/40/40'
                }
            ]
        }
    },
    methods: {
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
        selectEvent(event) {
            this.taskForm.event = event.value;
            this.isDropdownOpen = false;
        },
        getEventIcon(value) {
            const event = this.events.find(e => e.value === value);
            return event ? event.icon : '/api/placeholder/40/40';
        },
        getEventName(value) {
            const event = this.events.find(e => e.value === value);
            return event ? event.name : '';
        },
        getEventGroup(value) {
            const event = this.events.find(e => e.value === value);
            return event ? event.group : '';
        },

        closePopup() {
            this.$emit('close');
        },
        triggerFileUpload() {
            this.$refs.fileInput.click();
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.selectedImage = e.target.result;
                    this.taskForm.image = file;
                }
                reader.readAsDataURL(file);
            }
        },
        createTask() {
            // Perform validation
            if (!this.taskForm.title) {
                alert('Please enter a task title');
                return;
            }

            // Here you would typically send the task data to a backend
            console.log('Task created:', this.taskForm);

            // Reset form and close popup
            this.resetForm();
            this.closePopup();
        },
        resetForm() {
            this.taskForm = {
                title: '',
                url: '',
                event: '',
                description: '',
                startDate: '',
                endDate: '',
                image: null
            };
            this.selectedImage = null;
        }
    },

    mounted() {
        const closeDropdown = (e) => {
            if (!this.$el.contains(e.target)) {
                this.isDropdownOpen = false;
            }
        };
        document.addEventListener('click', closeDropdown);
        document.removeEventListener('click', closeDropdown);
    }
}
</script>