<template>
    <div @click="review_quote_navigaton(card_data.id, card_data.media_type)"
        class="flex overflow-hidden border-1 border-borderColor rounded-2xl flex-col cursor-pointer swiper-slide">
        <template v-if="card_page === 'recent_view'">
            <div class="relative flex justify-center h-48 vxs:h-40 xs:h-48  overflow-hidden items-center ">
                <div class="absolute right-3 top-3 z-10">
                    <FavCardIconComponent :card_data="card_data" />
                </div>
                <img :src="card_data.cover_image" :alt="card_data.title" class="w-full h-full " />
            </div>
        </template>

        <template v-else-if="card_page === 'favorite_view'">
            <div class="relative flex justify-center h-48 vxs:h-40 xs:h-48  overflow-hidden items-center ">
                <FavCardIconComponent :card_data="card_data" />
                <img :src="card_data.cover_image" :alt="card_data.title" class="w-full  h-full " />
            </div>
        </template>

        <template v-else>
            <div class="relative flex justify-center h-48 vxs:h-40 xs:h-48 overflow-hidden items-center ">
                <div class="absolute right-3 top-3 z-10">
                    <FavCardIconComponent :card_data="card_data" />
                </div>
                <img :src="card_data.cover_image ? card_data.cover_image : card_data.image" class="w-full  h-full " />
            </div>
        </template>

        <div class="p-2 ">
            <div class="flex justify-between items-center">
                <!-- Conditionally render origin if it exists -->
                <span v-if="card_data.origin" class="text-sm text-blue font-bold ">
                    {{ card_data.origin }}
                </span>

                <!-- Conditionally render media_type_display if it exists -->
                <span v-if="card_data.media_type_display"
                    class="bg-blue px-2 py-1 text-white text-xs text-center rounded-full">
                    {{ card_data.media_type_display }}
                </span>
            </div>

            <p v-if="card_data.title || card_data.name" class="text-md truncate font-bold py-2 ">
                {{ card_data.title ? card_data.title : card_data.name }}
            </p>

            <!-- Conditionally render genre if it exists and is an array -->
            <div v-if="card_data.genre && card_data.genre.length > 0"
                class="text-xs text-blue h-8 overflow-hidden flex flex-wrap gap-1">
                <span class="border-blue border-1 mb-1 p-1 px-2 font-semibold rounded-full truncate"
                    v-for="(genre, index) in card_data.genre" :key="index">
                    {{ genre }}
                </span>
            </div>
            <div v-else class="text-xs text-gray-500 h-8 flex items-center">
                <span class="border-gray-500 border-1 mb-1 p-1 px-2 font-semibold rounded-full truncate">No Genre</span>
            </div>

        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import FavCardIconComponent from '../fav_card_icon/fav_card_icon_component.vue';
// import { logout_messages } from '@/common_variables/logout_messages';
// import { media_page_navigation } from '../common_routing/media_page_routing';
// import { specific_page_routing } from '../common_routing/specific_data_routing';
// import { all_entity_type_values } from '@/common_variables/entity_type';

export default {
    name: 'DeafultJournalCard',
    components: {
        FavCardIconComponent,
    },
    props: {
        card_data: {
            type: Object,
            required: true,
        },
        card_page: {
            type: String, // or the appropriate type
            required: false,
        },
    },
    data() {
        return {
            isLogin: false,
        };
    },

    mounted() {
        const token = localStorage.getItem('token');
        if (token === undefined || token === null || token === '') {
            this.isLogin = false;
        } else {
            this.isLogin = true;
            this.set_show_login_popup(false);
        }
    },

    methods: {
        ...mapActions('fav_card_icon_store', ['favourite_data_api']),
        ...mapMutations('home_logout_store', ['set_show_login_popup', 'set_msg']),

        review_quote_navigaton() {
            this.$router.push({
                name: 'review-quote',
                params: {
                    id: this.card_data.id,
                    media_name: this.card_data.title,
                },
            });
        },
    },
};
</script>