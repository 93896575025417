<template>
  <a class="border-2 border-blue flex cursor-pointer items-center px-3 py-1 rounded-2xl bg-blue text-white text-sm ">
    <span>Load More</span>
    <span class="text-white">
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-down-short"
        viewBox="0 0 16 16">
        <path fill-rule="evenodd"
          d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4" />
      </svg>
    </span>
  </a>
</template>

<script>
export default {
  name: 'SeeMoreIcon',
  data() {
    return {};
  },
  methods: {},
};
</script>

<style></style>


<!-- <svg class="text-white" width="25" height="25" viewBox="0 0 47 47" fill="currentColor"
  xmlns="http://www.w3.org/2000/svg">
        <path d="M23.9999 26.1819L34.1111 16L37 18.9091L23.9999 32L11 18.9091L13.8889 16L23.9999 26.1819Z"
          fill="black" />
        <circle cx="23.5" cy="23.5" r="23" stroke="white" /> 
      </svg> -->
