<template>
    <div class="w-full mx-auto font-inter">
        <div class="hidden">
            <HomeHeader />
        </div>
        <section class="flex relative" @click="handleOutsideClick">
            <LeftSidebar :is-mobile-open="isSidebarOpen" @close-sidebar="closeSidebar" />
            <div class="w-full bg-[#e4eaf8] h-full lg:ml-[250px]">
                <div class="shadow-lg lg:hidden flex justify-between bg-white p-4">
                    <div class="logo flex justify-center items-center">
                        <router-link to="/">
                            <img src="../../images/logo.png" alt="Logo" class="h-10 w-16" />
                        </router-link>
                    </div>
                    <div class="menu font-semibold flex items-center cursor-pointer" 
                         @click.stop="toggleSidebar">
                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="30" fill="currentColor"
                            class="bi bi-list" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
                        </svg>
                    </div>
                </div>
                <router-view></router-view>
            </div>
        </section>
        
        <!-- Under Construction Popup -->
        <UnderConstructionPopup v-if="showUnderConstructionPopup" @close="closePopupAndRedirect" />
    </div>
</template>

<script>
import LeftSidebar from './components/leftsidebar_component.vue';
import HomeHeader from '@/common_components/header/header.vue';
import UnderConstructionPopup from './common_chats/coming_soon.vue';

export default {
    name: 'ChatScreen',
    components: {
        LeftSidebar,
        HomeHeader,
        UnderConstructionPopup
    },
    data() {
        return {
            isSidebarOpen: false,
            isGroupMenuOpen: false,
            showUnderConstructionPopup: false,
            // Define routes that are under construction
            underConstructionRoutes: [
                'allies_view',
                'my_groups',
                'manage_events',
                'task_manage',
                'task_detail',
                'earn_points'
                // Add or remove routes as needed
            ]
        }
    },
    methods: {
        toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;
        },
        closeSidebar() {
            this.isSidebarOpen = false;
        },
        
        handleOutsideClick(event) {
            // Check if click is outside sidebar and group menu
            const sidebar = document.querySelector('.leftside');
            const menuButton = document.querySelector('.menu');
            const groupMenu = this.$refs.groupMenu;
            
            if (
                this.isSidebarOpen &&
                sidebar &&
                !sidebar.contains(event.target) &&
                menuButton &&
                !menuButton.contains(event.target)
            ) {
                this.closeSidebar();
            }

            if (
                this.isGroupMenuOpen &&
                groupMenu &&
                !groupMenu.contains(event.target)
            ) {
                this.isGroupMenuOpen = false;
            }
        },
        
        checkRouteStatus() {
            // Check if current route name is in the underConstructionRoutes array
            if (this.underConstructionRoutes.includes(this.$route.name)) {
                this.showUnderConstructionPopup = true;
            } else {
                this.showUnderConstructionPopup = false;
            }
        },
        
        closePopupAndRedirect() {
            this.showUnderConstructionPopup = false;
            // Redirect to a safe route, like the main dashboard
            this.$router.push('/');
        }
    },
    watch: {
        // Watch for route changes to check if the new route is under construction
        '$route'() {
            this.checkRouteStatus();
        }
    },
    mounted() {
        document.addEventListener("click", this.handleOutsideClick);
        // Check route status when component is mounted
        this.checkRouteStatus();
    },
    beforeUnmount() {
        document.removeEventListener("click", this.handleOutsideClick);
    }
};
</script>