<template>
  <div class="w-full bg-white shadow-gray-400 shadow-[rgba(0,0,0,0.2)_0px_0px_5px_0px]">
    <HomeHeader />
  </div>
  <div v-if="loading" class="loading-indicator">
    <div class="loader"></div>
  </div>
  <div v-else class="w-full mx-auto  font-inter py-8 sm:p-8" :style="{
    backgroundImage: `url(${require('../../images/form-shadow.png')})`,
    backgroundSize: 'auto',
    // clipPath: 'ellipse(90% 100% at center top)'
  }">
    <button
      class="text-blue sm:mx-0 mx-5 bg-white xs:px-6 xs:py-3 px-4 py-2 sm:text-lg font-medium border-1 border-blue rounded-2xl hover:text-white hover:bg-blue duration-500"
      @click="goBack">
      Back
    </button>
    <div class="relative flex justify-center items-center">
      <div class="relative  mx-auto px-3  py-5 sm:p-10 bg-white rounded-2xl my-6 w-full sm:w-[900px]">
        <h2 class="text-xl sm:text-2xl md:text-3xl font-bold text-center">
          User Create Form
        </h2>
        <p class="text-xs xs:text-sm sm:text-base text-[#252525] text-center opacity-60 my-5">
          Any new data you add will only be visible to you until it is reviewed and
          approved by our curator team. This ensures the quality and accuracy of
          information on our platform.
        </p>

        <div class="mx-auto pb-2 my-8 max-[1023px]:px-5">
          <div class="flex justify-between items-center mb-6 max-[1023px]:mb-33">
            <div class="text-xl font-semibold">
              Earned XP: <span class="text-blue ">{{ currentXP }}</span>
            </div>
            <div class="xs:text-xl  sm:text-2xl  font-semibold text-blue">
              XP Points
            </div>
            <div class="text-xl font-semibold">
              Total XP: <span class="text-blue ">{{ totalPossibleXP }}</span>
            </div>
          </div>

          <div class="relative bg-[#d6dcf4] mb-3 h-6 w-full rounded-full overflow-hidden">
            <div class="absolute h-full bg-blue rounded-full transition-all duration-300 ease-in-out"
              :style="{ width: progressPercentage + '%' }">
            </div>
          </div>
        </div>

        <form>
          <div class="w-full mb-10 relative">
            <span
              class="absolute top-[63px] right-[30px] max-[640px]:top-[52px] text-[#ADB5BD] icon-updownarrow pointer-events-none"></span>
            <label for="dataType" class="font-semibold ml-2 sm:text-xl">Media Type<span
                class="text-red-500">*</span></label>
            <select @change="selected_media_type_func($event)"
              class="border-1 border-borderColor rounded-2xl text-[#53585c] text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2 appearance-none"
              id="dataType">
              <option v-for="(value, key, index) in media_type_options.result" :key="index" :value="key">
                {{ value }}
              </option>
            </select>
          </div>
          <div class="w-full mb-9">
            <div class="flex justify-between">
              <label for="name" class="font-semibold ml-2 sm:text-xl">Title<span class="text-red-500">*</span></label>
              <p class="mr-3 sm:text-xl italic">{{ xp_points.title.xp_points_display }}</p>
            </div>
            <input v-model="title" type="text"
              class="border-1 border-borderColor rounded-2xl text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"
              id="name" placeholder="" />
          </div>
          <div v-if="selected_media_type !== 'theater'" class="w-full mb-9">
            <div class="flex justify-between">
              <label for="identifier" class="font-semibold ml-2 sm:text-xl">{{ identifier
              }}<span class="text-red-500">*</span></label>
              <p class="mr-3 sm:text-xl italic">{{ xp_points.ISBN.xp_points_display }}</p>
            </div>
            <input v-model="identifier_text" type="text"
              class="border-1 border-borderColor rounded-2xl text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"
              id="identifier" placeholder="" />
          </div>
          <div class="w-full mb-9">
            <div class="flex justify-between">
              <label for="yearField" class="font-semibold ml-2 sm:text-xl">
                <span v-if="selected_media_type === 'book'">Year First Published</span>
                <span v-else-if="selected_media_type === 'theater'">Year First Performed</span>
                <span v-else-if="selected_media_type === 'artifact'">Year Created</span>
                <span v-else>Year First Released</span>
                <span class="text-red-500">*</span>
              </label>
              <p class="mr-3 sm:text-xl italic">{{ xp_points.origin.xp_points_display }}</p>
            </div>
            <input v-model="origin" type="number"
              class="border-1 border-borderColor rounded-2xl text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"
              id="yearField" placeholder="" />
          </div>
          <!-- <div class="w-full mb-9">
            <div class="flex justify-between">
              <label for="participants" class="font-semibold ml-2 sm:text-xl">Year First Published<span
                  class="text-red-500">*</span></label>
              <p class="mr-3 sm:text-xl italic">{{ xp_points.origin.xp_points_display }}</p>
            </div>
            <input v-model="origin" type="number"
              class="border-1 border-borderColor rounded-2xl text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"
              id="name" placeholder="" />
          </div> -->

          <div class="w-full mb-10 relative">
            <span
              class="absolute top-[63px] right-[30px] max-[640px]:top-[52px] text-[#ADB5BD] icon-updownarrow pointer-events-none"></span>
            <div class="flex justify-between">
              <label for="dataType" class="font-semibold ml-2 sm:text-xl">Format<span
                  class="text-red-500">*</span></label>
              <p class="mr-3 sm:text-xl italic">{{ xp_points.format.xp_points_display }}</p>

            </div>
            <select @change="selected_format_func($event)"
              class="border-1 border-borderColor rounded-2xl text-[#53585c] text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2 appearance-none"
              id="dataType">
              <option v-for="(value, key, index) in media_format_options.result" :key="index" :value="key">
                {{ value }}
              </option>
            </select>
          </div>



          <div class="flex flex-col  items-start justify-center w-full mb-10">
            <div class=" flex justify-between mb-4 w-full xs:w-60 ">
              <p class="sm:text-xl items-center font-semibold">Image Upload</p>
              <p class="mr-3 sm:text-xl italic">{{ xp_points.img.xp_points_display }}</p>
            </div>
            <!-- Image Preview Section -->
            <div class="relative w-full xs:w-52 h-52 flex items-center justify-center  rounded-2xl bg-gray-50">
              <template v-if="!show_avatar">
                <span class="text-gray-300">
                  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50"
                    class="bi bi-person-bounding-box text-gray-300" viewBox="0 0 16 16">
                    <path
                      d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5M.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5" />
                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                  </svg>
                </span>
              </template>
              <template v-else>
                <img :src="show_avatar" alt="Preview"
                  class="w-full h-full object-cover border border-gray-300 rounded-lg"
                  style="object-fit: contain; object-position: center" />
                <span @click="deleteAvatar"
                  class="absolute bg-gray-300 top-2 right-2 text-red-600 p-3 rounded-xl cursor-pointer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                    class="bi bi-trash-fill" viewBox="0 0 16 16">
                    <path
                      d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0" />
                  </svg>
                </span>
              </template>
            </div>

            <!-- Upload Image Section -->
            <label for="dropzone-file"
              class="flex justify-center items-center xs:w-52 gap-2 px-6 w-full py-4 my-4 text-blue rounded-lg cursor-pointer bg-gray-100 hover:bg-gray-200">
              <svg class="w-6 h-6 text-blue-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 20 16">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
              </svg>
              <span class="text-blue-600 font-medium">Add an image</span>
              <input id="dropzone-file" type="file" class="hidden" @change="handleAvatarUpload"
                accept=".png, .jpg, .jpeg" />
            </label>
          </div>
          <div class="w-full mb-9">
            <div class="flex justify-between">
              <label for="link" class="font-semibold ml-2 sm:text-xl">Summary Link</label>
              <!-- <p class="mr-3 sm:text-xl italic">{{ xp_points.URL_Link.xp_points_display }}</p> -->
            </div>
            <div class="flex mt-2 gap-2 border-1 border-borderColor rounded-2xl overflow-hidden">
              <input 
                v-model="summary_link" 
                type="text"
                class="text-xl max-[640px]:text-lg font-light w-full focus:outline-none px-5 py-4 max-[640px]:px-4 max-[640px]:py-2"
                id="link" 
                placeholder="" 
              />
              <button 
                type="button"
                @click="fetchDescription" 
                class="px-4 py-2 bg-blue text-white w-60 transition-colors duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
                :disabled="!summary_link || isFetching"
              >
                {{ isFetching ? 'Fetching...' : 'Fetch Summary' }}
              </button>
            </div>
          </div>
          
          <div class="w-full mb-9">
            <div class="flex justify-between">
              <label for="description" class="font-semibold ml-2 sm:text-xl">Summary</label>
              <p class="mr-3 sm:text-xl italic">{{ xp_points.summary.xp_points_display }}</p>
            </div>
            <textarea v-model="summary" id="description" rows="5" cols="30"
              class="border-1 border-borderColor rounded-3xl text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"></textarea>
          </div>

          <div
            class="flex flex-col border-1 border-borderColor rounded-3xl px-8 py-6 mb-5 max-[654px]:px-3 max-[654px]:py-3">
            <div class="flex justify-between">
              <h3 class="sm:text-xl font-bold mb-3">Target Audience</h3>
              <p class="mr-3 sm:text-xl italic">{{ xp_points.target_audiance.xp_points_display }}</p>
            </div>
            <div class="flex relative mb-5">
              <input v-model="search_target" type="text"
                class="border-1 border-borderColor rounded-2xl w-full text-base text-[#ADB5BD] pl-6 py-3"
                placeholder="Search Target Audience..." />
              <div class="absolute top-0 right-0"></div>
            </div>
            <div class="h-36 overflow-x-auto">
              <div v-for="(label, key) in filteredTargetAudienceOptions" :key="key"
                class="px-5 py-2 mb-1 rounded-full hover:bg-badgeBG">
                <label class="flex items-center">
                  <input type="checkbox" :id="'trait-' + key" :value="key" v-model="selected_target_audience_list"
                    class="w-5 h-5 mr-3" />
                  <span>{{ label }}</span>
                </label>
              </div>
            </div>
          </div>

          <div class="flex  justify-between items-end gap-4 flex-wrap mt-10">
            <div class="flex flex-col   w-full">
              <div class=" flex w-full justify-between items-center mb-4">
                <p class="font-semibold ml-2 sm:text-xl">Fiction or Non-fiction?</p>
                <p class="mr-3 text-center sm:text-xl italic ">{{ xp_points.type.xp_points_display }}</p>
              </div>
              <div class="flex w-72 overflow-hidden rounded-2xl  items-center max-[654px]:justify-between">
                <label class="relative cursor-pointer rounded-2xl overflow-hidden border border-borderColor w-full">
                  <input v-model="real_fiction" type="checkbox" class="sr-only peer" />
                  <div class="overflow-hidden font-semibold flex items-center bg-white">
                    <p class="p-3 w-full text-center" :class="{ 'bg-blue text-white': !real_fiction }">Real</p>
                    <p class="p-3 w-full text-center" :class="{ 'bg-blue text-white': real_fiction }">Fiction</p>
                  </div>
                </label>
              </div>
            </div>
            <!-- <div class="flex  w-60 flex-col">
              <p class="mr-3 text-center sm:text-xl italic">{{ xp_points.type.xp_points_display }}</p>
              <p class="mr-4 text-sm text-center font-medium text-gray-900 dark:text-gray-300">
                Submit to Curators
              </p>
              <div class="flex overflow-hidden rounded-2xl w-full items-center max-[654px]:justify-between">
                <label class="relative cursor-pointer rounded-2xl overflow-hidden border border-borderColor w-full">
                  <input v-model="submit_curator" type="checkbox" class="sr-only peer" />
                  <div class="overflow-hidden font-semibold flex items-center bg-white">
                    <p class="p-3 w-full text-center" :class="{ 'bg-blue text-white': !submit_curator }">No</p>
                    <p class="p-3 w-full text-center" :class="{ 'bg-blue text-white': submit_curator }">Yes</p>
                  </div>
                </label>
              </div>
            </div> -->
          </div>
          <div class="w-full mb-9 mt-10">
            <div class="flex justify-between">
              <label for="url" class="font-semibold ml-2 sm:text-xl">URL</label>
              <p class="mr-3 sm:text-xl italic">{{ xp_points.URL_Link.xp_points_display }}</p>
            </div>
            <input 
              v-model="url" 
              type="text"
              class="border-1 border-borderColor rounded-2xl text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"
              id="url" 
              placeholder="" 
            />
          </div>
          <div class="w-full py-9 ">
            <div class="flex justify-between">
              <label for="additional_resources" class="font-semibold ml-2 sm:text-xl">Additional Online
                Resources</label>
              <p class="mr-3 sm:text-xl italic">{{ xp_points.type.xp_points_display }}</p>
            </div>
            <div v-for="(resource, index) in additionalResources" :key="index" class="relative flex items-center mt-2">
              <input type="text"
                class="border-1 border-borderColor rounded-2xl text-xl max-[640px]:text-lg font-light w-full px-5 py-4 max-[640px]:px-4 max-[640px]:py-2"
                :placeholder="'Resource ' + (index + 1)" v-model="additionalResources[index]" />
              <!-- Remove button -->
              <button type="button" @click="removeResource(index)" class="absolute right-2 text-red-500 text-2xl ml-3">
                -
              </button>
            </div>
            <button type="button" @click="addResource" class="text-blue-500 sm:text-xl mt-2 flex items-center">
              <span class="mr-1">+</span> Add Resource
            </button>
          </div>
        </form>
        <div class="flex flex-col w-full">
          <div class="flex mb-4 justify-between w-full">
            <p class="font-semibold ml-2 sm:text-xl">
              Submit to Curators
            </p>
            <p class="mr-3 text-center sm:text-xl italic">{{ xp_points.type.xp_points_display }}</p>
          </div>
          <div class="flex  w-72 flex-col">
            <div class="flex overflow-hidden rounded-2xl w-full items-center max-[654px]:justify-between">
              <label class="relative cursor-pointer rounded-2xl overflow-hidden border border-borderColor w-full">
                <input v-model="submit_curator" type="checkbox" class="sr-only peer" />
                <div class="overflow-hidden font-semibold flex items-center bg-white">
                  <p class="p-3 w-full text-center" :class="{ 'bg-blue text-white': !submit_curator }">No</p>
                  <p class="p-3 w-full text-center" :class="{ 'bg-blue text-white': submit_curator }">Yes</p>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="flex justify-center mt-10">
          <button @click.prevent="submit_form()"
            class="text-xl text-white font-bold text-center border-2 border-blue bg-blue py-3 px-6 rounded-2xl hover:border-blue hover:border-1 hover:bg-white hover:text-blue duration-500">
            Submit
          </button>
        </div>
        <p class=" text-gray-500 font-semibold text-center my-7">
          Submitting spam, offensive content, or any other unwanted data to the
          curators for review may result in action taken against your account,
          including but not limited to temporary or permanent suspension of
          access.
        </p>
      </div>
    </div>

  </div>
  <!-- Footer Start -->
  <HomeFooter />
  <!-- Footer End -->
</template>

<script>
import HomeHeader from '@/common_components/header/header.vue';
import HomeFooter from '@/common_components/footer/footer.vue';
import { media_page_navigation } from '@/common_components/common_routing/media_page_routing';
import { mapState, mapActions } from 'vuex';
import { all_media_type_values } from '@/common_variables/media_type';
import { create_form_xp } from '@/common_variables/create_form_xp';
import axios from 'axios';
import { APIs } from '@/apis/apis_collection';

export default {
  name: 'CreateNewMedia',
  components: {
    HomeHeader,
    HomeFooter,
  },

  async created() {
    await this.fetchDataFromApis();
  },

  computed: {

    totalPossibleXP() {
      let total = 0;

      // Add all possible XP points
      total += this.xp_points.title.xp_points; // Title
      total += this.xp_points.ISBN.xp_points; // ISBN/Identifier
      total += this.xp_points.origin.xp_points; // Origin
      total += this.xp_points.format.xp_points; // Format
      total += this.xp_points.img.xp_points; // Image
      total += this.xp_points.summary.xp_points; // Summary
      total += this.xp_points.URL_Link.xp_points; // URL Link
      total += this.xp_points.another_source.xp_points; // Additional Resources
      total += this.xp_points.target_audiance.xp_points; // Target Audience
      total += this.xp_points.type.xp_points * 2; // Real/Fiction + Submit to Curators (type XP used for both)

      // Only add identifier XP if not theater type
      if (this.selected_media_type !== 'theater') {
        if (this.selected_media_type === 'museum') {
          total += this.xp_points.meusium_no.xp_points;
        } else if (this.selected_media_type === 'product') {
          total += this.xp_points.upc.xp_points;
        } else {
          total += this.xp_points.ISBN.xp_points;
        }
      }

      return total;
    },

    currentXP() {
      let total = 0;

      // Title XP
      if (this.title.trim()) {
        total += this.xp_points.title.xp_points;
      }

      // Identifier XP (ISBN/UPC/Museum Number)
      if (this.identifier_text.trim() && this.selected_media_type !== 'theater') {
        total += this.xp_points.ISBN.xp_points;
      }

      // Origin XP
      if (this.origin !== '') {
        total += this.xp_points.origin.xp_points;
      }

      // Format XP
      if (this.selected_format) {
        total += this.xp_points.format.xp_points;
      }

      // Image XP
      if (this.image) {
        total += this.xp_points.img.xp_points;
      }

      // Summary XP
      if (this.summary.trim()) {
        total += this.xp_points.summary.xp_points;
      }

      // URL Link XP
      if (this.url.trim()) {
        total += this.xp_points.URL_Link.xp_points;
      }

      // Target Audience XP
      if (this.selected_target_audience_list.length > 0) {
        total += this.xp_points.target_audiance.xp_points;
      }

      // Additional Resources XP
      if (this.additionalResources.length > 0 && this.additionalResources[0].trim()) {
        total += this.xp_points.another_source.xp_points;
      }

      // Real/Fiction XP
      if (this.real_fiction) {
        total += this.xp_points.type.xp_points;
      }

      // Submit to Curator XP
      if (this.submit_curator) {
        total += this.xp_points.type.xp_points;
      }

      return total;
    },

    progressPercentage() {
      if (this.totalPossibleXP === 0) return 0;
      return Math.min((this.currentXP / this.totalPossibleXP) * 100, 100);
    },


    ...mapState({
      media_type_options: (state) =>
        state.create_new_media_store.media_type_options,
      target_audience_options: (state) =>
        state.create_new_media_store.target_audience_options,
      media_format_options: (state) =>
        state.create_new_media_store.media_format_options,
    }),
    identifier() {
      return all_media_type_values[this.selected_media_type][
        'identifier_label'
      ];
    },

    filteredTargetAudienceOptions() {
      const query = this.search_target.toLowerCase();
      return Object.fromEntries(
        Object.entries(this.target_audience_options.result).filter(([label]) =>
          label.toLowerCase().includes(query),
        ),
      );
    },
  },

  data() {
    return {
      selected_media_type: all_media_type_values['book']['media_type_key'],
      selected_format: '',
      selected_target_audience_list: [],
      search_target: '',
      real_fiction: false,
      title: '',
      summary: '',
      summary_link: '',
      submit_curator: true,
      origin: '',
      format: '',
      show_avatar: null,
      loading: true,
      image: '',
      additionalResources: [],
      identifier_text: '',
      url: '',
      xp_points: create_form_xp,
      currentProgress: 0,
      totalXP: 0,
      isFetching: false,
    };
  },

  methods: {
    ...mapActions('create_new_media_store', [
      'get_media_type_options_api',
      'add_target_audience_options_api',
      'add_media_form_api',
      'get_media_format_options_api',
    ]),

    calculateTotalXP() {
      let total = 0;

      // Add base XP for required fields
      total += this.name ? this.xp_points.name : 0;
      total += this.selected_media_type ? this.xp_points.data_type : 0;

      // Add XP for description if applicable
      if (!this.not_description.includes(this.selected_media_type) && this.description) {
        total += this.xp_points.description;
      }

      // Add XP for participants if activity
      if (this.selected_media_type === this.all_entity_values['activity']['entity_type_key'] && this.no_of_participants) {
        total += this.xp_points.participants;
      }

      // Add XP for place-specific fields
      if (this.selected_media_type === this.all_entity_values['place']['entity_type_key']) {
        if (this.google_map_link) total += this.xp_points.google_map;
        if (this.google_embed_iframe) total += this.xp_points.google_embed;
      }

      // Add XP for media credit fields
      if (this.selected_media_type === this.all_entity_values['media_credit']['entity_type_key']) {
        if (this.summary_description) total += this.xp_points.summary;
        if (this.selected_role) total += this.xp_points.role;
      }

      // Add XP for character traits
      if (this.selected_media_type === this.all_entity_values['character']['entity_type_key'] &&
        Object.keys(this.selected_char_trait).length > 0) {
        total += this.xp_points.traits;
      }

      // Add XP for image
      if (this.image) {
        total += this.xp_points.image;
      }

      // Add XP for additional resources
      if (this.additionalResources.length > 0 && this.additionalResources[0].trim()) {
        total += this.xp_points.additional_resources;
      }

      return total;
    },

    addResource() {
      this.additionalResources.push('');
    },
    removeResource(index) {
      this.additionalResources.splice(index, 1);
    },
    selected_target_func(key) {
      const index = this.selected_target_audience_list.indexOf(key);
      if (index === -1) {
        this.selected_target_audience_list.push(key);
      } else {
        this.selected_target_audience_list.splice(index, 1);
      }
    },

    async selected_media_type_func(event) {
      this.selected_media_type = event.target.value;
      await this.fetch_media_form_api();
    },

    selected_format_func(event) {
      this.selected_format = event.target.value;
    },

    validateInputs() {
      const errors = [];
      if (!this.title.trim()) {
        errors.push('Title cannot be empty.');
      }
      if (this.origin === '') {
        errors.push('Origin cannot be empty.');
      } else if (parseInt(this.origin, 10) < 0) {
        errors.push('Origin cannot be negative.');
      }
      if (this.selected_target_audience_list.length === 0) {
        errors.push('Atleast select one Target Audience.');
      }
      if (
        !this.identifier_text.trim() &&
        this.selected_media_type !==
        all_media_type_values['theater']['media_type_key']
      ) {
        errors.push('Identifier cannot be empty.');
      }
      if (this.summary_link.trim() && !this.isValidURL(this.summary_link)) {
        errors.push('Summary link must be a valid URL.');
      }
      if (this.url.trim() && !this.isValidURL(this.url)) {
        errors.push('Link must be a valid URL.');
      }

      if (
        this.additionalResources.length > 0 &&
        this.additionalResources[0].trim()
      ) {
        for (const url of this.additionalResources) {
          if (!this.isValidURL(url)) {
            errors.push('Addition Online Resource link must be a valid URL.');
            break; // Exit the loop early if an invalid URL is found
          }
        }
      }

      return errors;
    },

    isValidURL(url) {
      try {
        // Use the built in JS  URL constructor to validate
        new URL(url);
        return true;
      } catch (e) {
        // If URL constructor throws an error, the URL is invalid
        return false;
      }
    },

    // isValidURL(url) {
    //   const pattern = new RegExp(
    //     '^(https?:\\/\\/)?' + // protocol
    //     '((([a-zA-Z0-9\\-\\_\\.]+)\\.([a-zA-Z]{2,}))|' + // domain name
    //     'localhost|' + // localhost
    //     '\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}|' + // IPv4
    //     '\\[([a-fA-F0-9:]+)\\])' + // IPv6
    //     '(\\:\\d+)?(\\/[-a-zA-Z0-9@:%_\\+.~#?&//=]*)?' + // port and path
    //     '(\\?[;&a-zA-Z0-9%_\\+.~#?&//=]*)?' + // query string
    //     '(\\#[-a-zA-Z0-9_]*)?$',
    //     'i',
    //   ); // fragment locator
    //   return pattern.test(url);
    // },

    navigation_media_page(id, media_name, media_type) {
      media_page_navigation(this.$router, id, media_name, media_type);
    },

    async submit_form() {
      const errors = this.validateInputs();
      if (errors.length > 0) {
        this.$toast.open({
          message: errors.join(' '),
          type: 'error',
          duration: 1000 * 5,
          dismissible: true,
        });
        return;
      }

      let from_data_body = {
        media_type: this.selected_media_type,
        o_type: this.real_fiction ? 'fiction' : 'real',
        title: this.title,
        submit_to_curator: this.submit_curator,
        target_audience: this.selected_target_audience_list,
        origin: this.origin,
        format: this.selected_format,
        additional_resource: this.additionalResources,
        identifier: this.identifier_text,
        xp_points: this.currentXP
      };

      // console.log('Form submission data with XP:', from_data_body);
      if (this.url.trim()) {
        from_data_body['link'] = this.url;
      }
      if (this.summary_link.trim()) {
        from_data_body['summary_link'] = this.summary_link;
      }

      if (this.summary.trim()) {
        from_data_body['summary'] = this.summary;
      }

      if (
        this.selected_media_type ===
        all_media_type_values['theater']['media_type_key']
      ) {
        delete from_data_body['identifier'];
      }

      if (this.image !== '') {
        from_data_body['cover_image'] = this.image;
      }

      this.loading = true;
      await Promise.all([
        this.add_media_form_api(from_data_body)
          .then((response) => {
            this.$toast.open({
              message: 'Form Submitted',
              type: 'success',
              duration: 1000 * 5,
              dismissible: true,
            });
            this.navigation_media_page(
              response.result.id,
              response.result.title,
              response.result.media_type,
              response.result.media_type_display,
            );
          })
          .catch((error) => {
            this.$toast.open({
              message: error,
              type: 'error',
              duration: 1000 * 5,
              dismissible: true,
            });
          }),
      ]);
      this.loading = false;
    },

    async fetchDataFromApis() {
      this.loading = true;
      try {
        await Promise.all([
          this.get_media_type_options_api(),
          this.add_target_audience_options_api(),
          this.get_media_format_options_api({
            media_type: this.selected_media_type,
          }),
        ]);
        this.selected_format = Object.keys(this.media_format_options.result)[0];
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.loading = false;
      }
    },

    async fetch_media_form_api() {
      try {
        await Promise.all([
          this.get_media_format_options_api({
            media_type: this.selected_media_type,
          }),
        ]);
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      }
    },

    handleAvatarUpload(event) {
      const file = event.target.files[0];
      if (file.size > 1048576) {
        this.$toast.open({
          message: 'Profile picture must be less than 1 MB',
          type: 'error',
          duration: 1000 * 5,
          dismissible: true,
        });
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        this.show_avatar = reader.result;
        this.image = reader.result;
      };
      reader.readAsDataURL(file);
    },

    deleteAvatar() {
      this.image = '';
      this.show_avatar = null;
    },

    goBack() {
      this.$router.go(-1);
    },

    async fetchDescription() {
      if (!this.summary_link || this.isFetching) return;

      try {
        this.isFetching = true;
        const result = await this.getDataFromLink(this.summary_link);
        if (result?.description) {
          this.summary = result.description;
        } else {
          this.$toast.open({
            message: 'No summary found from the provided URL',
            type: 'error',
            duration: 5000,
          });
        }
      } catch (error) {
        this.$toast.open({
          message: 'Error fetching description from URL',
          type: 'error',
          duration: 5000,
        });
        console.error('Error:', error);
      } finally {
        this.isFetching = false;
      }
    },

    async getDataFromUrl(url) {
      try {
        const response = await axios.post(APIs.get_summary, { url: url });
        return {
          title: response.data.result.title,
          description: response.data.result.description
        };
      } catch (error) {
        console.error("Error fetching data from URL:", error);
        return null;
      }
    },

    async getDataFromLink(link) {
      const result = await this.getDataFromUrl(link);
      if (result) {
        return result;
      }
      return { title: null, description: null };
    },
  },
  watch: {
    // Watch for changes in XP and update progress
    currentXP: {
      handler(newValue) {
        this.currentProgress = this.progressPercentage;
        this.totalXP = newValue;
      },
      immediate: true
    }
  }
};
</script>

<style></style>
