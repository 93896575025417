<template>
  <div class="w-full">
    <!-- Header Start -->
    <HomeHeader />
    <!-- Header End -->
    <div class="w-full mx-auto  font-inter py-8 sm:p-8" :style="{
      backgroundImage: `url(${require('../../images/form-shadow.png')})`,
      backgroundSize: 'auto',
      // clipPath: 'ellipse(90% 100% at center top)'
    }">
      <button
       class="text-blue xs:px-6 xs:py-3 px-4 py-2 sm:text-lg font-medium border-1 border-blue rounded-2xl hover:text-white hover:bg-blue duration-500"
        @click="goBack">
        Back
      </button>
      <div v-if="loading" class="loading-indicator">
        <div class="loader"></div>
      </div>
      <div v-else class=" relative flex justify-center items-center">
        <div class="relative mx-auto px-3 xs:px-5 py-5 sm:p-10 bg-white rounded-2xl my-6 w-full sm:w-[900px]">
          <h2 class="text-2xl sm:text-3xl font-bold text-center mb-2">
            Add Quote
          </h2>
          <h2 class="text-xl text-blue font-bold text-center my-8">
            {{ this.$route.params.media_name }}
          </h2>
          <form>
            <div class="w-full mb-9">
              <label for="quote" class="sm:text-xl font-bold">Quote<span class="text-red-500">*</span></label>
              <textarea v-model="quote" id="quote" rows="5" cols="30"
                class="border-1 border-borderColor rounded-2xl text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"></textarea>
            </div>
            <div class="w-full mb-7">
              <label for="said" class="sm:text-xl font-bold">Who said it<span class="text-red-500">*</span></label>
              <input type="text" v-model="said"
                class="border-1 border-borderColor rounded-2xl text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"
                id="said" placeholder="" />
            </div>
            <div class="w-full mb-7">
              <label for="location" class="sm:text-xl font-bold">Location in
                Media<span class="text-red-500">*</span></label>
              <input type="text" v-model="media_location"
                class="border-1 border-borderColor rounded-2xl text-xl max-[640px]:text-lg font-light w-full px-5 py-4 mt-2 max-[640px]:px-4 max-[640px]:py-2"
                id="location" placeholder="" />
            </div>
            <div class="flex flex-col justify-center items-center font-semibold ">
              <p class="mr-4 sm:text-lg md:text-xl mb-1 font-medium text-gray-900 dark:text-gray-300">
                Submit to Curators
              </p>
              <label class="relative cursor-pointer rounded-2xl overflow-hidden border border-borderColor w-40 sm:w-72">
                <input v-model="submit_curator" type="checkbox" class="sr-only peer" />
                <div class="overflow-hidden font-semibold flex items-center bg-white">
                  <p class="p-3 w-full text-center" :class="{ 'bg-blue text-white': !submit_curator }">No</p>
                  <p class="p-3 w-full text-center" :class="{ 'bg-blue text-white': submit_curator }">Yes</p>
                </div>
              </label>
            </div>
            <p class="text-sm text-[#8f8f8f] text-center my-7">
              SUBMITTING spam, offensive content, or any other unwanted data to the
              curators for review may result in action taken against your account,
              including but not limited to temporary or permanent suspension of
              access.
            </p>
            <div class="flex justify-center">
              <button @click.prevent="submitForm"
                class="text-xl text-white font-bold text-center bg-blue py-3 px-6 rounded-2xl hover:border-blue hover:border-1 hover:bg-white hover:text-blue duration-500">
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- Footer Start -->
    <HomeFooter />
    <!-- Footer End -->
  </div>
</template>

<script>
import HomeHeader from '@/common_components/header/header.vue';
import HomeFooter from '@/common_components/footer/footer.vue';
import { mapActions } from 'vuex';

export default {
  name: 'AddQuote',
  components: {
    HomeHeader,
    HomeFooter,
  },
  data() {
    return {
      loading: false,
      quote: null,
      said: null,
      media_location: null,
      submit_curator: true,
      errors: {
        quote: false,
        said: false,
        media_location: false,
      },
    };
  },
  methods: {
    ...mapActions('add_quote_store', ['post_add_quote_data_api']),
    ...mapActions('review_quote_store', ['get_quote_data_api']),
    validateForm() {
      this.errors.quote = !this.quote;
      this.errors.said = !this.said;
      this.errors.media_location = !this.media_location;

      if (this.errors.quote) {
        this.$toast.open({
          message: 'Quote cannot be empty',
          type: 'error',
          duration: 1000 * 5,
          dismissible: true,
        });
      }

      if (this.errors.said) {
        this.$toast.open({
          message: 'Who said it cannot be empty',
          type: 'error',
          duration: 1000 * 5,
          dismissible: true,
        });
      }

      if (this.errors.media_location) {
        this.$toast.open({
          message: 'Location in Media cannot be empty',
          type: 'error',
          duration: 1000 * 5,
          dismissible: true,
        });
      }

      return (
        !this.errors.quote && !this.errors.said && !this.errors.media_location
      );
    },
    async submitForm() {
      if (this.validateForm()) {
        const payload = {
          quote: this.quote,
          said_by: this.said,
          location: this.media_location,
          submit_to_curator: this.submit_curator,
          discovery_media: this.$route.params.id,
        };
        this.loading = true;
        await Promise.all([
          this.post_add_quote_data_api(payload)
            .then(() => {
              this.$toast.open({
                message: 'Quote is added successfully.',
                type: 'success',
                duration: 1000 * 5,
                dismissible: true,
              }),
                this.get_quote_data_api({ id: this.$route.params.id }),
                this.$router.go(-1);
            })
            .catch((error) => {
              this.$toast.open({
                message: 'Failed to add quote. Please try again.',
                type: 'error',
                duration: 1000 * 5,
                dismissible: true,
              });
              console.error('Failed to add quote:', error);
            }),
        ]);
        this.loading = false;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style></style>
