<template>
    <div :class="[
        'leftside sticky top-0 bg-gray-100 py-8 lg:mr-4 lg:rounded-2xl w-[320px] max-[767px]:mb-10',
        { 'show-filter': isFilterOpen }
    ]" @click.stop>
        <div class="px-5">
            <div class="flex justify-between mb-5 items-center">
                <h2 class="text-[24px] font-inter font-[600]">Filter Results</h2>
                <span @click="closeFilter" class="lg:hidden block">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                        class="bi bi-x-lg" viewBox="0 0 16 16">
                        <path
                            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                    </svg>
                </span>
            </div>
            <template v-if="groups_result && groups_result.result">
                <!-- Media Type Category -->
                <div class="category-container">
                    <CategoryItem :category="{
                        id: 'media_type',
                        name: 'Media Type',
                        isHeader: true,
                        children: mediaTypeChildren
                    }" :selected-filters="selectedFilters" :expanded-categories="expandedCategories"
                        :is-top-level="true" @toggle="toggleCategory" @filter-change="handleFilterChange" />
                </div>
                <!-- Existing Categories -->
                <div v-for="category in groups_result.result" :key="category.id">
                    <CategoryItem :category="category" :selected-filters="selectedFilters"
                        :expanded-categories="expandedCategories" :is-top-level="true" @toggle="toggleCategory"
                        @filter-change="handleFilterChange" />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue';
import CategoryItem from '../components/catagory_item_component.vue';
import { all_media_type_values } from '@/common_variables/media_type';

export default {
    name: 'LeftsideFilter',
    components: {
        CategoryItem
    },
    props: {
        groups_result: {
            type: Object,
            required: true
        },
        initialSelectedFilters: {
            type: Array,
            default: () => []
        },
        initialSelectedFilterNames: {
            type: Array,
            default: () => []
        },
        isFilterOpen: {
            type: Boolean,
            default: false
        }
    },
    emits: ['filter-change', 'close-filter'],
    setup(props, { emit }) {
        const selectedFilters = reactive({});
        const expandedCategories = reactive({});

        // Initialize media type children for the filter
        const mediaTypeChildren = Object.values(all_media_type_values).map(type => ({
            id: type.media_type_key,
            name: type.singular,
            count: 0,
            isHeader: false
        }));

        // Close filter handler
        const closeFilter = () => {
            emit('close-filter');
        };

        // Toggle category expansion
        const toggleCategory = (categoryId) => {
            expandedCategories[categoryId] = !expandedCategories[categoryId];
        };

        // Handle filter change
        const handleFilterChange = (id, name, isSelected) => {
            selectedFilters[id] = isSelected;
            emit('filter-change', { id, name, isSelected });
        };

        return {
            selectedFilters,
            expandedCategories,
            mediaTypeChildren,
            closeFilter,
            toggleCategory,
            handleFilterChange
        };
    },
    created() {
        // Initialize filters from the groups result
        this.initializeFilters();

        // Pre-select filters based on props
        this.applyInitialFilters();

        // Expand first-level categories by default
        this.expandFirstLevelCategories();
    },
    watch: {
        'groups_result': {
            handler() {
                this.initializeFilters();
                // Re-expand first-level categories when groups_result changes
                this.expandFirstLevelCategories();
            },
            deep: true
        },
        'initialSelectedFilters': {
            handler() {
                this.applyInitialFilters();
            },
            deep: true
        }
    },
    methods: {
        initializeFilters() {
            if (!this.groups_result?.result) return;

            const initializeCategory = (categories) => {
                categories.forEach(category => {
                    // Initialize expanded state for categories
                    if (this.expandedCategories[category.id] === undefined) {
                        this.expandedCategories[category.id] = false;
                    }

                    // Initialize selection state
                    if (this.selectedFilters[category.id] === undefined) {
                        this.selectedFilters[category.id] = false;
                    }

                    // Recursively process children
                    if (category.children) {
                        initializeCategory(category.children);
                    }
                });
            };

            // Initialize media type filters
            this.mediaTypeChildren.forEach(type => {
                if (this.selectedFilters[type.id] === undefined) {
                    this.selectedFilters[type.id] = false;
                }
            });

            // Initialize all category filters
            initializeCategory(this.groups_result.result);
        },

        applyInitialFilters() {
            // Reset all filters first
            Object.keys(this.selectedFilters).forEach(key => {
                this.selectedFilters[key] = false;
            });

            // Apply the initial filters
            this.initialSelectedFilters.forEach(filterId => {
                this.selectedFilters[filterId] = true;
            });
        },

        expandFirstLevelCategories() {
            // Expand Media Type by default
            this.expandedCategories['media_type'] = true;

            // Expand only the root categories
            if (this.groups_result?.result) {
                this.groups_result.result.forEach(category => {
                    if (category && category.id) {
                        // Set this root category to be expanded
                        this.expandedCategories[category.id] = true;
                    }
                });
            }
        }
    }
};
</script>

<style scoped>
@media (max-width: 1023px) {
    .leftside {
        position: fixed;
        left: -400px;
        height: 100%;
        overflow-y: auto;
        z-index: 50;
        top: 0;
        transition: 0.3s all ease;
    }

    .show-filter {
        left: 0;
    }
}
</style>