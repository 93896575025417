<template>
  <div class="w-full mx-auto font-inter">
    <HomeHeaders />
    <section class="px-5 bg-contain" :style="{
      backgroundImage: `url(${require('../../images/radial-shadow.png')})`,
      // backgroundSize: 'auto',
      // clipPath: 'ellipse(90% 100% at center top)'
    }">
      <div v-if="loading" class="loading-indicator">
        <div class="loader"></div>
      </div>
      <div v-else class=" font-inter">
        <div class=" p-4">
          <div class="flex justify-between items-center p-4 mb-6">
            <!-- <h2 class="text-4xl max-[1023px]:text-2xl font-semibold text-blue">Level</h2>
            <div class=" text-4xl max-[1023px]:text-2xl font-semibold">
              XP: <span class="text-[#4154f1] ">{{ profile.xp }}</span>
            </div> -->
          </div>
          <div class="mx-auto w-full min-[550px]:w-[80%] lg:w-[70%] pb-2 px-5">
            <div class="flex justify-center mb-6 max-[1023px]:mb-3">
              <div class="text-4xl max-[1023px]:text-2xl font-semibold">
                Level: <span class="text-blue">{{ profile.level }}</span>
              </div>
              <!-- <div class="text-4xl max-[1023px]:text-2xl font-semibold">
                XP: <span class="text-blue">{{ profile.xp }}</span>
              </div> -->
            </div>

            <div class="relative bg-[#d6dcf4] mb-3 h-5 sm:h-8 w-full rounded-full">
              <span class="absolute h-5 sm:h-8 bg-blue rounded-full" :style="{ width: profile.progress + '%' }"></span>
            </div>
            <!-- <div class="flex flex-wrap font-semibold justify-center gap-8 text-sm text-gray-600">
              <div class="flex items-center text-black gap-2">
                <svg class="w-4 h-4 text-[#4154f1]" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd" />
                </svg>
                <span>Watch 5 videos</span>
              </div>
              <div class="flex items-center gap-2">
                <svg class="w-4 h-4 text-[#4154f1]" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd" />
                </svg>
                <span>Create 3 user forms</span>
              </div>
              <div class="flex items-center gap-2">
                <svg class="w-4 h-4 text-[#4154f1]" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd" />
                </svg>
                <span>Add 2 Journal entries</span>
              </div>
            </div> -->

          </div>
        </div>
        <div class=" justify-center  items-center py-6 flex gap-2">
          <div class="md:block hidden w-40">
            <img src="../../images/badge.png" alt="Badges" />
          </div>
          <h2 class="sm:text-3xl text-2xl md:text-4xl font-semibold text-center ">Badges</h2>
        </div>
        <div class="flex justify-center pb-4  gap-3 sm:gap-6 lg:text-2xl text-sm  xs:text-xl font-semibold">
          <a href="#" @click.prevent="fetchBadgesByType('curiosity')" :class="[
            badgeType === 'curiosity' ? 'text-blue border-b-2 border-blue pb-2' : 'text-gray-500 hover:text-blue transition-colors pb-2'
          ]">
            Curiosity Badges
          </a>
          <a href="#" @click.prevent="fetchBadgesByType('statistical')" :class="[
            badgeType === 'statistical' ? 'text-blue border-b-2 border-blue pb-2' : 'text-gray-500 hover:text-blue transition-colors pb-2'
          ]">
            Statistical Badges
          </a>
        </div>
        <!-- Badges Start -->
        <div v-if="badge_stats_data.badge.length > 0" class="mt-6">
          <ul
            class="grid xxl:grid-cols-6 lg:grid-cols-5 md:grid-cols-4 min-[550px]:grid-cols-3 min-[400px]:grid-cols-2 gap-3 lg:gap-5 w-full">
            <BadgeStatsComponent v-for="(badge_stats_data, index) in badge_stats_data.badge" :key="index"
              :stats_data="badge_stats_data" @click="showBadgeModal(badge_stats_data)" />
          </ul>
        </div>
        <div v-else class="mt-6">No Badge Available</div>
        <!-- Badges End -->
      </div>
      <!-- Add the Badge Modal -->
      <!-- Add the Badge Modal - only shown for active badges -->
      <BadgeModal v-if="selectedBadge && selectedBadge.is_active" :is-open="isModalOpen" :badge-data="selectedBadge"
        @close="closeModal" />
      <div class="recently-bottom-border border-b-1 border-borderColor mt-20 mb-4 mx-20"></div>
    </section>

    <HomeFooter />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import HomeHeaders from '@/common_components/header/header.vue';
import HomeFooter from '@/common_components/footer/footer.vue';
import BadgeStatsComponent from './component/badge_stats_component.vue';
import BadgeModal from './component/badge_modal_component.vue'; // Import the new modal component

export default {
  name: 'StatsScreen',
  components: {
    HomeHeaders,
    HomeFooter,
    BadgeStatsComponent,
    BadgeModal,
  },

  async created() {
    await this.fetchDataFromApis();
    await this.fetchProfile();
  },

  computed: {
    ...mapState({
      badge_stats_data: (state) => state.stats_store.badge_stats_data,
    }),
  },

  data() {
    return {
      loading: true,
      isModalOpen: false,
      selectedBadge: null,
      badgeType: 'curiosity', //  default type
      profile: {
        first_name: '',
        last_name: '',
        full_name: '',
        email: '',
        avatar: null,
        age: null,
        new_password: '',
        confirm_new_password: '',
        level: 0,
        xp: 0,
        progress: 0
      },
    };
  },

  methods: {
    ...mapActions('stats_store', ['badge_stats_api', 'badge_curoisity_api']),
    ...mapActions('profile_setting_store', ['fetch_profile']),

    async fetchBadgesByType(type) {
      this.loading = true;
      this.badgeType = type;
      try {
        if (type === 'curiosity') {
          await this.badge_curoisity_api();
        } else {
          await this.badge_stats_api();
        }
      } catch (error) {
        console.error('Error fetching badges:', error);
      } finally {
        this.loading = false;
      }
    },

    async fetchDataFromApis() {
      this.loading = true;
      try {
        await this.badge_curoisity_api(); // Default to statistical badges
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.loading = false;
      }
    },


    showBadgeModal(badge) {
      if (badge.is_active) {
        this.selectedBadge = badge;
        this.isModalOpen = true;
      }
    },

    closeModal() {
      this.isModalOpen = false;
      this.selectedBadge = null;
    },

    async fetchProfile() {
      try {
        const response = await this.$store.dispatch('profile_setting_store/fetch_profile');
        this.profile = {
          ...this.profile,
          level: response.level,
          xp: response.xp,
          progress: response.progress
        };
      } catch (error) {
        console.error('Error fetching profile:', error);
        this.$toast.open({
          message: 'Error fetching profile data',
          type: 'error',
          duration: 1000 * 5,
          dismissible: true,
        });
      }
    },
  },
};
</script>

<style>
@import '../../css/style.css';
@import '../../css/discovery.css';
</style>