<template>
  <div @click="
    media_data_navigation(
      favorite_list_data.entity_id,
      favorite_list_data.entity_type,
      favorite_list_data.name,
      favorite_list_data.fav_type,
      favorite_list_data.linked_media_id,
      favorite_list_data.linked_media_type,
    )
    " class="rounded-2xl border-1 border-gray-200 overflow-hidden cursor-pointer swiper-slide ">
    <div class="">
      <div class="h-48 vxs:h-40 xs:h-48">
        <img :src="favorite_list_data.image" :alt="favorite_list_data.name" class="w-full h-full" />
      </div>
      <div class="bg-white w-full py-2 p-3">
        <div class=" w-full">
          <div class="flex justify-between mb-1 items-center">
            <span class="text-xs">{{
              favorite_list_data.year_pub || ''
            }}</span>
            <span class="bg-blue px-2 py-1 text-white text-xs text-center rounded-full">{{
              favorite_list_data.fav_type
            }}</span>
          </div>
          <p class="text-md font-bold truncate ">
            {{ favorite_list_data.name }}
          </p>
          <div class="flex p-1">
            <div class="flex items-center">
              <MediaLikeComponent :identify_media_data="{
                id: favorite_list_data.entity_id,
                entity: favorite_list_data.entity_type,
                name: favorite_list_data.name,
                like: true,
                is_fav: true,
              }" />
              <span class="text-md mx-3">Like</span>
            </div>
          </div>
          <p v-if="favorite_list_data.genre_or_media" class="text-xs text-gray-500 mb-1 h-4 truncate">
            {{ favorite_list_data.genre_or_media }}
          </p>
          <p v-else class="text-xs mb-1 text-gray-500 h-4">
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { media_page_navigation } from '../common_routing/media_page_routing';
import MediaLikeComponent from '@/common_components/media_fav_icon/media_like_component.vue';
import { specific_page_routing } from '../common_routing/specific_data_routing';
import { all_entity_type_values } from '@/common_variables/entity_type';
export default {
  name: 'FavCardComponent',
  components: {
    MediaLikeComponent,
  },
  props: {
    favorite_list_data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    media_data_navigation(id, type, name, fav_type, media_id, media_type) {
      if (Object.keys(all_entity_type_values).includes(type)) {
        if (media_id && media_type) {
          media_page_navigation(this.$router, media_id, name, media_type);
        } else {
          specific_page_routing(this.$router, id, type);
        }
      } else {
        media_page_navigation(this.$router, id, name, type);
      }
    },
  },
};
</script>

<style></style>
