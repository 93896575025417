<!-- LeftSidebar.vue -->
<template>
    <div class="leftside px-[12px] py-4 overflow-y-auto custom-scrollbar w-[250px] h-full fixed z-50 transition-transform duration-300 bg-white"
        :class="[
            isMobileOpen ? 'translate-x-0' : '-translate-x-[250px]',
            'lg:translate-x-0'
        ]">
        <!-- Logo Section -->
        <div class="logo flex justify-center items-center mb-4">
            <router-link to="/">
                <img src="../../../images/logo.png" alt="Logo" class="h-10" />
            </router-link>
        </div>

        <!-- Navigation Options -->
        <div class="options my-3">
            <!-- My Allies -->
            <router-link to="/chat/allies"
                class="flex gap-3 items-center text-[15px] font-[500] px-4 py-2 cursor-pointer rounded-lg transition-colors duration-200"
                :class="[
                    isActiveRoute('/chat/allies')
                        ? 'bg-gray-200 text-blue'
                        : 'text-[#949BA4] hover:bg-gray-200 hover:text-blue'
                ]">
                <span>
                    <img src="../../../images/Vector.png" alt="Allies Icon" />
                </span>
                <p>My Allies</p>
            </router-link>

            <!-- My Groups -->
            <router-link to="/chat/groups"
                class="flex gap-3 items-center text-[15px] font-[500] px-4 py-2 cursor-pointer rounded-lg transition-colors duration-200"
                :class="[
                    isActiveRoute('/chat/groups')
                        ? 'bg-gray-200 text-blue'
                        : 'text-[#949BA4] hover:bg-gray-200 hover:text-blue'
                ]">
                <span>
                    <img src="../../../images/group.png" alt="Groups Icon" />
                </span>
                <p>My Groups</p>
            </router-link>

            <!-- Manage Challenges -->
            <router-link to="/chat/manage_events"
                class="flex gap-3 items-center text-[15px] font-[500] px-4 py-2 cursor-pointer rounded-lg transition-colors duration-200"
                :class="[
                    isActiveRoute('/chat/manage_events')
                        ? 'bg-gray-200 text-blue'
                        : 'text-[#949BA4] hover:bg-gray-200 hover:text-blue'
                ]">
                <span>
                    <img src="../../../images/group.png" alt="Challenges Icon" />
                </span>
                <p>Manage Challenges</p>
            </router-link>

            <!-- Tasks Management -->
            <router-link to="/chat/task_manage"
                class="flex gap-3 items-center text-[15px] font-[500] px-4 py-2 cursor-pointer rounded-lg transition-colors duration-200"
                :class="[
                    isActiveRoute('/chat/task_manage')
                        ? 'bg-gray-200 text-blue'
                        : 'text-[#949BA4] hover:bg-gray-200 hover:text-blue'
                ]">
                <span>
                    <img src="../../../images/group.png" alt="Tasks Icon" />
                </span>
                <p>Tasks Management</p>
            </router-link>

            <!-- Earn Points -->
            <router-link to="/chat/earn_points"
                class="flex gap-3 items-center text-[15px] font-[500] px-4 py-2 cursor-pointer rounded-lg transition-colors duration-200"
                :class="[
                    isActiveRoute('/chat/earn_points')
                        ? 'bg-gray-200 text-blue'
                        : 'text-[#949BA4] hover:bg-gray-200 hover:text-blue'
                ]">
                <span>
                    <img src="../../../images/group.png" alt="Points Icon" />
                </span>
                <p>Earn points</p>
            </router-link>
        </div>

        <!-- Direct Messages Section -->
        <div class="chat">
            <div class="flex justify-between items-center cursor-pointer text-[#949BA4] text-sm mb-4 relative">
                <h2>DIRECT MESSAGES</h2>
                <button @click.stop="toggleMenu"
                    class="relative text-2xl hover:text-blue transition-colors duration-200">
                    +
                </button>
            </div>

            <!-- Moved Popup Menu outside the sidebar -->
            <Teleport to="body">
                <div v-if="isMenuOpen" class="fixed inset-0 left-40 top-52 z-50" @click="closeMenu">
                    <div ref="menu" class="bg-white rounded-lg shadow-lg w-[320px] max-h-[80vh] overflow-y-auto m-4"
                        @click.stop>
                        <h3 class="text-sm font-semibold p-4 border-b">Add into Direct Messages</h3>
                        <div class="p-2">
                            <div v-for="user in availableUsers" :key="user.id"
                                class="flex items-center gap-2 p-2 hover:bg-gray-100 rounded cursor-pointer">
                                <img src="../../../images/ziaumer.png" :alt="user.name" class="w-12 rounded-full" />
                                <div class="flex-1">
                                    <p class="text-sm font-medium">{{ user.name }}</p>
                                    <p class="text-xs text-gray-500">{{ user.isOnline ? "Online" : "Offline" }}</p>
                                </div>
                                <button @click="addToDirectMessages(user)"
                                    class="w-6 h-6 flex items-center justify-center rounded-full bg-gray-200 hover:bg-gray-300">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                                        class="bi bi-chat-dots-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M16 8c0 3.866-3.582 7-8 7a9 9 0 0 1-2.347-.306c-.584.296-1.925.864-4.181 1.234-.2.032-.352-.176-.273-.362.354-.836.674-1.95.77-2.966C.744 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7M5 8a1 1 0 1 0-2 0 1 1 0 0 0 2 0m4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Teleport>
            <!-- Chat Users List -->
            <div class="space-y-2">
                <router-link to="/chat/single_chat">
                    <div v-for="user in chatUsers" :key="user.id"
                        class="flex gap-2 text-[15px] font-[500] text-[#959BA3] py-1 items-center cursor-pointer hover:bg-gray-100 rounded-lg px-2"
                        @click="openChat(user)">
                        <div class="w-12 rounded-full relative">
                            <img src="../../../images/ziaumer.png" :alt="user.name" class="rounded-full" />
                            <img src='../../../images/offline.png' :alt="user.isOnline ? 'online' : 'offline'"
                                class="absolute bottom-1 right-1 bg-white rounded-full w-3 h-3" />
                        </div>
                        <div>{{ user.name }}</div>
                    </div>
                </router-link>
            </div>
        </div>

        <!-- Current User Profile -->
        <div class="open-chat absolute bottom-0 left-0 right-0 border-t px-4 py-3 bg-white">
            <div class="flex justify-between items-center text-[15px] font-[500] text-[#959BA3]">
                <div class="flex items-center gap-2">
                    <div class="w-10 rounded-full relative">
                        <img src="../../../images/ziaumer.png" :alt="currentUser.name" class="rounded-full" />
                        <img src='../../../images/offline.png' :alt="currentUser.isOnline ? 'online' : 'offline'"
                            class="absolute bottom-1 right-1 bg-white rounded-full w-2 h-2" />
                    </div>
                    <div class="text-xs">
                        <p>{{ currentUser.name }}</p>
                        <p>#{{ currentUser.id }}</p>
                    </div>
                </div>
                <button @click="openSettings"
                    class="w-8 h-8 flex items-center justify-center rounded-full hover:bg-gray-100 transition-colors duration-200">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-gear" viewBox="0 0 16 16">
                        <path
                            d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0" />
                        <path
                            d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LeftSidebar',
    props: {
        isMobileOpen: {
            type: Boolean,
            default: false
        }
    },
    emits: ['closeSidebar'],

    data() {
        return {
            isMenuOpen: false,
            chatUsers: [
                {
                    id: 1,
                    name: 'Lazar Nikolov',
                    avatar: '../../../images/ziaumer.png',
                    isOnline: false
                },
                {
                    id: 2,
                    name: 'Zia Umar',
                    avatar: '../../../images/ziaumer.png',
                    isOnline: false
                },
                {
                    id: 3,
                    name: 'Erik Taylor',
                    avatar: '../../../images/ziaumer.png',
                    isOnline: false
                },
                // Add more users as needed
            ],
            availableUsers: [
                { id: 4, name: 'Tom Ford', avatar: '../../../images/tomford.png', isOnline: false },
                { id: 5, name: 'Stella McCartney', avatar: '../../../images/stella.png', isOnline: true },
                { id: 6, name: 'Golden Fil', avatar: '../../../images/tomford.png', isOnline: false },
            ],
            currentUser: {
                id: '001',
                name: 'Lazar Nikolov',
                avatar: '../../../images/ziaumer.png',
                isOnline: true
            }
        }
    },

    methods: {
        isActiveRoute(path) {
            return this.$route.path === path;
        },

        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        closeMenu(event) {
            if (this.$refs.menu && !this.$refs.menu.contains(event.target)) {
                this.isMenuOpen = false;
            }
        },

        addToDirectMessages(user) {
            if (!this.chatUsers.find(u => u.id === user.id)) {
                this.chatUsers.push(user);
            }
            this.isMenuOpen = false;
        },

        openChat(user) {
            // Implement chat opening functionality
            console.log('Opening chat with user:', user.name);
        },

        openSettings() {
            // Implement settings functionality
            console.log('Opening settings');
        }
    },
    mounted() {
        document.addEventListener("click", this.closeMenu);
    },
    beforeUnmount() {
        document.removeEventListener("click", this.closeMenu);
    },
}
</script>

<!-- <style scoped>
.custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #949BA4 transparent;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #949BA4;
    border-radius: 20px;
}

/* Ensure the component takes full height */
.leftside {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

/* Create space for the fixed bottom profile section */
.chat {
    flex: 1;
    overflow-y: auto;
    padding-bottom: 80px;
    /* Adjust based on your profile section height */
}
</style> -->