<template>
  <div class="w-full  mx-auto">
    <!-- Header Start -->
    <HomeHeader />
    <!-- Header End -->
    <template v-if="loading">
      <div class="loading-indicator">
        <div class="loader"></div>
      </div>
    </template>
    <template v-else>
      <div v-if="search_satisfy_result.result.has_results" class="mx-auto font-inter px-5 py-10 ">
        <button
          class="text-blue xs:px-6 xs:py-3 px-4 py-2 sm:text-lg font-medium border-1 border-blue rounded-2xl hover:text-white hover:bg-blue duration-500"
          @click="goBack">
          Back
        </button>
        <div class="">
          <div
            class="xs:flex gap-2 xs:gap-6 p-4 sm:px-6 items-center justify-center bg-blue text-white rounded-2xl my-10 text-center">
            <img src="../../images/idea.png" alt="Bulb" class="w-24 xs:mx-0 mx-auto sm:w-32 max-[640px]:mb-3" />
            <p class="lg:text-2xl sm:text-xl vsx:text-md text-sm font-bold ">
              Here are your results! Try clicking on one for more details.
            </p>
          </div>

          <div class="flex flex-col  rounded-2xl py-8 my-10 max-[640px]:py-6 max-[640px]:my-5">
            <h2 class="text-4xl max-[640px]:text-2xl font-bold text-center w-full mb-9 max-[640px]:mb-5 text-blue">
              Here is what I found for “{{ search_topic_display }}”
            </h2>
            <div class="text-black my-2 flex flex-col">
              <span class="text-xl font-semibold mb-5">Related topics you might be interested in:</span>
              <div class="flex flex-wrap gap-2">
                <template v-for="(topics, index) in localSatisfyCuriosityOption" :key="index">
                  <div @click="related_topic_search(topics)"
                    class="flex justify-center items-center border-1 border-blue rounded-lg sm:rounded-xl px-3 py-1 cursor-pointer">
                    <span class="text-xs sm:text-sm lg:text-base text-blue ml-1">{{ topics }}</span>
                  </div>
                </template>
              </div>
            </div>
            <template v-for="(value, key) in search_satisfy_result.result" :key="key">
              <template v-if="!exclude_params.includes(key) && value.count != 0">
                <SearchedResults :key_prop="key" :value_prop="value" :search_topic_display="search_topic_display" />
              </template>
            </template>
          </div>
        </div>
      </div>

      <div v-else class="mx-auto  px-5 py-10 font-inter">
        <button
         class="text-blue xs:px-6 xs:py-3 px-4 py-2 sm:text-lg font-medium border-1 border-blue rounded-2xl hover:text-white hover:bg-blue duration-500"
          @click="goBack">
          Back
        </button>
        <div class="max-[640px]:px-5">

          <div
            class="xs:flex gap-2 xs:gap-6 p-4 sm:px-6 items-center justify-center bg-blue text-white rounded-2xl my-10 text-center">
            <img src="../../images/idea.png" alt="Bulb" class="w-24 xs:mx-0 mx-auto sm:w-32 max-[640px]:mb-3" />
            <p class="lg:text-2xl sm:text-xl vsx:text-md text-sm font-bold ">
              I couldn't find anything for “{{ search_topic_display }}”
            </p>
          </div>

          <!-- <div
            class="flex items-center max-[640px]:flex-col bg-white border-1 border-borderColor rounded-[36px] p-12 my-10 max-[640px]:p-6 max-[640px]:my-5">
            <img src="../../images/bulb.png" alt="Bulb" class="max-[640px]:mb-3" />
            <div class="flex flex-col ml-14 max-[640px]:ml-7">
              <p class="text-2xl font-bold max-[640px]:text-lg mb-4">
                I couldn't find anything for “{{ search_topic_display }}”
              </p>
              <p class="text-xl text-[#404558] opacity-75">
                Press back to adjust your search.
                Alternatively, you can use the
                'Feedback' button to send a request to our curator team to add
                this topic, or create your own Media record for this topic.
              </p>
            </div>
          </div> -->

          <div v-if="localSatisfyCuriosityOption.length > 0"
            class="flex flex-col bg-white border-1 border-borderColor rounded-[36px] p-12 my-10 max-[640px]:p-6 max-[640px]:my-5">
            <div class="text-black my-2 flex flex-col">
              <span class="text-xl font-semibold mb-5">Related topics you might be interested in:</span>
              <div class="flex flex-wrap gap-2">
                <template v-for="(topics, index) in localSatisfyCuriosityOption" :key="index">
                  <div @click="related_topic_search(topics)"
                    class="flex justify-center items-center border-1 border-blue rounded-full px-3 py-1 cursor-pointer">
                    <span class="text-xl text-blue ml-1">{{ topics }}</span>
                  </div>
                </template>
              </div>
            </div>
          </div>

          <div
            class="flex flex-col items-center bg-white  p-12 my-10 max-[640px]:p-6 max-[640px]:my-5">
            <img src="../../images/result-not-found.png" alt="Result Not Found" class="max-w-[256px]" />
            <p class="text-4xl font-bold opacity-40 mt-5">Result Not Found</p>
          </div>
        </div>
      </div>
      <div class="recently-bottom-border border-b-1 border-borderColor mt-10 mb-7"></div>
    </template>
    <!-- Footer Start -->
    <HomeFooter />
    <!-- Footer End -->
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import HomeHeader from '@/common_components/header/header.vue';
import HomeFooter from '@/common_components/footer/footer.vue';
import SearchedResults from './components/searched_results_component.vue';

export default {
  name: 'SearchSatisfy',
  components: {
    HomeHeader,
    HomeFooter,
    SearchedResults,
  },

  async created() {
    this.reset_search_satisfy_result_list();
    await this.fetchDataFromApis(
      this.$route.params.search_topic,
      this.$route.params.search_interest === ''
        ? ''
        : this.$route.params.search_interest,
      this.page,
    );
  },

  data() {
    return {
      loading: false,
      show_more_result: false,
      page: 1,
      exclude_params: ['has_results', 'search_query'],
      search_topic_display: this.$route.params.search_topic,
      previously_selected_topic: null,
      localSatisfyCuriosityOption: [],
    };
  },

  computed: {
    ...mapState({
      search_satisfy_result: (state) =>
        state.search_satisfy_store.search_satisfy_result,
      satisfy_curiosity_option: (state) =>
        state.search_satisfy_store.satisfy_curiosity_option,
    }),
  },

  watch: {
    satisfy_curiosity_option: {
      immediate: true,
      handler(newVal) {
        this.localSatisfyCuriosityOption = [...newVal];
      },
    },
  },

  methods: {
    ...mapActions('search_satisfy_store', [
      'search_satisfy_result_api',
      'satisfy_curiosity_option_api',
    ]),
    ...mapMutations('search_satisfy_store', [
      'reset_search_satisfy_result_list',
    ]),

    async related_topic_search(related_topic) {
      this.loading = true;
      try {
        await Promise.all([
          this.search_satisfy_result_api({
            search_query: related_topic,
            description:
              this.$route.params.search_interest === ''
                ? ''
                : this.$route.params.search_interest,
            page: this.page,
          }),
        ]);

        if (this.previously_selected_topic) {
          this.localSatisfyCuriosityOption.push(this.previously_selected_topic);
        }

        this.previously_selected_topic = related_topic;
        this.localSatisfyCuriosityOption =
          this.localSatisfyCuriosityOption.filter(
            (topic) => topic !== related_topic,
          );
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.loading = false;
      }
      this.search_topic_display = related_topic;
    },

    goBack() {
      this.$router.go(-1);
    },

    async fetchDataFromApis(search_topic, search_interest, page) {
      this.loading = true;
      try {
        await Promise.all([
          this.search_satisfy_result_api({
            search_query: search_topic,
            description: search_interest,
            page: page,
          }),
          this.satisfy_curiosity_option_api({
            query: this.$route.params.search_topic,
          }),
        ]);
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
