<template>
  <div @click="
    detail_navigation(
      activity.activity.id,
      activity.activity.entity,
      activity.activity.name,
      activity.activity.linked_media_id,
      activity.activity.linked_media_type,
    )
    " class="border-1 border-borderColor p-5 rounded-2xl cursor-pointer"
    :style="{ backgroundImage: `url(${require('../../../../../images/card2.png')})` }">
    <div class="xs:flex xs:h-[400px] ">
      <div class="flex  flex-col  xs:min-w-40 lg:min-w-52 xs:h-full">
        <div class=" overflow-hidden lg:h-60  mx-auto w-60 xs:w-full h-48 rounded-2xl">
          <img :src="activity.activity.image" :alt="activity.activity.name" class="w-full h-full" />
        </div>
        <div class="flex flex-col items-center w-full ">
          <div class="flex justify-center mt-3 max-[560px]:flex-col">
            <div class="flex items-center">
              <MediaLikeComponent :identify_media_data="activity.activity" />
              <span class="text-xl mx-3">Like</span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex  overflow-y-hidden hover:overflow-y-scroll h-[300px] xs:h-full flex-col xs:ml-2 xxl:ml-5 w-full ">
        <h2 class="text-[28px] font-bold mb-2 mr-2 max-[640px]:text-[24px]">
          {{ activity.activity.name }}
        </h2>
        <!-- <p class="text-sm mt-3 h-[100px] overflow-y-auto pr-3">
                    <template v-if="activity.activity.description">
                        <p class="py-1"><b>Description:</b></p>
                        {{ activity.activity.description }}
                    </template>
<template v-if="activity.description">
                        <p class="py-1"><b>Title Specific Description:</b></p>
                        {{ activity.description }}
                    </template>
</p> -->
        <template v-if="activity.activity.description">
          <p class="py-1">
            <span><b>Description:</b></span>
          </p>
          <p class="text-sm mt-1 h-[100px] overflow-y-auto pr-3">
            {{ activity.activity.description }}
          </p>
        </template>
        <template v-if="activity.description">
          <p class="py-1 mt-1">
            <span><b>Title Specific Description:</b></span>
          </p>
          <p class="text-sm mt-1 h-[100px] overflow-y-auto pr-3">
            {{ activity.description }}
          </p>
        </template>
        <p class="text-black my-2">
          <span class="text-sm font-semibold">Minimum Participants:&nbsp;</span>
          <span class="text-sm opacity-40">{{
            activity.activity.no_of_participants
          }}</span>
        </p>
        <template v-if="activity.activity.places.length > 0">
          <div class="text-black my-2 flex">
            <span class="text-sm font-semibold mr-2">Location:</span>
            <div class="flex flex-wrap gap-2">
              <template v-for="(place, index) in activity.activity.places" :key="index">
                <div class="flex justify-center items-center border-1 border-blue rounded-full px-3 py-1">
                  <span class="text-[10px] text-blue ml-1">{{ place }}</span>
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import MediaLikeComponent from '@/common_components/media_fav_icon/media_like_component.vue';
import { specific_page_routing } from '@/common_components/common_routing/specific_data_routing';
import { media_page_navigation } from '@/common_components/common_routing/media_page_routing';
export default {
  name: 'ActivitiesCardComponent',
  components: {
    MediaLikeComponent,
  },
  props: {
    activity: {
      type: Object,
    },
  },
  data() {
    return {
      isLike: this.activity.activity.like,
    };
  },
  methods: {
    detail_navigation(
      id,
      component,
      media_name = null,
      media_id = null,
      media_type = null,
    ) {
      if (media_id && media_type) {
        media_page_navigation(this.$router, media_id, media_name, media_type);
      } else {
        specific_page_routing(this.$router, id, component);
      }
    },
  },
};
</script>

<style scoped>
/* Add your component-specific styles here */
</style>
