<template>
  <div class="all-invites  border-x border-gray-500 max-w-[700px] mx-auto py-10 px-3 sm:p-10">
    <div class="search-name flex rounded-full mb-5 overflow-hidden bg-white ">
      <input class="w-full p-4 focus:outline-none" type="text" placeholder="Search ...">
      <span class="bg-blue rounded-full flex justify-center items-center text-white w-24">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-search"
          viewBox="0 0 16 16">
          <path
            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
        </svg>
      </span>
    </div>
    <p class="text-xl mb-4 font-semibold">All Invites</p>
    <div class="bg-white shadow-xl mb-4 flex gap-2 text-[15px] font-[500] sm:px-8 p-4 rounded-lg items-center">
      <div class="w-16 rounded-full relative">
        <img src="../../../images/ziaumer.png" alt="image" />
        <img class="absolute bottom-1 bg-white rounded-full right-1" src="../../../images/offline.png" alt="image" />
      </div>
      <div class="flex justify-between items-center w-full">
        <div>
          <p>Lazar Nikolov</p>
          <p class="text-xs text-[#959BA3]">Online</p>
        </div>
        <div class="flex items-center gap-5">
          <span class="cursor-pointer">
            <img src="../../../images/chat.png" alt="image" />
          </span>
          <span @click.stop="toggleMenu" class="cursor-pointer relative">
            <img src="../../../images/more.png" alt="image" />
            <div v-if="isMenuOpen" ref="menu"
              class="absolute -right-5 xl:-right-36 xl:bottom-0 mt-2 w-40 bg-white border rounded shadow-lg">
              <button @click="removeAlly" class="block w-full px-4 py-2 text-left text-red-600 hover:bg-gray-100">
                ⛔ Remove ally
              </button>
            </div>
          </span>
        </div>
      </div>
    </div>
    <div class="bg-white shadow-xl mb-4 flex gap-2 text-[15px] font-[500] sm:px-8 p-4 rounded-lg items-center">
      <div class="w-16 rounded-full relative">
        <img src="../../../images/ziaumer.png" alt="image" />
        <img class="absolute bottom-1 bg-white rounded-full right-1" src="../../../images/offline.png" alt="image" />
      </div>
      <div class="flex justify-between items-center w-full">
        <div>
          <p>Lazar Nikolov</p>
          <p class="text-xs text-[#959BA3]">Online</p>
        </div>
        <div class="flex items-center gap-5">
          <span class="cursor-pointer">
            <img src="../../../images/chat.png" alt="image" />
          </span>
          <span @click.stop="toggleMenu" class="cursor-pointer relative">
            <img src="../../../images/more.png" alt="image" />
            <div v-if="isMenuOpen" ref="menu"
              class="absolute -right-5 xl:-right-36 xl:bottom-0 mt-2 w-40 bg-white border rounded shadow-lg">
              <button @click="removeAlly" class="block w-full px-4 py-2 text-left text-red-600 hover:bg-gray-100">
                ⛔ Remove ally
              </button>
            </div>
          </span>
        </div>
      </div>
    </div>
    <div class="bg-white shadow-xl mb-4 flex gap-2 text-[15px] font-[500] sm:px-8 p-4 rounded-lg items-center">
      <div class="w-16 rounded-full relative">
        <img src="../../../images/ziaumer.png" alt="image" />
        <img class="absolute bottom-1 bg-white rounded-full right-1" src="../../../images/offline.png" alt="image" />
      </div>
      <div class="flex justify-between items-center w-full">
        <div>
          <p>Lazar Nikolov</p>
          <p class="text-xs text-[#959BA3]">Online</p>
        </div>
        <div class="flex items-center gap-5">
          <span class="cursor-pointer">
            <img src="../../../images/chat.png" alt="image" />
          </span>
          <span @click.stop="toggleMenu" class="cursor-pointer relative">
            <img src="../../../images/more.png" alt="image" />
            <div v-if="isMenuOpen" ref="menu"
              class="absolute -right-5 xl:-right-36 xl:bottom-0 mt-2 w-40 bg-white border rounded shadow-lg">
              <button @click="removeAlly" class="block w-full px-4 py-2 text-left text-red-600 hover:bg-gray-100">
                ⛔ Remove ally
              </button>
            </div>
          </span>
        </div>
      </div>
    </div>
    <div class="bg-white shadow-xl mb-4 flex gap-2 text-[15px] font-[500] sm:px-8 p-4 rounded-lg items-center">
      <div class="w-16 rounded-full relative">
        <img src="../../../images/ziaumer.png" alt="image" />
        <img class="absolute bottom-1 bg-white rounded-full right-1" src="../../../images/offline.png" alt="image" />
      </div>
      <div class="flex justify-between items-center w-full">
        <div>
          <p>Lazar Nikolov</p>
          <p class="text-xs text-[#959BA3]">Online</p>
        </div>
        <div class="flex items-center gap-5">
          <span class="cursor-pointer">
            <img src="../../../images/chat.png" alt="image" />
          </span>
          <span @click.stop="toggleMenu" class="cursor-pointer relative">
            <img src="../../../images/more.png" alt="image" />
            <div v-if="isMenuOpen" ref="menu"
              class="absolute -right-5 xl:-right-36 xl:bottom-0 mt-2 w-40 bg-white border rounded shadow-lg">
              <button @click="removeAlly" class="block w-full px-4 py-2 text-left text-red-600 hover:bg-gray-100">
                ⛔ Remove ally
              </button>
            </div>
          </span>
        </div>
      </div>
    </div>
    <div class="bg-white shadow-xl mb-4 flex gap-2 text-[15px] font-[500] sm:px-8 p-4 rounded-lg items-center">
      <div class="w-16 rounded-full relative">
        <img src="../../../images/ziaumer.png" alt="image" />
        <img class="absolute bottom-1 bg-white rounded-full right-1" src="../../../images/offline.png" alt="image" />
      </div>
      <div class="flex justify-between items-center w-full">
        <div>
          <p>Lazar Nikolov</p>
          <p class="text-xs text-[#959BA3]">Online</p>
        </div>
        <div class="flex items-center gap-5">
          <span class="cursor-pointer">
            <img src="../../../images/chat.png" alt="image" />
          </span>
          <span @click.stop="toggleMenu" class="cursor-pointer relative">
            <img src="../../../images/more.png" alt="image" />
            <div v-if="isMenuOpen" ref="menu"
              class="absolute -right-5 xl:-right-36 xl:bottom-0 mt-2 w-40 bg-white border rounded shadow-lg">
              <button @click="removeAlly" class="block w-full px-4 py-2 text-left text-red-600 hover:bg-gray-100">
                ⛔ Remove ally
              </button>
            </div>
          </span>
        </div>
      </div>
    </div>
    <div class="bg-white shadow-xl mb-4 flex gap-2 text-[15px] font-[500] sm:px-8 p-4 rounded-lg items-center">
      <div class="w-16 rounded-full relative">
        <img src="../../../images/ziaumer.png" alt="image" />
        <img class="absolute bottom-1 bg-white rounded-full right-1" src="../../../images/offline.png" alt="image" />
      </div>
      <div class="flex justify-between items-center w-full">
        <div>
          <p>Lazar Nikolov</p>
          <p class="text-xs text-[#959BA3]">Online</p>
        </div>
        <div class="flex items-center gap-5">
          <span class="cursor-pointer">
            <img src="../../../images/chat.png" alt="image" />
          </span>
          <span @click.stop="toggleMenu" class="cursor-pointer relative">
            <img src="../../../images/more.png" alt="image" />
            <div v-if="isMenuOpen" ref="menu"
              class="absolute -right-5 xl:-right-36 xl:bottom-0 mt-2 w-40 bg-white border rounded shadow-lg">
              <button @click="removeAlly" class="block w-full px-4 py-2 text-left text-red-600 hover:bg-gray-100">
                ⛔ Remove ally
              </button>
            </div>
          </span>
        </div>
      </div>
    </div>
    
    
  </div>
</template>

<script>
export default {
  name: "AllsScreen",
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu(event) {
      if (this.$refs.menu && !this.$refs.menu.contains(event.target)) {
        this.isMenuOpen = false;
      }
    },
    removeAlly() {
      alert("Ally removed!");
      this.isMenuOpen = false;
    },
  },
  mounted() {
    document.addEventListener("click", this.closeMenu);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.closeMenu);
  },
};
</script>

<style>
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}
</style>