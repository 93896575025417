<template>
    <div>
        <HomeHeader />

        <!-- Loading Indicator (first one is redundant and incorrectly nested) -->
        <div v-if="loading" class="loading-indicator">
            <div class="loader"></div>
        </div>

        <div v-else class="border-2 border-black mx-auto px-4 py-8">
            <h1 class="text-3xl font-bold mb-6">
                {{ mediaTitle }}
            </h1>

            <div class="mb-6 flex items-center">
                <button @click="goBack" class="flex items-center text-blue hover:text-blue-700">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                        class="bi bi-arrow-left mr-2" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                    </svg>
                    Back to Search Results
                </button>
            </div>

            <!-- View Toggle -->
            <div class="mb-6 flex justify-end">
                <button @click="viewMode = 'grid'"
                    :class="['p-2 rounded mr-2', viewMode === 'grid' ? 'bg-blue text-white' : 'bg-gray-200']">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                        viewBox="0 0 16 16">
                        <path
                            d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a1.5 1.5 0 0 1-1.5-1.5v-3z" />
                    </svg>
                </button>
                <button @click="viewMode = 'list'"
                    :class="['p-2 rounded', viewMode === 'list' ? 'bg-blue text-white' : 'bg-gray-200']">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                        viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                    </svg>
                </button>
            </div>

            <!-- Grid View -->
            <div v-if="viewMode === 'grid'"
                class="grid xl:grid-cols-5 min-[1130px]:grid-cols-4 lg:grid-cols-3 md:grid-cols-4 min-[560px]:grid-cols-3 grid-cols-2 gap-2 xs:gap-4 max-[640px]:gap-y-4 mb-10">
                <div v-for="(item, index) in mediaItems" :key="index">
                    <CardComponent :card_data="item" card_page="search_page" />
                </div>
            </div>

            <!-- List View (changed from "Flat View" for consistency) -->
            <div v-else-if="viewMode === 'list'" class="sm:block hidden">
                <div v-for="(item, index) in mediaItems" :key="index">
                    <FlatCardComponent :card_data="item" card_page="search_page" />
                </div>
            </div>

            <!-- No Results -->
            <div v-if="mediaItems.length === 0" class="text-center py-10">
                <p class="text-xl text-gray-500">No results found for this media type.</p>
            </div>

            <!-- Pagination controls -->
            <div v-if="totalPages > 1" class="flex justify-center mt-8">
                <div class="flex space-x-2">
                    <button @click="changePage(currentPage - 1)" :disabled="currentPage === 1"
                        :class="['px-4 py-2 rounded', currentPage === 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue text-white']">
                        Previous
                    </button>

                    <div v-for="page in displayedPages" :key="page" class="flex items-center">
                        <button v-if="page !== '...'" @click="changePage(page)"
                            :class="['px-4 py-2 rounded', currentPage === page ? 'bg-blue-700 text-white' : 'bg-blue text-white']">
                            {{ page }}
                        </button>
                        <span v-else class="px-2">...</span>
                    </div>

                    <button @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages"
                        :class="['px-4 py-2 rounded', currentPage === totalPages ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue text-white']">
                        Next
                    </button>
                </div>
            </div>
        </div>

        <!-- Footer Start -->
        <HomeFooter />
        <!-- Footer End -->
    </div>
</template>

<script>
import CardComponent from '@/common_components/card/card_component.vue';
import FlatCardComponent from '@/common_components/card/flatcard_component.vue';
import HomeHeader from '@/common_components/header/header.vue';
import HomeFooter from '@/common_components/footer/footer.vue';
import { mapActions } from 'vuex';
import { all_entity_type_values } from '@/common_variables/entity_type';

export default {
    name: 'AllMedia',
    components: {
        CardComponent,
        FlatCardComponent,
        HomeHeader,
        HomeFooter
    },
    data() {
        return {
            loading: true,
            mediaItems: [],
            viewMode: 'grid',
            currentPage: 1,
            totalPages: 1,
            itemsPerPage: 20
        };
    },
    computed: {
        entityType() {
            return this.$route.params.entity_type;
        },
        searchQuery() {
            return this.$route.query.q || '';
        },
        filters() {
            const filterString = this.$route.query.filters || '';
            return filterString ? filterString.split(',') : [];
        },
        mediaTitle() {
            let temp_all_entity_type_values = JSON.parse(
                JSON.stringify(all_entity_type_values),
            );

            if (this.entityType === 'media') {
                temp_all_entity_type_values['media'] = {
                    entity_type_key: 'media',
                    singular: 'Media',
                    plural: 'Media',
                };
            }

            const entityName = temp_all_entity_type_values[this.entityType]?.plural || this.entityType;
            return this.searchQuery ?
                `All ${entityName} for "${this.searchQuery}"` :
                `All ${entityName}`;
        },
        displayedPages() {
            // Logic to display a limited number of page links
            if (this.totalPages <= 7) {
                return Array.from({ length: this.totalPages }, (_, i) => i + 1);
            }

            let pages = [1];

            if (this.currentPage > 3) {
                pages.push('...');
            }

            const startPage = Math.max(2, this.currentPage - 1);
            const endPage = Math.min(this.totalPages - 1, this.currentPage + 1);

            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }

            if (this.currentPage < this.totalPages - 2) {
                pages.push('...');
            }

            pages.push(this.totalPages);

            return pages;
        }
    },
    methods: {
        ...mapActions('home_search_store', ['home_search_result_api']),

        goBack() {
            this.$router.go(-1);
        },

        async fetchAllMedia() {
            this.loading = true;
            try {
                const response = await this.home_search_result_api({
                    search_query: this.searchQuery,
                    entity_type: this.entityType,
                    filters: this.filters,
                    page: this.currentPage
                });

                if (response.data && response.data.result && response.data.result[this.entityType]) {
                    this.mediaItems = response.data.result[this.entityType].results || [];

                    // Calculate total pages based on the total count and items per page
                    const totalCount = response.data.result[this.entityType].count || 0;
                    this.totalPages = Math.ceil(totalCount / this.itemsPerPage);
                } else {
                    this.mediaItems = [];
                    this.totalPages = 1;
                }
            } catch (error) {
                console.error('Error fetching media data:', error);
                this.mediaItems = [];
                this.totalPages = 1;
            } finally {
                this.loading = false;
            }
        },

        changePage(page) {
            if (page >= 1 && page <= this.totalPages && page !== this.currentPage) {
                this.currentPage = page;
                this.fetchAllMedia();
            }
        }
    },
    created() {
        this.fetchAllMedia();
    },
    watch: {
        // Re-fetch if route params change
        '$route.params.entity_type': function () {
            this.currentPage = 1;
            this.fetchAllMedia();
        },
        '$route.query': function () {
            this.currentPage = 1;
            this.fetchAllMedia();
        }
    }
};
</script>

<style>
.animated-background {
    animation: placeholderShimmer 1s linear infinite forwards;
    background: linear-gradient(to right, #f6f7f8 8%, #edeef1 18%, #f6f7f8 33%);
    background-size: 800px 104px;
    height: 100%;
    position: relative;
}

@keyframes placeholderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

.background-masker {
    background: #fff;
    position: absolute;
    height: 100%;
    width: 30px;
    right: 0;
}

/* .loading-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
}

.loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
} */
</style>