<template>
    <div class="all-invites  border-x border-gray-500 max-w-[700px] mx-auto py-10 px-3 sm:p-10">

        <p class="text-xl mb-4 font-semibold">All Earn points</p>
        <div class="bg-white shadow-xl mb-4 flex gap-2 text-[15px] font-[500] sm:px-6 py-6 p-4 rounded-xl ">
            <div class="w-16 rounded-full relative">
                <img src="../../../../images/ziaumer.png" alt="image" />
            </div>
            <div class="flex justify-between w-full">
                <div class=" text-gray-500">
                    <p class="text-2xl text-black font-semibold mb-1">The stars</p>
                    <p class="mb-1">Event: The Adventures of Alice Wonderland-Dinner Event</p>
                    <p class="text-xs flex gap-5 "><span>Start date: 1/24/2025</span><span>End date: 1/30/2025</span>
                    </p>
                    <p class="text-blue text-lg my-2">Points earned: 2000</p>
                    <div class="flex gap-2 items-center">
                        <p class="text-[#12B625]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                            </svg>
                        </p>
                        <p>Completed on time</p>
                    </div>
                </div>
                <div class="flex gap-5">
                    <span @click.stop="toggleMenu" class="cursor-pointer relative">
                        <img class="w-2" src="../../../../images/more.png" alt="image" />
                        <!-- <div v-if="isMenuOpen" ref="menu"
                            class="absolute -right-5 xl:-right-36 xl:bottom-0 mt-2 w-40 bg-white border rounded shadow-lg">
                            <button @click="removeAlly"
                                class="block w-full px-4 py-2 text-left text-red-600 hover:bg-gray-100">
                                ⛔ Remove ally
                            </button>
                        </div> -->
                    </span>
                </div>
            </div>
        </div>
        <div class="bg-white shadow-xl mb-4 flex gap-2 text-[15px] font-[500] sm:px-6 py-6 p-4 rounded-xl ">
            <div class="w-16 rounded-full relative">
                <img src="../../../../images/ziaumer.png" alt="image" />
            </div>
            <div class="flex justify-between w-full">
                <div class=" text-gray-500">
                    <p class="text-2xl text-black font-semibold mb-1">The stars</p>
                    <p class="mb-1">Event: The Adventures of Alice Wonderland-Dinner Event</p>
                    <p class="text-xs flex gap-5 "><span>Start date: 1/24/2025</span><span>End date: 1/30/2025</span>
                    </p>
                    <p class="text-blue text-lg my-2">Points earned: 2000</p>
                    <div class="flex gap-2 items-center">
                        <p class="text-[#12B625]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                            </svg>
                        </p>
                        <p>Completed on time</p>
                    </div>
                </div>
                <div class="flex gap-5">
                    <span @click.stop="toggleMenu" class="cursor-pointer relative">
                        <img class="w-2" src="../../../../images/more.png" alt="image" />
                        <!-- <div v-if="isMenuOpen" ref="menu"
                            class="absolute -right-5 xl:-right-36 xl:bottom-0 mt-2 w-40 bg-white border rounded shadow-lg">
                            <button @click="removeAlly"
                                class="block w-full px-4 py-2 text-left text-red-600 hover:bg-gray-100">
                                ⛔ Remove ally
                            </button>
                        </div> -->
                    </span>
                </div>
            </div>
        </div>
        <div class="bg-white shadow-xl mb-4 flex gap-2 text-[15px] font-[500] sm:px-6 py-6 p-4 rounded-xl ">
            <div class="w-16 rounded-full relative">
                <img src="../../../../images/ziaumer.png" alt="image" />
            </div>
            <div class="flex justify-between w-full">
                <div class=" text-gray-500">
                    <p class="text-2xl text-black font-semibold mb-1">The stars</p>
                    <p class="mb-1">Event: The Adventures of Alice Wonderland-Dinner Event</p>
                    <p class="text-xs flex gap-5 "><span>Start date: 1/24/2025</span><span>End date: 1/30/2025</span>
                    </p>
                    <p class="text-blue text-lg my-2">Points earned: 2000</p>
                    <div class="flex gap-2 items-center">
                        <p class="text-[#12B625]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                            </svg>
                        </p>
                        <p>Completed on time</p>
                    </div>
                </div>
                <div class="flex gap-5">
                    <span @click.stop="toggleMenu" class="cursor-pointer relative">
                        <img class="w-2" src="../../../../images/more.png" alt="image" />
                        <!-- <div v-if="isMenuOpen" ref="menu"
                            class="absolute -right-5 xl:-right-36 xl:bottom-0 mt-2 w-40 bg-white border rounded shadow-lg">
                            <button @click="removeAlly"
                                class="block w-full px-4 py-2 text-left text-red-600 hover:bg-gray-100">
                                ⛔ Remove ally
                            </button>
                        </div> -->
                    </span>
                </div>
            </div>
        </div>



    </div>
</template>
<script>
export default {
    name: "AllEarnPoint",
    data() {
        return {
            isMenuOpen: false,
        };
    },
    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        closeMenu(event) {
            if (this.$refs.menu && !this.$refs.menu.contains(event.target)) {
                this.isMenuOpen = false;
            }
        },
        removeAlly() {
            alert("Ally removed!");
            this.isMenuOpen = false;
        },
    },
    mounted() {
        document.addEventListener("click", this.closeMenu);
    },
    beforeUnmount() {
        document.removeEventListener("click", this.closeMenu);
    },
};
</script>

<style>
.relative {
    position: relative;
}

.absolute {
    position: absolute;
}
</style>