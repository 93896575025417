<template>
  <div :class="entity_key ===
      all_entity_type_display['purchase_oppurtunity']['entity_type_key']
      ? ' w-full pt-5 pb-10 mb-10'
      : ' w-full pt-5 pb-10 mb-10'
    ">
    <div class="flex flex-wrap  bg-gray-100 py-4 mb-4 rounded-2xl justify-between ">
      <p class="sm:text-2xl text-xl md:text-3xl  font-bold px-5">
        {{ all_entity_type_display[entity_key]['plural'] }}
        <span class="text-blue md:text-xl sm:text-lg text-sm vsx:mt-0 mt-1 lg:text-2xl">({{ entity_result[entity_key]?.count }})</span>
      </p>
      <div v-if="entity_result[entity_key]?.count > 0" class="flex  vsx:justify-end  items-center ">
        <a @click="see_all_entity(entity_key)"
          class="text-md sm:text-lg md:text-xl font-semibold text-blue mr-4 ml-5 vsx:mt-0 mt-1 cursor-pointer">See All</a>
      </div>
    </div>

    <!-- <div class="flex border-2 border-black bg-gray-100 py-4 mb-4 rounded-2xl justify-between ">
      <p class="sm:text-2xl text-xl md:text-3xl lg:text-4xl font-bold px-5">
        {{ all_entity_type_display[entity_key]['plural'] }}
        <span class="text-blue text-2xl">({{
          entity_result[entity_key]?.count
        }})</span>
      </p>
      <div class="flex items-center max-[440px]:flex-col">
        <a
          @click="see_all_entity(entity_key)"
          class="text-md sm:text-lg md:text-xl font-semibold text-blue mr-6 cursor-pointer"
          >See All</a
        >
      </div>
    </div> -->

    <div v-if="
      group_loading === false && group_result[entity_key].result.length > 0
    " class="relative flex items-center mb-8 px-5">
      <template v-if="group_result[entity_key].result.length > 4">
        <button class="absolute  z-20 top-[40%] left-0" @click="scrollLeft" aria-label="Scroll left">
          <span class="icon-slider-leftarrow text-sm sm:text-2xl"></span>
        </button>
        <button class="absolute  z-20 top-[40%] right-0" @click="scrollRight" aria-label="Scroll right">
          <span class="icon-slider-rightarrow text-sm sm:text-2xl"></span>
        </button>
      </template>
      <div class="items-center w-full mt-5  filter-slider-container" ref="filters" role="group"
        aria-label="Filter options">
        <GroupComponent v-for="(item, index) in group_result[entity_key]?.result" :key="index" :item_key="item.name"
          :label="item.name" :count="item.entity_count" :selected_category="selected_category" 
          @select="select_category" />
      </div>
    </div>
    <template v-if="entity_loading === false">
      <template v-if="entity_result_list[entity_key].length > 0">
        <div :class="entity_key ===
            all_entity_type_display['purchase_oppurtunity']['entity_type_key']
            ? 'grid xl:grid-cols-6 min-[1100px]:grid-cols-5 mb-10 min-[850px]:grid-cols-4 min-[380px]:px-0 px-5 min-[650px]:grid-cols-3 min-[380px]:grid-cols-2 grid-cols-1 gap-4'
            : 'grid  sm:gap-4 max-[640px]:gap-y-4 mb-10 grid-cols-1 min-[900px]:grid-cols-2'
          ">
          <component v-for="(result, index) in entity_result_list[entity_key]"
            :is="all_entity_type_display[entity_key]['card_component']" :key="index" v-bind="{
              [all_entity_type_display[entity_key]['entity_type_key']]: result,
            }" />
        </div>
        <div v-if="show_more_result" class="main-item">
          <div class="animated-background">
            <div class="background-masker btn-divide-left"></div>
          </div>
        </div>
        <!-- More Slides Down Arrow -->
        <div v-if="entity_result[entity_key].next !== null" class="flex justify-center items-center">
          <SeeMoreIcon @click="see_more_result(entity_result[entity_key].next)" />
        </div>
      </template>
      <template v-else> 
        <div class="text-2xl text-gray-500 px-1 p-4">
          No data available 
        </div>
      </template>
    </template>
    <template v-else>
      <div class="loading-container">
        <div class="loader"></div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import CharacterCardComponent from '@/controller/media_data/components/describe_components/character/character_card_component.vue';
import PlaceCardComponent from '@/controller/media_data/components/describe_components/place/place_card_component.vue';
import ThingCardComponent from '@/controller/media_data/components/describe_components/thing/thing_card_component.vue';
import TimePeriodCardComponent from '@/controller/media_data/components/describe_components/time_period/time_period_card_component.vue';
import GenreCardComponent from '@/controller/media_data/components/describe_components/genre/genre_card_component.vue';
import TopicCardComponent from '@/controller/media_data/components/describe_components/topic/topic_card_component.vue';
import ThemeCardComponent from '@/controller/media_data/components/describe_components/theme/theme_card_component.vue';
import GroupComponent from '@/controller/media_data/components/common_component/group_component.vue';
import ActivityCardComponent from '@/controller/media_data/components/enhance/activity/activity_card_component.vue';
import CareerInterestCardComponent from '@/controller/media_data/components/enhance/career_interest/career_interest_card_component.vue';
import PurchaseCardActivityComponent from '@/controller/media_data/components/enhance/purchase_opp/purchase_card_component.vue';
import SeeMoreIcon from '@/common_components/show_more/show_more_component.vue';
import { all_entity_type_values } from '@/common_variables/entity_type';
export default {
  name: 'EntityCardComponent',

  components: {
    CharacterCardComponent,
    PlaceCardComponent,
    ThingCardComponent,
    TimePeriodCardComponent,
    GenreCardComponent,
    TopicCardComponent,
    ThemeCardComponent,
    GroupComponent,
    SeeMoreIcon,
    ActivityCardComponent,
    CareerInterestCardComponent,
    PurchaseCardActivityComponent,
  },

  props: {
    entity_key: {
      type: String,
    },
  },

  computed: {
    ...mapState({
      entity_result: (state) => state.entity_store.entity_result,
      entity_result_list: (state) => state.entity_store.entity_result_list,
      group_result: (state) => state.entity_store.group_result,
    }),
    all_entity_type_display() {
      return all_entity_type_values;
    },
  },
  async created() {
    this.reset_entity_result({ entity_type: this.entity_key });
    await Promise.all([
      this.fetchEntityDataFromApis(
        this.$route.params.id,
        this.page,
        this.selected_category,
      ),
      this.fetchGroupDataFromApis(this.$route.params.id, this.entity_key),
    ]);
    this.selected_category =
      this.group_result[this.entity_key]?.result[0]?.name;
  },
  data() {
    return {
      outer_loader: true,
      entity_loading: true,
      group_loading: true,
      page: 1,
      show_more_result: false,
      selected_category: null,
    };
  },
  methods: {
    ...mapActions('entity_store', [
      'entity_show_result_api',
      'group_result_api',
    ]),
    ...mapMutations('entity_store', ['reset_entity_result']),

    see_all_entity(entity_type) {
      this.$router.push({
        name: 'see_more_entity',
        params: {
          id: this.$route.params.id,
          type: entity_type,
          media_name: this.$route.query.media_name,
          media_type: this.$route.query.media_type,
        },
      });
    },
    scrollLeft() {
      this.$refs.filters.scrollBy({
        top: 0,
        left: -180, // Adjust scroll distance as needed
        behavior: 'smooth',
      });
    },
    scrollRight() {
      this.$refs.filters.scrollBy({
        top: 0,
        left: 180, // Adjust scroll distance as needed
        behavior: 'smooth',
      });
    },

    select_category(category) {
      this.reset_entity_result({ entity_type: this.entity_key });
      this.selected_category = category;
      console.log(`Selected category: ${category}`);
      this.page = 1;
      this.fetchEntityDataFromApis(
        this.$route.params.id,
        this.page,
        this.selected_category,
      );
    },

    async see_more_result(page) {
      if (this.entity_result[this.entity_key].next !== null) {
        await this.show_more_results(
          this.$route.params.id,
          page,
          this.selected_category,
        );
      }
    },

    async show_more_results(media_id, page, selected_category) {
      this.show_more_result = true;
      try {
        await Promise.all([
          this.entity_show_result_api({
            id: media_id,
            page: page,
            selected_category:
              selected_category !== null && selected_category !== undefined
                ? encodeURIComponent(selected_category)
                : '',
            entity_type: this.entity_key,
          }),
        ]);
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.show_more_result = false;
      }
    },

    async fetchEntityDataFromApis(media_id, page, selected_category) {
      this.entity_loading = true;
      try {
        await Promise.all([
          this.entity_show_result_api({
            id: media_id,
            page: page,
            selected_category:
              selected_category !== null && selected_category !== undefined
                ? encodeURIComponent(selected_category)
                : '',
            entity_type: this.entity_key,
          }),
        ]);
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.entity_loading = false;
      }
    },

    async fetchGroupDataFromApis(media_id, entity_type) {
      this.group_loading = true;
      try {
        await Promise.all([
          this.group_result_api({
            id: media_id,
            entity_type: entity_type,
          }),
        ]);
      } catch (error) {
        console.error('Error fetching data from APIs:', error);
      } finally {
        this.group_loading = false;
      }
    },
  },
};
</script>

<style>
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-slider-container {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.filters {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.filters::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.filter-button {
  padding: 10px 20px;
  margin-right: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.filter-button.active {
  background-color: #007bff;
  color: white;
}

.scroll-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0 10px;
  color: #007bff;
}

.scroll-button.left {
  position: absolute;
  left: 0;
}

.scroll-button.right {
  position: absolute;
  right: 0;
}
</style>
