<template>
    <div class="all-invites border-x border-gray-500 max-w-[700px] mx-auto py-10 px-3 sm:p-10">
        <p class="text-xl mb-4 font-semibold">Sent Requests</p>
        <div class="bg-white shadow-xl mb-4 flex gap-2 text-[15px] font-[500] sm:px-8 px-2 py-4  rounded-lg items-center">
            <div class="w-16 rounded-full relative">
                <img src="../../../images/ziaumer.png" alt="image" />
                <img class="absolute bottom-1 bg-white rounded-full right-1" src="../../../images/offline.png"
                    alt="image" />
            </div>
            <div class="flex justify-between items-center w-full">
                <div>
                    <p>Lazar Nikolov</p>
                    <p class="text-xs text-[#959BA3]">Invite request received</p>
                </div>
                <div class="flex items-center gap-5">
                    <!-- <span class="bg-gray-300 p-3 rounded-full cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                            class="bi bi-check-lg" viewBox="0 0 16 16">
                            <path
                                d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                        </svg>
                    </span> -->
                    <span class="bg-gray-300 p-3 rounded-full cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                            class="bi bi-x-lg" viewBox="0 0 16 16">
                            <path
                                d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                        </svg>
                    </span>
                </div>
            </div>
        </div>
        <div class="bg-white shadow-xl mb-4 flex gap-2 text-[15px] font-[500] sm:px-8 px-2 py-4  rounded-lg items-center">
            <div class="w-16 rounded-full relative">
                <img src="../../../images/ziaumer.png" alt="image" />
                <img class="absolute bottom-1 bg-white rounded-full right-1" src="../../../images/offline.png"
                    alt="image" />
            </div>
            <div class="flex justify-between items-center w-full">
                <div>
                    <p>Lazar Nikolov</p>
                    <p class="text-xs text-[#959BA3]">Invite request received</p>
                </div>
                <div class="flex items-center gap-5">
                    <!-- <span class="bg-gray-300 p-3 rounded-full cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                            class="bi bi-check-lg" viewBox="0 0 16 16">
                            <path
                                d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                        </svg>
                    </span> -->
                    <span class="bg-gray-300 p-3 rounded-full cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                            class="bi bi-x-lg" viewBox="0 0 16 16">
                            <path
                                d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                        </svg>
                    </span>
                </div>
            </div>
        </div>
        <div class="bg-white shadow-xl mb-4 flex gap-2 text-[15px] font-[500] sm:px-8 px-2 py-4  rounded-lg items-center">
            <div class="w-16 rounded-full relative">
                <img src="../../../images/ziaumer.png" alt="image" />
                <img class="absolute bottom-1 bg-white rounded-full right-1" src="../../../images/offline.png"
                    alt="image" />
            </div>
            <div class="flex justify-between items-center w-full">
                <div>
                    <p>Lazar Nikolov</p>
                    <p class="text-xs text-[#959BA3]">Invite request received</p>
                </div>
                <div class="flex items-center gap-5">
                    <!-- <span class="bg-gray-300 p-3 rounded-full cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                            class="bi bi-check-lg" viewBox="0 0 16 16">
                            <path
                                d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                        </svg>
                    </span> -->
                    <span class="bg-gray-300 p-3 rounded-full cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                            class="bi bi-x-lg" viewBox="0 0 16 16">
                            <path
                                d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                        </svg>
                    </span>
                </div>
            </div>
        </div>
        <div class="bg-white shadow-xl mb-4 flex gap-2 text-[15px] font-[500] sm:px-8 px-2 py-4  rounded-lg items-center">
            <div class="w-16 rounded-full relative">
                <img src="../../../images/ziaumer.png" alt="image" />
                <img class="absolute bottom-1 bg-white rounded-full right-1" src="../../../images/offline.png"
                    alt="image" />
            </div>
            <div class="flex justify-between items-center w-full">
                <div>
                    <p>Lazar Nikolov</p>
                    <p class="text-xs text-[#959BA3]">Invite request received</p>
                </div>
                <div class="flex items-center gap-5">
                    <!-- <span class="bg-gray-300 p-3 rounded-full cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                            class="bi bi-check-lg" viewBox="0 0 16 16">
                            <path
                                d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                        </svg>
                    </span> -->
                    <span class="bg-gray-300 p-3 rounded-full cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                            class="bi bi-x-lg" viewBox="0 0 16 16">
                            <path
                                d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                        </svg>
                    </span>
                </div>
            </div>
        </div>
        <div class="bg-white shadow-xl mb-4 flex gap-2 text-[15px] font-[500] sm:px-8 px-2 py-4  rounded-lg items-center">
            <div class="w-16 rounded-full relative">
                <img src="../../../images/ziaumer.png" alt="image" />
                <img class="absolute bottom-1 bg-white rounded-full right-1" src="../../../images/offline.png"
                    alt="image" />
            </div>
            <div class="flex justify-between items-center w-full">
                <div>
                    <p>Lazar Nikolov</p>
                    <p class="text-xs text-[#959BA3]">Invite request received</p>
                </div>
                <div class="flex items-center gap-5">
                    <!-- <span class="bg-gray-300 p-3 rounded-full cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                            class="bi bi-check-lg" viewBox="0 0 16 16">
                            <path
                                d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                        </svg>
                    </span> -->
                    <span class="bg-gray-300 p-3 rounded-full cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                            class="bi bi-x-lg" viewBox="0 0 16 16">
                            <path
                                d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                        </svg>
                    </span>
                </div>
            </div>
        </div>
        <div class="bg-white shadow-xl mb-4 flex gap-2 text-[15px] font-[500] sm:px-8 px-2 py-4  rounded-lg items-center">
            <div class="w-16 rounded-full relative">
                <img src="../../../images/ziaumer.png" alt="image" />
                <img class="absolute bottom-1 bg-white rounded-full right-1" src="../../../images/offline.png"
                    alt="image" />
            </div>
            <div class="flex justify-between items-center w-full">
                <div>
                    <p>Lazar Nikolov</p>
                    <p class="text-xs text-[#959BA3]">Invite request received</p>
                </div>
                <div class="flex items-center gap-5">
                    <!-- <span class="bg-gray-300 p-3 rounded-full cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                            class="bi bi-check-lg" viewBox="0 0 16 16">
                            <path
                                d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                        </svg>
                    </span> -->
                    <span class="bg-gray-300 p-3 rounded-full cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                            class="bi bi-x-lg" viewBox="0 0 16 16">
                            <path
                                d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                        </svg>
                    </span>
                </div>
            </div>
        </div>
      

    </div>
</template>

<script>
export default {
    name: "RecievedRequestScreen",

};
</script>